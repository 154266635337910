import React, { useEffect, useState } from 'react';
import { Avatar, Button } from 'uielements/src';
import { PopupMenu } from 'uielements/src/PopupMenu/PopupMenu';
import { IGetPresentsResponse, IPresentData } from 'utils/src/requests/models/api.shop';
import { getUserPresents } from 'utils/src/requests/requests.shop';
import { cnPresents, IPresentsProps } from './Presents.index';
import './Presents.scss';

import { Translate } from 'localizations/Translate';
import { withTranslation } from 'react-i18next';

export const Presents = withTranslation('translation')((props: IPresentsProps) => {
    const { tag: Tag = 'div', edit, className } = props;

    const [presents, setPresents] = useState<IPresentData[]>();

    useEffect(() => {
        (async function () {
            const response: IGetPresentsResponse = await getUserPresents();
            setPresents(response.data);
        })();
    }, []);

    return (
        <Tag {...props} className={cnPresents('', [className])}>
            <h4 className={cnPresents('Title')}>
                <Translate i18nKey="pryaniky.widgets.presents.title" />
            </h4>
            <div className={cnPresents('Items')}>
                {!edit &&
                    presents?.map((present) => {
                        return (
                            <PopupMenu
                                className={cnPresents('Item')}
                                horizontal={'auto'}
                                noFixed
                                btnContent={
                                    <div className={cnPresents('Item-Img')}>
                                        <Avatar imgUrl={present.imgUrl} size={64} />
                                        <div className={cnPresents('Item-Count')}>{present.count}</div>
                                    </div>
                                }
                            >
                                <div>
                                    <div>
                                        <Translate i18nKey="pryaniky.widgets.presents.present" />
                                        &nbsp;
                                        <Button
                                            theme="unstyled"
                                            type="rlink"
                                            href={`/shop/product/${present.orderProductId}`}
                                        >{`"${present.orderProductName}"`}</Button>{' '}
                                        &nbsp;
                                        <Translate i18nKey="pryaniky.widgets.presents.from" />
                                        &nbsp;
                                        <Button theme="unstyled" type="rlink" href={`/user/${present.userOrder.id}`}>
                                            {present.userOrder.displayName}
                                        </Button>
                                    </div>

                                    {!!present.userComment && (
                                        <div>
                                            <Translate i18nKey="pryaniky.widgets.presents.comment" />
                                            &nbsp;
                                            {present.userComment}
                                        </div>
                                    )}
                                </div>
                            </PopupMenu>
                        );
                    })}
            </div>
        </Tag>
    );
});
