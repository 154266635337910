import { Box } from "muicomponents/src/Box";
import React, { ComponentProps, FC, forwardRef, ReactNode, useMemo } from "react";
import { styled } from 'muicomponents/src/mui/system';
import { Typography } from "muicomponents/src/Typography";
import { ColoredSquare } from "../ColoredSquare";
import moment from "moment";
import { DayHeaderColors } from "../UsersEvents.constants";
import { BorderedBox } from "./UsersEventsTable.styled";
import { getDatesFromTo } from "../UsersEvents.utils";
import { TitleFormats, weekendIndexes } from "../UsersEvents.constants";
import { Translate } from "localizations/Translate";
import { useUsersEventsContext } from "../UsersEvents.context";
import { getCalendarEventsRequestV4 } from "utils/src/requests/requests.calendar";
import { getEventFromArrayWhereDateIsBetweenStartAndEndDates } from "./UsersEventsTable.utils";

const MonthHeaderBox = styled(BorderedBox)({
    display: 'inline-flex',
    boxSizing: 'border-box',
}) as typeof BorderedBox;

const MonthHeaderDatesBox = styled(Box)({
    display: 'inline-block',
    padding: '4px 16px 12px',
}) as typeof Box;

const MonthHeaderSumBox = styled(Box)({
    borderLeft: '1px solid #0000001F',
    width: 50,
    boxSizing: 'border-box',
    padding: '15px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}) as typeof Box;

const MonthTypography = styled(Typography)({
    textTransform: 'capitalize',
}) as typeof Typography;

const MonthHeaderSumTypography = styled(Typography)({
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
}) as typeof Typography;

const DaysBox = styled(Box)({
    display: 'flex',
    gap: 4,
    gridGap: 4
}) as typeof Box;

const DayBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 4,
    gridGap: 4
}) as typeof Box;

const WeekDayTypography = styled(Typography)({
    fontSize: '.625rem', // 10px;
    fontWeight: 500,
    lineHeight: '.875rem', //14px;
    textTransform: 'capitalize',
}) as typeof Typography;

const DayTypography = styled(Typography)({
    lineHeight: 1
}) as typeof Typography;

type MonthHaderProps = ComponentProps<typeof MonthHeaderBox> & {
    events: {[key: string]: Awaited<ReturnType<typeof getCalendarEventsRequestV4>>['data']['events']};
};

const MonthHaderDay: FC<Pick<MonthHaderProps, 'events'> & {
    day: moment.Moment
}> = ({
    day,
    events
}) => {

    const blockContext = useUsersEventsContext();
    
    const isToday = useMemo(() => {
        return moment().startOf('day').isSame(day.startOf('day'));
    }, [day]); 

    const isWeekend = useMemo(() => weekendIndexes.includes(day.day()), [day]);

    const color = useMemo(() => {
        if(isToday) {
            return blockContext.settings.generatedCalerdarSettings.headerTodayColor;
        }
        if(blockContext.settings.generatedCalerdarSettings.calendarId) {
            const weekendEvent = getEventFromArrayWhereDateIsBetweenStartAndEndDates(day, events[blockContext.settings.generatedCalerdarSettings.calendarId]);
            return weekendEvent ? blockContext.settings.generatedCalerdarSettings.headerWeekendColor : undefined;
        }
        return isWeekend ? blockContext.settings.generatedCalerdarSettings.headerWeekendColor : undefined;
    }, [isToday, events, day, isWeekend, blockContext.settings.generatedCalerdarSettings]);

    return (
        <ColoredSquare
            disableIteractive
            color={color}
        >
            <DayTypography variant="caption">
                {day.get('D')}
            </DayTypography>
        </ColoredSquare>
        // <ColoredSquare color={color} disableIteractive />
    );
};

export type MonthHeaderProps = ComponentProps<typeof MonthHeaderBox> & {
    title?: ReactNode;
    dateFrom: Date;
    dateTo?: Date;
    events: {[key: string]: Awaited<ReturnType<typeof getCalendarEventsRequestV4>>['data']['events']};
};

export const MonthHeader = forwardRef<HTMLElement, MonthHeaderProps>(({
    title,
    dateFrom,
    dateTo,
    events,
    ...props
}, ref) => {

    const blockContext = useUsersEventsContext();


    const prepairedTitle = useMemo(() => {
        if(title) return title;
        const isFirstMonth = moment(dateFrom).month() === 0;
        return moment(dateFrom).format(isFirstMonth ? TitleFormats.yearMonth : TitleFormats.month);
    }, [title, dateFrom]);

    const dates = useMemo(() => {
        return getDatesFromTo(moment(dateFrom), dateTo ? moment(dateTo) : moment(dateFrom).endOf('month'));
    }, [dateFrom, dateTo]);

    return (
        <MonthHeaderBox
            {...props}
            ref={ref}
        >
            <MonthHeaderDatesBox>
                <MonthTypography variant="body1">{prepairedTitle}</MonthTypography>
                <DaysBox>
                    {dates.map(day => {
                        // const isWeekend = weekendIndexes.includes(day.day());
                        return <DayBox>
                            <WeekDayTypography>{day.format('dd')}</WeekDayTypography>
                            <MonthHaderDay day={day} events={events} />
                            {/* <ColoredSquare
                                disableIteractive
                                color={
                                    isToday
                                    ? blockContext.settings.generatedCalerdarSettings.headerTodayColor
                                    : isWeekend
                                    ? blockContext.settings.generatedCalerdarSettings.headerWeekendColor
                                    : undefined
                                }
                            >
                                <DayTypography variant="caption">
                                    {day.get('D')}
                                </DayTypography>
                            </ColoredSquare> */}
                        </DayBox>
                    })}
                </DaysBox>
            </MonthHeaderDatesBox>
            <MonthHeaderSumBox>
                <MonthHeaderSumTypography variant="body2">
                    <Translate i18nKey={'pryaniky.usersevents.header.monthSum'} />
                </MonthHeaderSumTypography>
            </MonthHeaderSumBox>
        </MonthHeaderBox>
    );
});