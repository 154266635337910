import { IBodyProps } from 'utils/src/DialogCreator';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { bindActionCreators, Action, Dispatch } from 'redux';
import { makeShopOrder } from 'redux/shop/actions';
import { IShopOrder } from 'utils/src/requests/models/api.shop';
import { IShopMakeShopOrderPayload } from 'redux/shop/actions.interfaces';

export enum OrderManageTypes {
    makeAgreeToParticipate = 'makeAgreeToParticipate',
    changeAgreeToParticipate = 'changeAgreeToParticipate'
}

export interface IShopAgreeToParticipateInMultiOrderProps
    extends IDialogOwnProps,
        IAgreeToParticipateInMultiOrderDialogStateProps,
        IAgreeToParticipateInMultiOrderDispatchProps {
    orderData: IShopOrder;
    investedValue?: number;
    minInvestedValue?: number;
    maxInvestedValue?: number;
    fullCost: number;
    alreadyInvested?: number;
    useRedux?: boolean;
    typeForm?: OrderManageTypes;
    onRefuse?: () => void;
    onClose?: () => void;
    onAccept?: (v: IShopMakeShopOrderPayload) => void;
    investorStatus?: 'invited' | 'investor' | 'refused';
    isWithRequest?: boolean;
}

export interface IDialogOwnProps extends IBodyProps {}

export type IAgreeToParticipateInMultiOrderDialogStateProps = ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: any) => {
    return {
        userThanksCount: getAuthUser(state)?.extData?.mythanksCount || 0,
        currencyNames: state.store.appSettings,
    };
};

export type IAgreeToParticipateInMultiOrderDispatchProps = ReturnType<typeof mapDispatchToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({ makeShopOrder }, dispatch);
export interface IMaybeIndependentPurchaseDialogProps extends IBodyProps {
    onAccept?: () => void;
    onClose?: () => void;
}
