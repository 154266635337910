import React, { useState } from 'react'
import { connect } from 'react-redux'
import { mapStateToProps, cnEventsList, IUser, mapDispatchToProps } from './EventItem.index'
// import { AllParticipants } from '../CalendarModal/CalendarModal'
// import { ListRound } from 'blocks/ListRound/ListRound'
// import { EventAction } from 'blocks/NewsTypes/Events/EventAction/EventAction'
// import { addUserToArray, removeUserFromArray } from 'blocks/NewsTypes/NewsUtils'
// import { UserList } from 'uielements/src/UserList/UserList'
// import { Button } from 'uielements/src'
import './EventItem.scss'
// import { Icon } from 'uielements/src'
// import { Translate } from 'localizations/Translate'
import { CommonEvent } from './types/CommonEvent/CommonEvent'
import { BirthdayEvent } from './types/BirthdayEvent/BirthdayEvent'
import { LmsEvent } from './types/LmsEvent/LmsEvent'
import { WorkflowsEvent } from './types/WorkflowsEvent/WorkflowsEvent'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles';
import ErrorBoundary from 'utils/src/ErrorBoundary'
const EventBox = styled(Box)({
  marginBottom: '8px',
  // flex: '1'
})

const eventsTypesMap = {
  events: CommonEvent,
  birthday: BirthdayEvent,
  lms: LmsEvent,
  workflow: WorkflowsEvent
}

const EventItemPresenter: React.FC<any> = (props) => {
  const { event, user } = props

  const EventRender = event.componentRenderName in eventsTypesMap ? (eventsTypesMap as any)[event.componentRenderName] : null

  if(!EventRender) {
    console.warn(`not render for type "${event.type}"`);
  }

  return <ErrorBoundary>
    <EventBox>
      {
        EventRender ? <EventRender
          event={event}
          user={user}
          updateCalendarEvent={props.updateCalendarEvent}
        /> : null
      }
    </EventBox>
  </ErrorBoundary>
}

export const EventItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventItemPresenter);