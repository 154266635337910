import React, { ComponentProps, FC, useMemo, useState } from 'react';
import { Paper } from 'muicomponents/src/Paper';
import { styled } from 'muicomponents/src/mui/system';
import { CardActionArea } from '@mui/material';

const SquarePaper = styled(Paper)({
    boxSizing: "border-box",
    border: "1px solid #0000001F",
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}) as typeof Paper;

type ColoredSquareProps = ComponentProps<typeof SquarePaper> & {
    color?: string;
    disableIteractive?: boolean;
};
  
export const ColoredSquare: FC<ColoredSquareProps> = ({
    color,
    disableIteractive,
    ...props
}) => {

    const colorSx = useMemo(() => {
        return {
            ...props.sx,
            background: color,
            cursor: !disableIteractive ? 'pointer' : undefined
        };
    }, [color, disableIteractive, props.sx]);
  
    return (
        <SquarePaper
            {...props}
            sx={colorSx}
            elevation={0}
        />
    );
};