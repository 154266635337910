import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { bindActionCreators, Action, Dispatch } from 'redux';
import { makeShopOrder } from 'redux/shop/actions';
import { IBodyProps } from 'utils/src/DialogCreator';
import { Characteristics as CharacteristicsTypes, IOrderUserData } from 'utils/src/requests/models/api.shop';

export interface IShopMakeOrderState {
    id?: number;
    additionalFields?: any;
    characteristics?: CharacteristicsTypes.IItem[];
    comment?: string;
    presentFor?: any;
    thanksPrice?: number;
    doNotShowAdditionalInformation?: any;
    isGift?: boolean;
    name?: string;
    isMultiOrder?: boolean;
    investedValue?: number;
    inviteToGroupOrder?: any;
    groupOrderId?: string;
}

export interface IMakeOrderDialogProps
    extends IMakeOrderDialogStateProps,
        IMakeOrderDialogOwnProps,
        IMakeOrderDialogDispatchProps {
    data?: IShopMakeOrderState;
    additionalInformationBeforeOrder?: string;
    preselectedUsersId?: string;
    useRedux?: boolean;
    withCount?: boolean;
    selectedCount?: number;
    maxCount?: number;
    multiOrderItem?: boolean;
    purchaseOrganizer?: IOrderUserData;
    typeForm?: 'makeOrder' | 'changeOrder';
    minInvestedValue?: number;
    maxInvestedValue?: number;
    isWithRequest?: boolean;
}

export interface IMakeOrderDialogOwnProps extends IBodyProps {}

export interface IMakeOrderDialogState {}

export type IMakeOrderDialogStateProps = ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: any) => {
    return {
        userThanksCount: getAuthUser(state)?.extData?.mythanksCount || 0,
        currencyFormats: state.store.appSettings,
    };
};

export type IMakeOrderDialogDispatchProps = ReturnType<typeof mapDispatchToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators(
        {
            makeShopOrder,
        },
        dispatch
    );
export interface IMaybeIndependentPurchaseDialogProps extends IBodyProps {
    onAccept?: () => void;
    onClose?: () => void;
}
