import React, { ComponentProps, FC, Fragment, useCallback, useMemo } from 'react';
import { TabsSettingsItemProps, TabsSettingsProps } from './TabsSettings.index';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { DialogTitle } from 'muicomponents/src/DialogParts/DialogTitle';
import { DialogContent } from 'muicomponents/src/DialogParts/DialogContent';
import { DialogActions } from 'muicomponents/src/DialogParts/DialogActions';
import { useTabSettings, useTabsSettings } from './TabsSettings.hooks';
import { TabsSettingsItemBox, TabsSettingsItemChackboxLabel, TabsSettingsItemDataBox, StyledSortableList } from './TabsSettings.styled';
import { useDidUpdateEffect } from 'utils/src/hooks';
import { Tooltip } from 'muicomponents/src/Tooltip';
import { Delete, HelpOutline } from 'muicomponents/src/Icons';
import { Checkbox } from 'muicomponents/src/Checkbox';
import { FormControlLabel } from 'muicomponents/src/FormControl';
import { FilePreviewUploader } from 'muicomponents/src/FilePreviewUploader';
import { Translate } from 'localizations/Translate';
import { Button, TextField } from 'muicomponents/src';
import { IconButton } from 'muicomponents/src/IconButton';
import { groupTabs, userTabs, userNewTabs } from 'pageStructures/__tabs';
import { SplitButton } from 'muicomponents/src/SplitButton/SplitButton'
import { generateColumn, generateWidget } from 'Widgets_v2/utils';
import { WidgetsTypes } from 'utils/src/widgets/types';
import { flatMapDeep, flattenDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { getAppDesignColors } from 'utils/src/CommonRedux/base/selectors';
import { getUrlTemplateByPathName } from 'utils.project/utils.project';
import { generatePath, useLocation } from 'react-router';
import urls from 'routes/urls';
import { PreviewParams } from 'utils/src/PreviewParams';
import { GUID_EMPTY, wikilistAliasPatterns } from 'utils/src/constants.prn';
import { AccessPolicyType, basePageCollectionWithChildCollections, mainUrls } from 'utils/src';
import { getTabSelectedFolderByAlias } from './TabsSettings.utils';
import { getWikilistSettings } from 'blocks/WikiListNew/List_type_wiki.utils';

const newDefaultTabs = {
    group: groupTabs,
    user: {
        ...userNewTabs,
        ...userTabs
    }
} as const;

export const TabsSettingsItem: FC<TabsSettingsItemProps> = ({
    tab,
    onChange: onChangeProps,
    onRemove,
    isGroupPage
}) => {

    const {
        tabData,
        onChange
    } = useTabSettings({...tab, title: tab.title || Translate.t({ i18nKey: `pryaniky${tab.name ? '' : '.custom'}.tabs.${tab.name || tab.id}` })});

    useDidUpdateEffect(() => {
        onChangeProps(tabData);
    }, [tabData]);

    // const titleValue = useMemo(() => {
    //     return Translate.t({ i18nKey: `pryaniky${tabData.name ? '' : '.custom'}.tabs.${tabData.name || tabData.id}` });
    // }, [tabData.name, tabData.title, tabData.id]);

    const colors = useSelector(getAppDesignColors)

    return (
        <TabsSettingsItemBox>
            <FilePreviewUploader
                file={tabData.iconFile}
                onFileChange={(file) => {
                    onChange({ iconFile: file });
                }}
                hideTexts
            />
            <TabsSettingsItemDataBox>
                <TextField
                    size='small'
                    label={<Translate i18nKey={'pryaniky.tabs.settings.name'} />}
                    value={tabData.title}
                    onChange={(event) => {
                        onChange({ title: event.target.value });
                    }}
                />
                <FormControlLabel
                    control={<Checkbox checked={Boolean(tabData.accessPolicy)} />}
                    onChange={(event, checked) => {
                        onChange({
                            accessPolicy: checked
                                ? {
                                    policyType: AccessPolicyType.ShowOnlyForOwnerAndAdmin
                                }
                                : null
                        })
                    }}
                    label={<TabsSettingsItemChackboxLabel>

                        <Translate i18nKey={'pryaniky.tabs.settings.accessPolicy'} />
                        <Tooltip
                            title={
                                <Translate i18nKey={isGroupPage ? 'pryaniky.tabs.group.accessPolicy.help' : 'pryaniky.tabs.settings.accessPolicy.help'} />
                            }
                            placement={'top'} >
                            <HelpOutline fontSize='small' />
                        </Tooltip>
                    </TabsSettingsItemChackboxLabel>}
                />
                {/* <TextField
                    size='small'
                    label={'name'}
                    value={tabData.title || titleValue}
                    onChange={(event) => {
                        onChange({ title: event.target.value });
                    }}
                /> */}
            </TabsSettingsItemDataBox>
            <IconButton
                onClick={() => onRemove(tabData.id)}
            >
                <Delete htmlColor={colors.primaryColor3} />
            </IconButton>
        </TabsSettingsItemBox>
    );
};

export const TabsSettingsPr: FC<TabsSettingsProps> = ({
    tabs,
    handleAccept,
    handleClose,
    wcontext,
}) => {
    // console.log('WWWWWcontext', wcontext)
    const {
        list,
        tabsSettings,
        editTab,
        removeTab,
        sortTabs,
        addTab
    } = useTabsSettings(tabs);

    const isGroupPage = wcontext && 'groupId' in wcontext

    const location = useLocation();

    const createDefaultTab = useCallback<NonNullable<ComponentProps<typeof SplitButton>['handleMenuItemClick']>>((event, item) => {
        const [group, name] = item.id.split('.') as [keyof typeof newDefaultTabs, keyof typeof newDefaultTabs[keyof typeof newDefaultTabs]];
        const fn = newDefaultTabs[group][name];
        let column = fn();
        if(
            group === 'group' && (
                (name as any) === 'imageGallery'
                || (name as any) === 'videoGallery'
            )
        ) {
            const groupId = wcontext && 'groupId' in wcontext ? wcontext.groupId : '';
            if(groupId) {
                column = (fn as any)(
                    getWikilistSettings({
                        folder: getTabSelectedFolderByAlias(wikilistAliasPatterns.replacePatternParamsByMatch(wikilistAliasPatterns.group[name as keyof typeof wikilistAliasPatterns.group], { id: groupId }))
                    })
                );
            }
        }
        addTab(column as any);
    }, []);

    const options = useMemo<ComponentProps<typeof SplitButton>['options']>(() => {
        const { pathTemplate } = getUrlTemplateByPathName(location.pathname.replace(/\/$/, ''));
        let groupForShow: (keyof typeof newDefaultTabs)[] = [];
        if(
            !!urls.user[urls.user.findIndex(i => i === pathTemplate)]
            || !!urls.userNew[urls.userNew.findIndex(i => i === pathTemplate)]
        ) {
            groupForShow = Object.typedKeys(newDefaultTabs).filter(el => el === 'user');
        } else
        if (!!urls.group[urls.group.findIndex(i => i === pathTemplate)]) {
            groupForShow = Object.typedKeys(newDefaultTabs).filter(el => el === 'group');
        }
        return flattenDeep(groupForShow.map(group => {
            return Object.typedKeys(newDefaultTabs[group]).map(name => {
                return {
                    content: <Translate i18nKey={`pryaniky.tabs.create.${group}.${name}`} />,
                    id: `${group}.${name}`
                }
            });
        }));
    }, [location.pathname]);



    return (
        <>
            <DialogTitle
                onClose={handleClose}
            >
                <Translate i18nKey={'pryaniky.tabs.settings.title'} />
            </DialogTitle>
            <DialogContent>
                <StyledSortableList
                    onChange={sortTabs}
                    useHandler
                    handlerOnEnd
                >
                    {
                        list.map(id => {
                            return (
                                <TabsSettingsItem
                                    key={id}
                                    tab={tabsSettings[id]}
                                    onChange={editTab}
                                    onRemove={removeTab}
                                    isGroupPage={isGroupPage || false}
                                />
                            );
                        })
                    }
                </StyledSortableList>
            </DialogContent>
            <DialogActions
                onAccept={() => {
                    const newTabs = list.map(id => tabsSettings[id]);
                    newTabs.forEach(tab => {
                        const key = `pryaniky${tab.name ? '' : '.custom'}.tabs.${tab.name || tab.id}`;
                        if (tab.title) Translate.setResorseItem(key, tab.title);
                    });
                    handleAccept(newTabs);
                }}
                onClose={handleClose}
                acceptText={<Translate i18nKey={'save'} />}
                closeText={<Translate i18nKey={'cancel'} />}
                leftActions={<SplitButton
                    variant='outlined'
                    size='small'
                    handleMenuItemClick={createDefaultTab}
                    options={options}
                >
                    <Button
                        onClick={() => {
                            const column = generateColumn({
                                items: [
                                    generateWidget('layout', {
                                        data: [
                                            generateColumn({})
                                        ] as any
                                    })
                                ]
                            });
                            const key = `pryaniky.custom.tabs.${column.id}`;
                            Translate.setResorseItem(key, Translate.t({ i18nKey: 'pryaniky.tabs.new' }));
                            addTab(column as any);
                        }}
                    >
                        <Translate i18nKey={'pryaniky.tabs.settings.addNew'} />
                    </Button>
                </SplitButton>}
            />
        </>
    );
};

export const TabsSettings = createModal(TabsSettingsPr, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
        }
    },
    TransitionComponent,
    scroll: 'body'
});