import React from 'react';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { DialogBody } from 'muicomponents/src/DialogParts/DialogBody/DialogBody';
import { Translate } from 'localization';
import { IMaybeIndependentPurchaseDialogProps } from '../ShopMakeOrder.index';

const MaybeIndependentPurchaseDialog = ({
    onAccept,
    onClose,
    handleAccept,
    handleClose,
}: IMaybeIndependentPurchaseDialogProps) => {
    return (
        <DialogBody
            header={<Translate i18nKey="pryaniky.shop.order.jointPurchase.maybeIndependentPurchase.header" />}
            acceptText={<Translate i18nKey="pryaniky.shop.order.jointPurchase.maybeIndependentPurchase.acceptText" />}
            closeText={<Translate i18nKey="pryaniky.shop.order.jointPurchase.maybeIndependentPurchase.closeText" />}
            onAccept={() => {
                if (onAccept) onAccept();
                handleAccept(true);
            }}
            onClose={() => {
                if (onClose) onClose();
                handleClose();
            }}
            contentProps={{
                sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                },
            }}
        >
            <Translate i18nKey="pryaniky.shop.order.jointPurchase.maybeIndependentPurchase.discription" />;
        </DialogBody>
    );
};

export const openMaybeIndependentPurchaseDialog = createModal(MaybeIndependentPurchaseDialog, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
        },
    },
    TransitionComponent,
    scroll: 'body',
});
