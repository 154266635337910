import { Box, styled } from 'muicomponents/src';
import { Avatar } from 'muicomponents/src/Avatar';

export const StyledAvatar = styled(Avatar)({
    borderRadius: '4px 4px 0 0',
    height: '158px', // в макете
    //height: '255px',  // для objectFit: 'contain' в .MuiAvatar-img и квадратности изображения
    width: '100%',
    '.MuiAvatar-root>img': {
        display: 'block',
        minWidth: '100%',
        minHeight: '100%',
    },
    '.MuiAvatar-img': {
        minWidth: '100%',
        minHeight: '100%',
        objectFit: 'cover',
    },
}) as typeof Avatar;

export const AvailableBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
}) as typeof Box;

export const StarBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
}) as typeof Box;

export const ShopInfoBox = styled(Box)({
    display: 'flex',
    gap: '8px',
}) as typeof Box;
