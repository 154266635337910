import { cn } from '@bem-react/classname';
import { MenuListProps, PaperProps } from '@mui/material';
import { Menu as MenuMui } from 'muicomponents/src/Menu';
import { ToggleButton } from 'muicomponents/src/ToggleButton';
import { Tooltip } from 'muicomponents/src/Tooltip';
import React, { useEffect } from 'react';
import { CircleIndicator, IconMui } from '../Header.styled';
import { IMenuProps } from './Menu.index';

export const cnHeaderMenu = cn('HeaderMenu');

const defaultPaperProps: Partial<PaperProps> = {
    sx: {
        width: 450,
        maxHeight: 420,
        top: '66px !important',
    },
};

const defaultMenuListProps: Partial<MenuListProps> = {
    sx: {
        paddingBottom: '0 !important',
        width: '100% !important',
    },
};

export const Menu: React.FC<IMenuProps> = (props) => {
    const { children, notificationCount, menuIcon, tooltipTitle, getNotificationCount, resetNotificationCount } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (getNotificationCount) getNotificationCount();
    }, []);

    useEffect(() => {
        if (open && resetNotificationCount) resetNotificationCount();
    }, [open]);

    return (
        <>
            <Tooltip title={tooltipTitle}>
                <ToggleButton
                    size="small"
                    value={open}
                    selected={open}
                    className={cnHeaderMenu('Icon')}
                    onClick={handleClick}
                >
                    <IconMui as={menuIcon as any} />

                    {notificationCount !== 0 && (
                        <CircleIndicator sx={{ backgroundColor: '#e31b0c' }}>{notificationCount}</CircleIndicator>
                    )}
                </ToggleButton>
            </Tooltip>

            <MenuMui
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                disableScrollLock
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={defaultPaperProps}
                MenuListProps={defaultMenuListProps}
            >
                {children}
            </MenuMui>
        </>
    );
};
