import { Box, styled } from "muicomponents/src";

export const BlocksBox = styled(Box)({
    display: 'flex',
    gap: 32,
    gridGap: 32
}) as typeof Box;

export const BlockBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    gridGap: 8,
    flex: 'calc(1 / 3)'
}) as typeof Box;

export const CalendarItemsBox = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 16,
    gridColumnGap: 16,
    rowGap: 8,
    gridRowGap: 8,
    alignItems: 'center'
}) as typeof Box;

export const CalendarItemBox = styled(Box)({
    display: 'flex',
    gap: 8,
    gridGap: 8,
    alignItems: 'center'
}) as typeof Box;

export const ColorCircle = styled('span')({
    width: 10,
    height: 10,
    borderRadius: '50%'
});