import * as React from 'react';
import * as utils from 'utils/src/utils';

// import { withBemMod } from '@bem-react/core';

import { widgets } from 'i.widgets';
import { Timeline } from '../../Timeline/Timeline';
import WidgetPresenter from '../Widget';
import { cnWidget } from '../Widget.index';
import { IWidgetTypeTimelineProps } from './Widget_type_timeline.index';

import './Widget_type_timeline.scss';

const type = 'timeline';

const createToDayDate = (range = 1) => {
    const beginDatei = new Date();
    // beginDatei.setUTCHours(0, 0, 0, 0)
    beginDatei.setSeconds(0);
    beginDatei.setMilliseconds(0);
    const beginDate = beginDatei.toISOString();

    const endDatei = new Date();
    endDatei.setHours(23, 59, 59, 0);
    if (range > 1) {
        endDatei.setDate(endDatei.getDate() + range);
    }
    // endDatei.setUTCHours(23, 59, 59, 0)
    const endDate = endDatei.toISOString();
    return {
        beginDate,
        endDate,
        order: 'eventdate',
        sort: 'asc',
    };
};
export default class WidgetTypeTimelinePresenter extends WidgetPresenter<IWidgetTypeTimelineProps> {
    public render() {
        if (!this.props.widget) return null;
        const subtype = utils.widgetSubtype(this.props.widget.type);
        let additionalContext: Record<string, any> = {};
        if (this.props.widget.settings?.dateNow) {
            additionalContext = { ...additionalContext, ...createToDayDate(this.props.widget.settings?.range) };
        }

        const context = {
            ...this.props.wcontext,
            wId: this.props.widget.id,
            ...this.props.widget.settings,
            ...additionalContext,
        };
        // дополнительный типы оберток для каждой новости, можно постореть в news/containers/newsitem объект individualViewType
        const { timelineType } = this.props.widget.settings || {};
        return (
            <Timeline
                {...this.props}
                hideNews={this.props.edit}
                relations={this.props.widget.relations}
                context={context}
                className={cnWidget({ type, subtype, timelineType })}
                tag={widgets.components.common}
            />
        );
    }
}
