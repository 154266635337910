import { Box, styled, Typography } from "muicomponents/src";

export const HeadBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    gridGap: 16
}) as typeof Box;

export const TableBox = styled(Box)({
    padding: 0
}) as typeof Box;