import React from 'react';
import { cnShop } from '../Shop.index';
import { IShopItem } from 'utils/src/requests/models/api.shop';
import { cn } from '@bem-react/classname';
// import { Button, HelpTooltip } from 'uielements/src';
import i18n from 'localizations/i18n';
import { connect } from 'react-redux';
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
import { toast } from 'react-toastify';
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import './Shop-Item.scss';
import { IStoreAppSetting } from 'models/store/appSettings';
import { IClassNameProps } from '@bem-react/core';
import { openShopMakeOrder } from 'blocks/Dialogs/Shop/ShopMakeOrder/ShopMakeOrder';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { currentUserMinusMyThanksCount } from 'utils/src/CommonRedux/users/actions';
import { clearShopProduct, clearShopCatProducts } from 'redux/shop/actions';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { ShopPurse } from '../ShopPurse.svg';
import { Translate } from 'localizations/Translate';
import { orderIsDenied } from '../validation';
import OrderBlock from '../OrderBlock';
import { countMissingThanks, getPriceData, changeLinks } from '../utils';
import MDRender from 'uielements/src/CommonmarkRender';
import { Link } from 'muicomponents/src/Link/Link';
import { cloneDeep } from 'lodash';
// const changeLinks = (text: string) => {
//   let desc = text;
//   const urls = desc.match(/https?:\/\/[\w\.]+\S+/g);
//   if (urls) urls.forEach(url => desc = desc.replace(url, `<a class="${cnShopCard('Description-Url')}" target="blank" href="${url}">${url}</a>`));
//   return desc
// }

const cnShopCard = cn('ShopCard');

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: any) =>
    bindActionCreators(
        {
            currentUserMinusMyThanksCount,
            clearShopProduct,
            clearShopCatProducts,
        },
        dispatch
    );

export interface IShopItemProps extends IShopItemOwnProps, IDispatchProps, IItemStateProps {}

export interface IShopItemOwnProps extends IClassNameProps {
    tag?: string | React.ComponentType<any>;
    data: IShopItem;
    isHidePricesAndButtonBuy: boolean;
    isHideButtonBuy?: boolean;
    maxOrdersCount: number;
    maxOrdersPeriod: string;
    ordersCount: number;
    additionalInformationBeforeOrder: string;
}

interface IShopItemState {
    comment: string;
    presentFor: any;
    imageLoaded: boolean;
    showDialog: boolean;
}

export class ItemPresenter extends React.Component<IShopItemProps, IShopItemState> {
    public integrated: boolean = window.self !== window.top;
    public state = {
        comment: '',
        presentFor: void 0 as any,
        imageLoaded: false,
        showDialog: false,
    };
    public render() {
        const {
            data,
            myThanksCount,
            currencyFormats,
            isHidePricesAndButtonBuy,
            additionalInformationBeforeOrder,
            tag: TagName = 'div',
            className = '',
            maxOrdersCount,
            maxOrdersPeriod,
            ordersCount,
            isHideButtonBuy,
        } = this.props;

        const { characteristics = [], thanksPrice, minVirtCurrencyPayment, availableCount, isUnlimitedProduct } = data;

        const missingThanks = countMissingThanks(thanksPrice, myThanksCount);

        const desc = data.desc; //changeLinks(data.desc);

        // если цена зависит от характеристик: сравниваем userThanksCount с минимальной ценой на товар, а не с общей ценой thanksPrice и выводим "От"
        const priceDependsOnChars = characteristics.some((char) => char.usedForPrice) && minVirtCurrencyPayment > -1;
        const fromPriceText = priceDependsOnChars ? i18n.t('from.price') : null;
        const price = priceDependsOnChars ? minVirtCurrencyPayment : thanksPrice;
        const priceCurrencyText = utilsProj.getFormattedCurrencyName(
            currencyFormats,
            price,
            !characteristics.length ? 'nominative' : 'genitive'
        );

        // для товаров которых нет в наличии: при поиске по товарам их все равно видно, и для отображения корректной надписи о наличии проверям это
        const productIsAvailable = isUnlimitedProduct ? true : !!availableCount;

        return (
            <>
                {/* <MakeOrderDialog
          data={this.props.data}
          isShown={this.state.showDialog}
          onConfirm={this.orderItem}
          onClose={this.closeDialog}
        /> */}
                <TagName className={cnShopCard({}, [cnShop('Item'), className])}>
                    <Link to={`/shop/product/${data.id}`} className={cnShopCard('Img')}>
                        <img
                            className={cnShopCard('Img', { hidden: !this.state.imageLoaded })}
                            src={data.mainImgUrl}
                            alt={data.name}
                            onLoad={this.setImageLoaded}
                        />
                    </Link>

                    {!data.isUnlimitedProduct && !!availableCount && (
                        <div className={cnShopCard('Ribbon')}>
                            <span className={cnShopCard('RibbonText')}>
                                {' ' + availableCount + ' ' + i18n.t('in stock')}
                            </span>
                        </div>
                    )}

                    {data.isGift && <div className={cnShopCard('RibbonGift')}>{i18n.t('can be gifted')}</div>}

                    <div className={cnShopCard('Info')}>
                        <Link className={cnShopCard('Header')} to={`/shop/product/${data.id}`}>
                            <h4 className={cnShopCard('Header')}>{data.name}</h4>
                        </Link>
                        <div className={cnShopCard('Description')}>
                            <MDRender
                                className={cnShopCard('MDRender')}
                                disableEmojiOne
                                disableShowMoreBtn
                                type={'inline'}
                                textCut={false}
                                source={desc}
                            />
                        </div>
                    </div>

                    {!isHidePricesAndButtonBuy && (
                        <div className={cnShopCard('Price')}>
                            <div className={cnShopCard('Price-Purse')}>
                                <ShopPurse size={36} padding={6} className={cnShopCard('Price-Purse-Img')} />
                            </div>
                            <div className={cnShopCard('Price-Value')}>
                                {fromPriceText}
                                <span>{price}</span>
                                {priceCurrencyText}
                                {/* {priceCurrency} */}
                            </div>
                            <div></div>
                        </div>
                    )}

                    {!isHideButtonBuy && (
                        <div className={cnShopCard('Footer', { missing: missingThanks > 0 })}>
                            <OrderBlock
                                {...{
                                    characteristics,
                                    currencyFormats,
                                    id: data.id,
                                    maxOrdersCount,
                                    maxOrdersPeriod,
                                    myThanksCount,
                                    ordersCount,
                                    showDialog: () =>
                                        openShopMakeOrder({
                                            data,
                                            additionalInformationBeforeOrder,
                                            isWithRequest: true,
                                        })
                                            .then((value) => this.orderItem(value))
                                            .catch(() => {}),
                                    thanksPrice: data.thanksPrice,
                                    disableOrder: characteristics.length === 0,
                                    productIsAvailable,
                                    multiOrderItem: data.multiOrderItem,
                                }}
                            />
                        </div>
                    )}
                </TagName>
            </>
        );
    }

    private closeDialog = () => this.setState({ showDialog: false });

    private showDialog = () => this.setState({ showDialog: true });

    private setImageLoaded = () => this.setState({ imageLoaded: true });

    private orderItem = (data: any) => {
        this.props.currentUserMinusMyThanksCount(this.props.data.thanksPrice);
        this.setState({ comment: '', presentFor: '' });
        this.closeDialog();
        toast.success(i18n.t('your order is successfully placed'));
    };
}

interface IItemStateProps {
    myThanksCount: number;
    currencyFormats: IStoreAppSetting;
}

const mapStateToProps = (state: any) => ({
    myThanksCount: getAuthUser(state).extData.mythanksCount,
    currencyFormats: state.store.appSettings,
});

export const Item = connect<IItemStateProps, IDispatchProps, IShopItemOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(ItemPresenter);
