import React from 'react';
import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";
import { UserRatings } from 'blocks/UserRatings/UserRatings';
import { IWidgetTypeRatingsProps } from './Widget_type_userRatings.index';
import { cnWidgetMui } from '../Widget.index';

const type = 'userRatings';

const types = {
  common: UserRatings,
}

export default class WidgetTypeUserRatings extends WidgetPresenter<IWidgetTypeRatingsProps> {

  public render() {
    if (!this.props.widget) return null;
    const Component = this.getComponent(types);
    return <widgets.components.common {...this.props} className={cnWidgetMui({ type })}>
      <Component settings={this.props.widget.settings} context={this.props.wcontext} />
    </widgets.components.common>
  }
}
