/**
 * @packageDocumentation
 * @module ListAbstract
 */
import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from '../../redux/connector';
import { prepareContext } from 'utils/src/utils';
import { IWidgetSettings } from 'widgets/wiget_settings';
import { IWidgetProps } from 'blocks/Widget/Widget.index';
import { IStateType as IState } from 'redux/store';
import {
  toggle,
  getUsers,
  getLikers,
  // getBadgeUsers,
  getPollAnswerParticipants,
  getGroups,
  getGroupsTree,
  getShopProducts,
  getOrders,
  getRatingUsers,
  getBadges,
  addDataToList,
  removeDataFromList,
  checkAllListItem,
  changeListItem,
  setDataToList,
  toggleClearData,
  toggleHideData,
  toggleIsFinished,
  toggleIsLoading,
  clearDataFromList,
  killList,
  addSubitemsToList,
  removeSubitemsFromList,
  getListItem,
  getQuests,
  clearSelectedListItems,
  getEventParticipants,
  getQuestsList,
  getRegistrationRequests
} from 'redux/actions/Lists';
import { getWikiList } from 'redux/actions/WikiList';
import { IList } from 'redux/reducers/Lists';
import { REDUSERS_NAMES } from 'utils/src/constants.prn';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { getAllowToGroupTransfer } from 'redux/sagas/AppSettings/selectors';

// import { IContext } from 'src/store/vm';

export interface IListProps extends IClassNameProps, IListOwnProps, IActionsList, IListStateProps, IWidgetProps, IDispatchProps {
  tag?: 'div' | React.ComponentType;
}

export interface IListOwnProps {
  context: IListContext;
  staticFilters?: boolean;
  id ?: string; // need to redux list indentifier;
}

export interface IListState {
  data: any[];
  filteredData: any[];
  selected: any[];
  isFinished: boolean;
  isLoading: boolean;
  hideData: boolean;
  clearData: boolean;
  [s: string]: any;
}

export interface IListContext extends IWidgetSettings {
  cId?: string;
  aId?: string;
}

export interface IActionsList {
  toggle: typeof toggle;
  getUsers: typeof getUsers;
  getLikers: typeof getLikers;
  // getBadgeUsers: typeof getBadgeUsers;
  getPollAnswerParticipants: typeof getPollAnswerParticipants;
  getGroups: typeof getGroups;
  getGroupsTree: typeof getGroupsTree;
  getShopProducts: typeof getShopProducts;
  getOrders: typeof getOrders;
  getRatingUsers: typeof getRatingUsers;
  getBadges: typeof getBadges
  setDataToList: typeof setDataToList;
  addDataToList: typeof addDataToList;
  removeDataFromList: typeof removeDataFromList;
  changeListItem: typeof changeListItem;
  toggleClearData: typeof toggleClearData;
  toggleHideData: typeof toggleHideData;
  toggleIsFinished: typeof toggleIsFinished;
  toggleIsLoading: typeof toggleIsLoading;
  clearDataFromList: typeof clearDataFromList;
  killList: typeof killList;
  checkAllListItem: typeof checkAllListItem;
  addSubitemsToList: typeof addSubitemsToList;
  removeSubitemsFromList: typeof removeSubitemsFromList;
  getListItem: typeof getListItem;
  getQuests: typeof getQuests;
  clearSelectedListItems: typeof clearSelectedListItems;
  getEventParticipants: typeof getEventParticipants;
  getWikiList: typeof getWikiList;
  getQuestsList: typeof getQuestsList;
  getRegistrationRequests: typeof getRegistrationRequests;
}
// export type IActionsList = ReturnType<typeof mapStateToProps>

export const mapActionsToProps = {
  toggle,
  getUsers,
  getLikers,
  getGroups,
  // getBadgeUsers,
  getPollAnswerParticipants,
  getGroupsTree,
  getShopProducts,
  getOrders,
  getRatingUsers,
  getBadges,
  setDataToList,
  addDataToList,
  removeDataFromList,
  changeListItem,
  toggleClearData,
  toggleHideData,
  toggleIsFinished,
  toggleIsLoading,
  clearDataFromList,
  killList,
  checkAllListItem,
  addSubitemsToList,
  removeSubitemsFromList,
  getListItem,
  getQuests,
  clearSelectedListItems,
  getEventParticipants,
  getWikiList,
  getQuestsList,
  getRegistrationRequests
};

function dataToList(list: IList, props?: IListProps): string[] {
  return list.hideData || list.filteredData.length || list.search ? list.filteredData : list.data;
}

export type IListStateProps = ReturnType<typeof mapStateToProps>

export const mapStateToProps = (state: IState, props: IListOwnProps) => {
  const list = state.lists[props.id || 'alone'];
  return {
    companyUsersCount: state.store.appSettings && state.store.appSettings.usersCount,
    parentNetwork: state.store.appSettings && state.store.appSettings.parentNetwork,
    appId: state.store.appSettings && state.store.appSettings.appId,
    uid: getAuthUser(state).baseData.id,
    authUser: getAuthUser(state),
    uIsAdmin: getAuthUser(state).baseData.isAdmin,
    uRoles: getAuthUser(state).baseData.roles,
    edit: state.store.edit as boolean,
    data: list && dataToList(list) || [],
    additionalParams: list && list.additionalParams || [] ,
    // filteredData: list && list.filteredData || [],
    selected: list && list.selected || [],
    isFinished: list && list.isFinished || false,
    isLoading: list && list.isLoading || false,
    hideData: list && list.hideData || false,
    clearData: list && list.clearData || false,
    search: list && list.search || '',
    currencyNameSingle: state.store.appSettings && state.store.appSettings.currencyNameNominativeSingular,
    currencyNamePlural: state.store.appSettings && state.store.appSettings.currencyNameGenitivePlural,
    currencyNameSemiPlural: state.store.appSettings && state.store.appSettings.currencyNameGenitiveSingular,
    network: state.widgets.contexts.common.network || '',
    allowToGroupTransfer: getAllowToGroupTransfer(state)
  }
}

export const cnList = cn('List');