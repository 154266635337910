import { ArrowLeft, ArrowRight, KeyboardArrowLeft, KeyboardArrowRight } from 'muicomponents/src/Icons';
import { Menu } from 'muicomponents/src/Menu';
import React, { PropsWithChildren, useState } from 'react';
import { MenuItem, MenuItemFlex, MenuItemTextBox, MenuTextCrop } from '../UserMenu.styled';
import { IUserNestedMenu } from './UserNestedMenu.index';

export const UserNestedMenu: React.FC<PropsWithChildren<IUserNestedMenu>> = ({ children, text, title }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <MenuItem onClick={handleClick}>
                <MenuItemFlex>
                    <MenuItemTextBox>
                        <div>{title}</div>
                        <MenuTextCrop>{text}</MenuTextCrop>
                    </MenuItemTextBox>
                    <ArrowLeft sx={{
                        transform: open ? 'rotate(180deg)' : undefined
                    }} />
                    {/* {open ? <ArrowLeft /> : <ArrowRight />} */}
                </MenuItemFlex>
            </MenuItem>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                mousePassBackdrop
                MenuListProps={{
                    sx: {
                        width: '100% !important',
                    },
                }}
                PaperProps={{
                    sx: {
                        width: 410,
                        maxHeight: 300,
                    },
                }}
            >
                {children}
            </Menu>
        </>
    );
};
