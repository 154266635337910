import * as React from 'react';
import ReactDOM from 'react-dom';
import { IAppDesign } from 'utils/src/requests/models/api.system';
import { IStoreAppSetting } from 'models/store/appSettings';
import { Confirm, IConfrimOptions, confirmProise as confirmProiseU } from 'uielements/src/Confirm/Confirm';
import { ConnectedRouter } from 'connected-react-router';

// import * as React from 'react';
// import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import { store } from '../redux/store';
// import { mapDispatchToProps } from '../redux/connector'
import { Modal } from '../blocks/Modal/Modal';
import { IModalProps } from 'blocks/Modal/Modal.index';
// import { IWidget } from 'i.widgets';
// import { IStateType as IState } from 'redux/store';
import { v1 as uuid } from 'uuid';
import actionsModals from '../redux/actionsTypes/Modals';
import urls, { exactPathnames } from '../routes/urls';
import { ExtractRouteParams, generatePath, matchPath } from "react-router";
import { getBrightnessFromHSL, hexToHSL } from 'utils/src/utils.colors';
import { Omit } from 'utils/src';
import { Dictionary } from 'lodash';
import { queryStringArrayFormat } from 'utils/src/constants.parse';
import { stringify } from 'query-string';

export const getLocationStateFrom = (state?: any) => {
  return state && state.from
}

export const isAllowOpenNewsDialog = (pathname: string, state?: any) => {
  const isNews = matchPath(pathname, {
    path: urls.news,
    exact: true,
    strict: true
  });
  const from = getLocationStateFrom(state)
  const isPage = from?.pathname === pathname
  return isNews && from && !isPage
}

interface IUtilsModalOpts {
  type?: string;
  header?: string;
  data?: Record<string, any>;
  okBtnText?: string;
  okBtnAction?: (data: any) => void;
  cancelBtnText?: string;
  cancelBtnAction?: (...args: any) => void;
  okBtnValidate?: (data: any) => boolean;
  disableCloseOnClickOutside?: boolean;
  [s: string]: any;
}

/**
 * @deprecated rewrite to ReactDOM.createPortal(child, container)
 */
export const modal = (props: IUtilsModalOpts = {}) => {
  let Layout = document.getElementById('Layout');
  if (!Layout) Layout = document.body;
  let rootLayoutNumber = 1;
  let rootModal = document.getElementById('RootModal-l' + rootLayoutNumber);
  let newRoot: HTMLElement | null = null;
  if (rootModal) {
    while (rootModal.children.length > 0) {
      rootLayoutNumber++;
      rootModal = document.getElementById('RootModal-l' + rootLayoutNumber);
      if (rootModal === null) {
        newRoot = document.createElement('div');
        newRoot.setAttribute('id', 'RootModal-l' + rootLayoutNumber);
        if (Layout) Layout.appendChild(newRoot);
        rootModal = newRoot;
      }
    }
  }
  const removeRootNode = () => {
    if (newRoot && rootLayoutNumber > 1) {
      newRoot.remove();
      newRoot = null;
    }
  };
  const modalProps: IModalProps = {
    type: props.type || 'confirm',
    header: props.header,
    data: props.data || {},
    okBtnAction: props.okBtnAction || (() => undefined),
    cancelBtnAction: props.cancelBtnAction,
    okBtnValidate: props.okBtnValidate || (() => true),
    disableCloseOnClickOutside: props.disableCloseOnClickOutside,
    root: {
      elem: rootModal,
      remove: removeRootNode,
    },
    ...props,
  };
  const store = (window as any).store;
  const history = (window as any).storeBrowserHistory;
  ReactDOM.render(
    <Provider key={'RootModalContainer_old_stroe'} store={store} >
      <ConnectedRouter key={'RootModalContainer_old_history'} history={history}>
        <Modal {...modalProps} />
      </ConnectedRouter>
    </Provider>,
    rootModal
  );
};

/**
 * @deprecated use ReactDOM.createPortal(child, container)
 */
export const renderComponent = (component: React.ReactNode, root: string) => {
  // ReactDOM.render(<Provider store={store} children={component} />, document.getElementById(root));
};

/**
 * @deprecated
 * @param Component 
 * @param props 
 * @returns 
 */
export function modalPortal<T = any>(Component: React.ComponentType<T>, props: T) {
  let { id } = props as any;
  if (!id) id = uuid();
  store.dispatch({ type: actionsModals.CHANGE_DATA, payload: { id, data: (props as any).data } });
  delete (props as any).data;
  return <Component {...props} id={id} />
}






export const setCustomDesign = (data: IAppDesign) => {
  const domElems = [document.getElementById('customCss'), document.getElementById('customJs')];
  domElems.forEach(el => el ? el.remove() : undefined);

  const style = document.createElement('style');
  style.setAttribute('id', 'customCss');
  style.setAttribute('type', 'text/css');
  style.innerText = generateCssFromMainColor(data.colors);
  style.innerText += data.customCss;
  document.body.append(style);

  const scr = document.createElement('script');
  scr.setAttribute('id', 'customJs');
  scr.innerText = data.customJs;
  // document.body.append(scr);
}

export function getFormattedCurrencyName(
  names: IStoreAppSetting,
  num: number,
  wordCase: 'nominative' | 'genitive'
): string {
  switch (wordCase) {
    case 'nominative':
      return formatCurrencyNominative(names, num);
    case 'genitive':
      return formatCurrencyGenitive(names, num);
  }
}

function formatCurrencyNominative(names: IStoreAppSetting, num: number): string {
  if (num % 10 > 4 || !(num % 10) || (num > 10 && num < 20)) return names.currencyNameGenitivePlural;
  if (num % 10 === 1) return names.currencyNameNominativeSingular;
  return names.currencyNameGenitiveSingular;
}

function formatCurrencyGenitive(names: IStoreAppSetting, num: number): string {
  if (num % 10 === 1) return names.currencyNameGenitiveSingular;
  return names.currencyNameGenitivePlural;
}

let rootEl: any = null
export function confirm(options: IConfrimOptions) {
  if (!rootEl) {
    rootEl = document.createElement('div');
    (document.getElementById('modalRoot') || document.body).appendChild(rootEl);
  }
  const store = (window as any).store;
  const history = (window as any).storeBrowserHistory;
  if (store) {
    ReactDOM.render(
      <Provider key={'RootModalContainer_stroe'} store={store} >
        <ConnectedRouter key={'RootModalContainer_history'} history={history}>
          <Confirm {...options} key={uuid()} uid={uuid()} root={rootEl} />
        </ConnectedRouter>
      </Provider>,
      rootEl
    );
  } else {
    ReactDOM.render(
      <Confirm {...options} key={uuid()} uid={uuid()} root={rootEl} />,
      rootEl
    );
  }
}

export function confirmProise(options: Omit<IConfrimOptions, 'onAccept' | 'onCancel' | 'onConfirm'>, ...buttons: ('accept')[]) {
  return confirmProiseU(options, ...buttons)
}



const getPathMatchCounts = (pn: string, mpn: string) => {
  let sc = 0;
  const pna = pn.substr(1).split('/').map(i => i.toLowerCase());
  const mva = mpn.substr(1).split('/').map(i => i.toLowerCase());
  if (pna.length >= mva.length && pna[0] === mva[0]) {
    if (exactPathnames.includes(mpn)) {
      if (pn === mpn) sc += 1;
    } else {
      mva.forEach((el, i) => {
        if (el === pna[i]) sc += 1;
      })
    }
  }
  return sc
}

/**
 * get module name by url
 * @param { string } pn
 */
export const getModuleNameByPathname = (pn: string): keyof typeof urls => {
  const p: { n: keyof typeof urls, c: number }[] = [];
  Object.keys(urls).forEach((moduleName) => {
    const mn = moduleName as keyof typeof urls;
    let sc = 0;
    const mpn = urls[mn];
    if (typeof mpn === 'string') {
      sc = getPathMatchCounts(pn, mpn)
    } else if (Array.isArray(mpn)) {
      let sca: number[] = []
      mpn.forEach(mpnv => {
        sca.push(getPathMatchCounts(pn, mpnv))
      });
      sc = sca.reduce((v, c) => c > v ? c : v, 0);
    }
    p.push({ n: mn, c: sc });
  });
  return (p.reduce((r, it) => !r || it.c > r.c ? it : r) || {}).n;
}

/**
 * get module name and path template matched with pathname
 * @param { string } pn 
 */
export const getUrlTemplateByPathName = (pn: string) => {
  const urlModuleName = getModuleNameByPathname(pn);
  let mpn = urls[urlModuleName];
  if (Array.isArray(mpn)) {
    const pna = pn.substr(1).split('/');
    mpn = (mpn as string[]).reduce((mpn, mpnv) => {
      return (!mpn || (mpnv.length < mpn.length && pna.length == mpnv.substr(1).split('/').length && pna[0].toLowerCase() == mpnv.substr(1).split('/')[0].toLowerCase())) ? mpnv : mpn
    });
  }
  return {
    urlModuleName,
    pathTemplate: mpn
  };
}



type IgenerateCssFromMainColorDataKeys = 'primaryColor1' | 'primaryColor2' | 'primaryColor3' | 'additionalColor1' | 'additionalColor2' | 'backgroundColor' | 'backgroundImageUrl';
type IgenerateCssFromMainColorData = {
  [key in IgenerateCssFromMainColorDataKeys]?: string;
}

export const generateCssFromMainColor = (data: IgenerateCssFromMainColorData) => {
  if (!data) return '';
  const css = Object.keys(data).reduce((css, k: any) => {
    const key = k as IgenerateCssFromMainColorDataKeys;
    if (!data[key]) return css;
    const brightness = getBrightnessFromHSL(hexToHSL(data[key]!));
    const backgroundTextColor = brightness < 50 ? '#fff' : '';
    switch (key) {
      case 'primaryColor1': {
        // Статичное левое меню
        css += `.LeftMenu { background-color: ${data[key]}; } `;
        // Строка при выборе на фильтрах
        css += `.Selectize-Button_active { background-color: ${data[key]}; } `;
        // Строка при наведении в списках (раздел с группами, раздел с ДР и другие подобные разделы)
        css += `.List-Item_type_common:hover, .List-Item_type_common:hover .Group-Label, .List-Item_type_common:hover .List-Item-DataPart a.Button,.Suggesters-Results .Item:hover { background-color: ${data[key]};} `;
        // Цвет кошельков в каталоге товаров
        css += `.Shop-Header-Search-Wallet, .ShopCard-Price-Purse, .ShopCard-Price-Purse-Img { background-color: ${data[key]}; } `;
        // Иконки в шапке портала
        css += `.Header-Button-Icon { color: ${data[key]}; } `;

        css += `.CustomStyle .primaryColor1-text { color: ${data[key]}; } `;
        css += `.CustomStyle .primaryColor1-bg { background-color: ${data[key]}; } `;
        css += `.CustomNode-Header, .CustomNode-HeaderWithoutUsers { background-color: ${data[key]}; } `;
        css += `.Virtcurrency-Icon,  .Virtcurrency-IconColl, .ShopWidget-Icon,  .QuestItem-Rewards-Item-Icon { background-color: ${data[key]}} `;
        break;
      }
      case 'primaryColor2': {
        // Строка при наведении в левом меню
        css += `.LeftMenu-Item_open, .LeftMenu-Item_active, .LeftMenu-Item:hover, .LeftMenuItem-Layout_expanded .LeftMenuItem-Body, .LeftMenuItem-Body:hover { background-color: ${data[key]}; } `;
        // styles to mui5 left menu
        css += `.LeftMenuItemMui:hover, .LeftMenuItemMui_selected { background-color: ${data[key]}; }`
        // Строка при наведении на фильтрах
        css += `.Selectize-Button:hover:not(.Selectize-Button_active) { background-color: ${data[key]}; } `;
        // выделения сегодняшнего дня на календаре
        css += `.MuiPickersDay-daySelected:hover{ background-color: ${data[key]}; } `;
        // Выбранный элемент в выпадающем списке (настройки оповещений в группе)
        css += `.SplitButton-Item .Button_main { background-color: ${data[key]}; } `;
        // Выбранный элемент-иконка в шапке портала
        // ????
        css += `.CustomStyle .primaryColor2-text { color: ${data[key]}; } `;
        css += `.CustomStyle .primaryColor2-bg { background-color: ${data[key]}; } `;

        css += ` .MuiButtonBase-root.MuiButton-root.MuiButton-contained:hover { background-color: ${data[key]}; } `;
        break;
      }
      case 'primaryColor3': {
        // Активные кнопки для отправки
        css += `.Button_main { background-color: ${data[key]}; } `;
        // Кнопки сохранения
        css += `.MuiButton-containedPrimary, .MuiTabs-indicator { background-color: ${data[key]}; } `;
        // Подчеркивание выбранной вкладки
        css += `.MuiTabs-indicator { background-color: ${data[key]}; } `;
        // Выбранный чекбокс 
        css += `.MuiCheckbox-colorPrimary svg { color: ${data[key]}; } `;
        css += `.MuiSlider-root { color: ${data[key]}; } `;
        css += `.SurveyPassing-Warning { border-color: ${data[key]}; } `;
        css += `.QuizStart-Warning, .QuizFinish-Warning{  background-color: ${data[key]}; } `;

        // Mui Badge
        css += `.MuiBadge-colorPrimary { color: ${backgroundTextColor}; background: ${data[key]} }`;
        css += `.PresentItemMui-PresentItemMuiStyledBadge .MuiBadge-colorPrimary { background: ${data[key]}; color:#fff }`;
        // Кнопки с variant='outlined' 
        css += `.MuiButton-outlinedPrimary:hover, .MuiButton-outlinedPrimary { border-color: ${data[key]}; color: ${data[key]};  } `;
        // Выбранная вкладка на форме отправки
        css += `.CreateNews .PriorityNav-Button_active { border-bottom-color: ${data[key]}; } `;

        // Ссылки в постах, кликабельные ФИО сотрудников, на виджетах, в т.ч. ссылка "Сколько перечислить в благодарность?"
        // css += `.Button_type_link:visited, .Button_type_rlink:visited, .Button_type_link, .Button_type_rlink { color: ${data[key]}; }`;
        // css += `.Button_type_link:not(.Button_main), .Button_type_rlink:not(.Button_main), .ModalDialogActions-CancelButton, .SecretSantaWidget-SaveButton-Unparticipate, .SecretSantaPart-SaveButton-Unparticipate  { color: ${data[key]}; }`;
        css += `.Button_type_link:not(.Button_main), .Button_type_rlink:not(.Button_main), .List_users_badge .List-Item:hover :not(.Dropdown):not(.Dropdown-List) .Button, .ModalDialogActions-CancelButton, .SecretSantaWidget-SaveButton-Unparticipate, .SecretSantaPart-SaveButton-Unparticipate, .Button.CommonmarkRender-ThanksMore, .MuiLink-underlineNone, .TOSessionsItem-Title { color: ${data[key]}; }`;
        css += `.Button_main.Button_type_link, .Button_main.Button_type_rlink {  background-color: ${data[key]}; }`;
        css += `.BadgeIcon .Bable, .BadgeIconNew .Bable { border: 1px solid ${data[key]}; }`;

        // табы старого вида
        css += `.TabsElement-TabBtn_selected, .LMSCourseTabs-TabBtn_selected, .TabsV2-TabBtn_selected { color: ${data[key]}; border-bottom-color: ${data[key]}; }`;
        // Виджет кошелька +
        // Количество валюты +
        // Цена товаров на виджете
        css += `.Virtcurrency-Count, .Virtcurrency-Icon, .ShopWidget-Price, .SurveyWidget-Navigate-Item { color: ${data[key]}; } `;
        css += `.HeaderMenu-Icon .MuiSvgIcon-root, .Header-Button  .MuiSvgIcon-root { color: ${data[key]}; } `;

        //----------------------------start https://tracker.yandex.ru/COMMON-11357

        // выбранные радиобаттоны 
        css += `.MuiRadio-colorPrimary.Mui-checked { color: ${data[key]}; } `;
       
        // дозагрузки и прогрессбары
        css += `.MUIInfinityList-More .MUIInfinityList-Trigger, .MuiCircularProgress-circle { color: ${data[key]}!important; } `;
      
        css += `.LinearProgress .MuiLinearProgress-bar1Indeterminate, .LinearProgress .MuiLinearProgress-bar2Indeterminate, .LMSListPlaceholder-Progress .MuiLinearProgress-bar1Indeterminate, .SurveyMui-SurveyPassingProgressBox .MuiLinearProgress-bar1Determinate{ background: ${data[key]}; } `;
        css += `.LinearProgress .MuiLinearProgress-indeterminate, .LMSListPlaceholder-Progress, .SurveyMui-SurveyPassingProgressBox { background: ${data[key]}6e; } `;
        css += `.MuiLinearProgress-bar1Indeterminate{ background: ${data[key]}; } `;
        css += `.MuiLinearProgress-indeterminate{ background: ${data[key]}6e; } `;
        // css += `.LinearProgress .MuiLinearProgress-bar1Indeterminate { background: ${data[key]}; } `;

        // элементы ссылок
        css += `.MuiLink-root { color: ${data[key]}; text-decoration-color: ${data[key]}} `;

        // название виджетов перекарашиваем насильно для тех виджетов у которых названия это ссылки
        css += `.RatingBadgeWidget .MuiLink-root, .Widget_type_BadgesNew .MuiLink-root, .Widget_type_ratingsMui  .MuiLink-root, .UserGroupsWidgetNew .MuiLink-root{ color: #000!important;}`;

        css += `.MuiSvgIcon-root.MuiSvgIcon-colorPrimary   { color: ${data[key]}; } `;

        css += `.Button_theme_unstyled_center { color: ${data[key]}; } `;
        css += ` .PostToGroup, .DelayPublicationPicker { color: ${data[key]}; } `;

        // кнопки с вариантом текст и оутлайнд  при наведении и превбю селектора изображени
        css += `.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary:hover, .PreviewSelector-Preview, .primaryColor3-lightBbg   { background: ${data[key]}5e; } `;
        css += `.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary:hover { background: ${data[key]}5e; } `;
        // кнопки с вариантом текст
        css += `.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary { color: ${data[key]}; } `;
        css += `.MUITabs  .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary { color: #fff; } `;

        //  кнопки с иконками
        css += `.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorPrimary, .MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary:not(.Mui-disabled )  { color: ${data[key]}; } `;

        // css += ` .MuiButtonBase-root.MuiButton-root:hover { background-color: ${data[key]}cc; } `;
        // елементы слайдера
        css += `.Mood-Mark-Slider .MuiSlider-root  { color: ${data[key]}; } `;
        css += `.MuiSlider-root>.MuiSlider-rail { background-color: ${data[key]}6e!important; } `;
        css += `.MuiPickersDay-daySelected{ background-color: ${data[key]}; } `;
        // border inputs on focus
        css += `.MuiFormLabel-colorPrimary.Mui-focused:not(.Mui-error), .PreviewSelector-Label  { color: ${data[key]}; }`;
        css += `.MuiInputLabel-root.Mui-focused:not(.Mui-error), .PreviewSelector-Label  { color: ${data[key]}; }`;
        css += `.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-focused:not(.Mui-error) >.MuiOutlinedInput-notchedOutline, .MuiClockPointer-thumb { border-color: ${data[key]}; } `;
        css += `.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused:not(.Mui-error) >.MuiOutlinedInput-notchedOutline { border-color: ${data[key]}; } `;
        css += `.MuiButton-outlined.MuiButtonGroup-grouped:not(:last-of-type) { border-color: ${data[key]}; } `;

        css += `.Widget_type_wikiArticles .MuiTabs-scrollButtons, .HeaderWikiWithFiltersNew .MuiTabs-scrollButtons { border-color: ${data[key]}; } `;
        css += `.Widget_type_wikiArticles .MuiTab-root.MuiTab-textColorPrimary.Mui-selected, .HeaderWikiWithFiltersNew  .MuiTab-root.MuiTab-textColorPrimary.Mui-selected  { color: ${data[key]};  }`;
        css += `.Widget_type_wikiArticles .MuiSvgIcon-root, .HeaderWikiWithFiltersNew .MuiIconButton-colorPrimary { color: ${data[key]} }`;

        css += `.SkillsDialogEditDialog-SkillChip.CustomStyle.primaryColor3-bg  { background-color: ${data[key]}; } `;
        // покраска выбранных даты и времени
        css += `.MuiButtonBase-root.MuiPickersDay-root.Mui-selected, .MuiClockPointer-root, .MuiClockPointer-thumb, .MuiClock-pin, .MuiClockNumber-root.Mui-selected, .MuiClock-wrapper  { background-color: ${data[key]}; } `;
        //----------------------------------------------end https://tracker.yandex.ru/COMMON-11357

        // css += `.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-focused  { border: 1px solid ${data[key]}; }`;

        css += `.MuiTypography-root.MuiTypography-body2.MUITooltipNews-Text  { color: ${data[key]}; } `;
        css += `.MUIModalDialogActions-CancelButton { color: ${data[key]}; } `;

// -------input на странице расширеного поиска
        css += `.SpreadSearchTabs .MuiOutlinedInput-notchedOutline, .PreListFiltersNew-BulitsForSearch .MuiOutlinedInput-notchedOutline { border-color: ${data[key]};} `;
        css += `.SpreadSearchTabs .MuiInputLabel-shrink { color: ${data[key]}!important;} `;

        css += `.CustomStyle .primaryColor3-text { color: ${data[key]}; } `;
        css += `.CustomStyle .primaryColor3-borderColor { border-color: ${data[key]}; } `;
        css += `.CustomStyle .primaryColor3-bg { background-color: ${data[key]}; } `;
        css += `.CustomStyle .primaryColor3-bg .Mui-selected { background-color: ${hexToHSL(data[key] || '', -10)}; } `;
        // drag иконка сортируемого списка
        css += `.SortableList-Dragger { color: ${data[key]}; } `;
        break;
      }
      case 'additionalColor1': {
        // Заливка Конкурсов +
        // Заливка Событий +
        // Заливка Идей
        // Тексты в левом меню
        css += `.NewsView-Body_type_competitions, .NewsView-Body_type_events, .NewsView-Body_type_ideas, .NewsView.type-23 .Events-Layout, .NewsView.type-21 .Ideas-Layout, .NewsView.type-6 .Creativetasks-Layout { background-color: ${data[key]}; } `;
        css += `.LeftMenuItemMui .MuiLink-root { color: ${data[key]}; text-decoration-color: ${data[key]}} `;
        css += `.CustomStyle .additionalColor1-text { color: ${data[key]}; } `;
        css += ` .Virtcurrency-IconColl { color: ${data[key]}; } `;
        css += `.Virtcurrency-Icon .MuiSvgIcon-colorAction, .ShopWidget-Icon .MuiSvgIcon-colorAction, .QuestItem-Rewards-Item-Icon .MuiSvgIcon-colorAction { color: ${data[key]}; } `;
        // перекрестно бьет по стилям списка
        // css += `.MuiListItemIcon-root .MuiSvgIcon-root, .MuiListItemSecondaryAction-root .MuiSvgIcon-root { color: ${data[key]}; } `;
        // так же есть проблема с цветами из админки
        // css += `.LeftMenuMuiSearch  .MuiSvgIcon-root { color: ${data[key]}; } `;

        css += `.CustomStyle .additionalColor1-bg { background-color: ${data[key]}; } `;

        css += `.LeftMenu .LeftMenuItem-Name, .LeftMenu .LeftMenuItem-Label { color: ${data[key]}; }`;
        // css += `.LeftMenu .Button.Button_type_link:visited, .LeftMenu .Button.Button_type_rlink:visited { color: ${data[key]}; }`;
        break;
      }
      case 'additionalColor2': {
        // Элементы на публикации конкурса
        // Элементы на публикации события
        // Элементы на публикации идеи
        css += `.NewsView.type-6 .StageBar { border-color: ${data[key]}; }`;
        css += `.NewsView.type-21 .StageBar { border-color: ${data[key]}; }`;
        css += `.NewsView.type-6 .StageBar .ButtonBox .Button { background-color: ${data[key]}; }`;
        css += `.NewsView.type-21 .StageBar .ButtonBox .Button, .NewsView.type-21 .StageBar .ButtonBox .MuiButtonBase-root { background-color: ${data[key]}; }`;

        css += `.CustomStyle .additionalColor2-text { color: ${data[key]}; } `;
        css += `.CustomStyle .additionalColor2-bg { background-color: ${data[key]}; } `;
        break;
      }
      case 'backgroundColor': {
        // цвет заднего фона
        css += `body { background-color: ${data[key]}; }`;
        break;
      }
      // нужно поправить ссылку на сервере
      // case 'backgroundImageUrl': {
      //   css += `.Background { background-image: url("${data[key]}")!important; }`;
      //   break;
      // }
    }
    return css;
  }, '');
  return css;
}