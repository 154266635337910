import { Translate } from 'localization/src/Translate';
import { Check, Close as CloseIcon, Settings } from 'muicomponents/src/Icons';
import { ToggleButton } from 'muicomponents/src/ToggleButton';
import { Tooltip } from 'muicomponents/src/Tooltip';
import React, { ComponentProps, useCallback } from 'react';
import { connect } from 'react-redux';
import { IStateType } from 'redux/store';
import { cnHeader } from '../Header.index';
import { IconMui } from '../Header.styled';
import {
    IRenderEditSwitcherOwnProps,
    IRenderEditSwitcherProps,
    mapDispatchToProps,
    mapStateToProps,
    TEditDispathProps,
    TEditStateProps,
} from './RenderEditSwitcher.index';
import { ButtonEdit } from './RenderEditSwitcher.styled';

export const RenderEditSwitcherPresenter: React.FC<IRenderEditSwitcherProps> = ({
    edit,
    isAdmin,
    oldMenuEditState,
    oldMenuEdit,
    oldMenuSetData,
    oldMenuUnsave,
    changeVm,
    isMobile,
}) => {
    const editToggle: ComponentProps<typeof ToggleButton>['onClick'] = (event) => {
        changeVm('', { edit: !edit, editTypes: '', saving: (event.currentTarget as any).name === 'save' });
    };

    const CompleteEdit = useCallback(
        () => (
            <>
                <ButtonEdit
                    name="save"
                    startIcon={!isMobile && <Check style={{ color: '#fff' }} />}
                    className={cnHeader('Button', { editToggle: true, rightSide: true })}
                    variant="contained"
                    isMobile={isMobile}
                    onClick={(e) => {
                        if (edit) editToggle(e, '');
                        if (oldMenuEditState) oldMenuSetData();
                    }}
                >
                    <div>{isMobile && <Translate i18nKey="pryaniky.default.save" />}</div>
                </ButtonEdit>

                <ButtonEdit
                    name="unsave"
                    startIcon={!isMobile && <CloseIcon />}
                    className={cnHeader('Button', { editToggle: true, rightSide: true })}
                    variant="text"
                    isMobile={isMobile}
                    onClick={(e) => {
                        if (edit) editToggle(e, '');
                        if (oldMenuEditState) oldMenuUnsave('restore');
                    }}
                >
                    <Tooltip className={'InfoIcon'} title={<Translate i18nKey={'pryaniky.edit.close'} />}>
                        <div>{isMobile && <Translate i18nKey="pryaniky.default.cancel" />}</div>
                    </Tooltip>
                </ButtonEdit>
            </>
        ),
        [edit, editToggle]
    );

    return edit || oldMenuEditState ? (
        <CompleteEdit />
    ) : (
        <Tooltip title={<Translate i18nKey={'pryaniky.edit.title'} />}>
            <ToggleButton
                size="small"
                value={false}
                className={cnHeader('Button', { rightSide: true, editType: true })}
                onClick={(...arg) => {
                    editToggle(...arg);
                    oldMenuEdit(false);
                }}
            >
                <IconMui as={Settings} />
            </ToggleButton>
        </Tooltip>
    );
};

export const RenderEditSwitcher = connect<TEditStateProps, TEditDispathProps, IRenderEditSwitcherOwnProps, IStateType>(
    mapStateToProps,
    mapDispatchToProps
)(RenderEditSwitcherPresenter);
