/**
 * @packageDocumentation
 * @module Widget_Settings_presents
 */
import { i18n } from 'localization';
import { JFdictionary } from '../dictionary';

const { renderers } = JFdictionary;

export default {
    type: renderers.layout,
    elements: [
        {
            type: renderers.input,
            scope: '#/properties/name',
        },
        {
            type: renderers.select,
            scope: '#/properties/type',
        },
        {
            type: renderers.checkbox,
            label: i18n.t('pryaniky.widgets.presents.settings.noData'),
            scope: '#/properties/hideIfNoData',
        },
    ],
};
