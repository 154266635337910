import { lineClampStyles, tableConstantProps } from "blocks/UsersEvents/UsersEvents.constants";
import { styled, Typography } from "muicomponents/src";
import { BorderedBox } from "../UsersEventsTable.styled";

export const CellBox = styled(BorderedBox)({
    padding: '14px 16px',
}) as typeof BorderedBox;

export const HeadCellBox = styled(CellBox)({
    minHeight: tableConstantProps.estimatedRowSize
}) as typeof CellBox;

export const TableContentBox = styled(Typography)({
    ...lineClampStyles
}) as typeof Typography;