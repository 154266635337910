import * as React from 'react';
import * as utils from 'utils/src/utils';

// import { withBemMod } from '@bem-react/core';

import { cnWidget } from '../Widget.index';
import { IWidgetTypeInfoProps } from './Widget_type_info.index';
import './Widget_type_info.scss';
// import { Info } from 'blocks/Info/Info';

import { default as WidgetPresenter } from '../Widget';
import { widgets } from "i.widgets";

const types = {
  common: require('blocks/InfoBlock/InfoBlock').InfoBlock,
}

const type = 'info';

export default class WidgetTypeInfoPresenter extends WidgetPresenter<IWidgetTypeInfoProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const Component = this.getComponent(types);
    return <Component {...this.props}
      className={cnWidget({ type, subtype })}
      settings={this.props.widget.settings}
      context={this.props.wcontext}
      tag={widgets.components.common} />
  }

}

// export const withWidgetTypeInfo = withBemMod<IWidgetTypeInfoProps>(
//   cnWidget(),
//   { type: 'info' },
//   (Presenter) => (
//     (props) => (
//       <Presenter {...props} children={
//         <Info />
//       }/>
//     )
//   )
// );