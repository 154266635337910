import { cnWidgetMui } from "blocks/Widget/Widget.index";
import moment from "moment";
import { Box } from "muicomponents/src";
import { DateRangePicker } from "muicomponents/src/DatePicker";
import React, { ComponentProps, FC, useState } from "react";
import { UsersEventesHead } from "./Head/UsersEventesHead";
import { initUsersEventsTableSettings } from "./Settings/UsersEventsTableSettings";
import { UserEventsTable } from "./Table/UsersEventsTable";
import { UsersEventsContextProvider } from "./UsersEvents.context";
import { HeadBox, TableBox } from "./UsersEvents.styled";

initUsersEventsTableSettings();

export const UsersEvents: FC<ComponentProps<typeof UsersEventsContextProvider> & {
}> = ({
    calendars,
    settings,
    userId,
    cIds
}) => {

    const [ dates, setDates ] = useState<ComponentProps<typeof DateRangePicker>['value']>([moment().toISOString(), moment().add(1, 'month').toISOString()]);

    return (
        <UsersEventsContextProvider userId={userId} cIds={cIds} calendars={calendars} settings={settings}>
            <HeadBox className={cnWidgetMui()}>
                <UsersEventesHead />
                <DateRangePicker value={dates} onChange={(value) => setDates(value)} />
            </HeadBox>
            <TableBox className={cnWidgetMui()}>
                <UserEventsTable dateEnd={dates[1] as string} dateStart={dates[0] as string}/>
            </TableBox>
        </UsersEventsContextProvider>
    )
};