import React, { ComponentProps, FC, useRef, useEffect, useCallback, createRef, useState, useLayoutEffect } from 'react';
import { cnLeftMenu, NLeftMenu, MENU_HIDE_BREAKPOINT } from './LeftMenu.index';
import { LeftMenuBar } from './Bar/LeftMenuBar';
import { useLeftMenu } from './LeftMenu.hooks';
import { Slide, TransitionGroup } from 'muicomponents/src/Transitions/Transitions';
import { Box } from 'muicomponents/src/Box';
// import { styled } from 'muicomponents/src';
import { styled } from 'muicomponents/src/mui/system';
import { ClickAwayListener } from 'muicomponents/src/ClickAwayListener';
import { getLeftMenuModule } from './redux/module';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { menuBarTimeout, minimalMenuWidth, openMenuByHover } from './LeftMenu.constants';
import { mainBarId } from './redux/constants';
import { isMobile } from 'utils/src';
import { useEnvironmentContex } from 'utils/src/EnvironmentContex';

// position: fixed;
// top: $HeaderHeight;
// left: 0;
// height: $HeightIncludeHeader;
// overflow: hidden;
// width: $LeftMenuWidth;
// background-color: #194776;
// z-index: 90;

const top = 72;
// const top = 56;

const TransitionGroupBox = styled(Box)({
  display: 'flex',
  position: 'fixed',
  top,
  left: 0,
  height: `calc(100% - ${top}px)`,
  maxWidth: '100%',
  // backgroundColor: '#194776',
  zIndex: 110,
  transition: `max-width 0.25s ease-in-out`
});

// background: #F0F0F0;

const StyledBar = styled(LeftMenuBar)(({
  id,
  zIndex
}) => ({
  backgroundColor: id === mainBarId ? '#F0F0F0' : '#FFFFFF',
  zIndex: zIndex || 10
}));

const LeftMenuPresenter: FC<NLeftMenu.Props> = ({
  stayOpened
}) => {

  const {
    bars,
    edit,
    setBars,
    loading,
    opened,
    setOpened,
    hide,
    setHide
  } = useLeftMenu();

  const environment = useEnvironmentContex();

  const barsRef = useRef<{ [s: string]: HTMLDivElement }>({});

  const checkWidth = useCallback(() => {
    if (document.body.clientWidth < MENU_HIDE_BREAKPOINT || environment.isTablet || environment.isMobileOS) {
      setHide(true);
    }
    else {
      setHide(false);
    }
  }, []);

  useEffect(() => {
    checkWidth();
    window.addEventListener('resize', checkWidth);
    return () => {
      window.removeEventListener('resize', checkWidth);
    };
  }, []);

  useEffect(() => {
    stayOpened && setOpened(stayOpened);
  }, [stayOpened]);

  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    const barsWidth = Object.typedKeys(barsRef.current || {}).reduce((a: number, c) => {
      const width = (barsRef.current || {})[c]?.clientWidth || 0;
      return a + width;
    }, 0);
    if (barsWidth >= document.body.clientWidth) {
      setOverflow(true);
    } else {
      if (overflow) setOverflow(false);
    }
  }, [bars.toString()]);

  const mouseClicked = useRef(false);

  useLayoutEffect(() => {
    if(bars.length > 1 && !edit) return;
    if(!opened || bars.length === 1 || !edit) mouseClicked.current = false;
  }, [opened, bars, edit]);

  // useLayoutEffect(() => {
  //   if(!edit) mouseClicked.current = false;
  // }, [edit]);

  const onClick = useCallback(() => {
    mouseClicked.current = true;
    setOpened(true);
  }, []);

  const onMouseEnter = useCallback(() => {
    if(!openMenuByHover || isMobile()) return;
    setOpened(true);
  }, []);

  const onMouseMove = useCallback(() => {
    if(!openMenuByHover || isMobile()) return;
    if(!opened) setOpened(true);
  }, [opened]);

  const onMouseLeave = useCallback(() => {
    if(!openMenuByHover || edit || isMobile()) return;
    if(!mouseClicked.current) {
      if (stayOpened) {
        setBars([mainBarId]);
      } else {
        setOpened(false);
      }
    }
  }, [edit, bars, stayOpened]);

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        const target = e.target as HTMLElement;
        if (!edit && opened && target?.id !== 'editMenuButton' && target?.id !== 'DASHUPMENUSCROLLER' && !target?.closest('#DASHUPMENUSCROLLER') && !target.closest('.LeftMenuItemMuiChange') && !target.closest('.MuiAutocomplete-popper')) {
          if (document.body.clientWidth < MENU_HIDE_BREAKPOINT || environment.isTablet || environment.isMobileOS) {
            setHide(true);
          }
          if (stayOpened) {
            setBars([mainBarId]);
          } else {
            setOpened(false);
          }
        }
      }}
    >
      <TransitionGroup
        component={TransitionGroupBox}
        className={cnLeftMenu({ stayOpened, hide, opened })}
        sx={{
          // minWidth: hide ? undefined : minimalMenuWidth,
          maxWidth: hide ? 0 : stayOpened && !opened ? 300 : !opened ? minimalMenuWidth : undefined,
          overflowX: hide ? 'hidden' : opened && overflow ? 'auto' : undefined
        }}
        onClick={onClick}
        onMouseMove={onMouseMove}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {
          bars.map((key, idx, arr) => {
            const id = key.toString();
            const ids = arr.map(el => el.toString());
            return (
              <Slide
                direction="right"
                key={idx}
                onEnter={(el) => {
                  el.style.zIndex = '5';
                }}
                onEntered={(el) => {
                  el.style.zIndex = '';
                }}
                timeout={menuBarTimeout}
              >
                <StyledBar
                  id={id.toString()}
                  zIndex={100 - idx}
                  path={ids.slice(0, idx + 1)}
                  stayOpened={stayOpened}
                  ref={(e) => {
                    barsRef.current[id.toString()] = e;
                  }}
                />
              </Slide>
            )
          })
        }
      </TransitionGroup>
    </ClickAwayListener>
  )
};

export const LeftMenu: FC<ComponentProps<typeof LeftMenuPresenter>> = ({
  ...props
}) => {

  return (
    <DynamicModuleLoader modules={[getLeftMenuModule()]}>
      <LeftMenuPresenter {...props} />
    </DynamicModuleLoader>
  );
};