import { DayHeaderColors } from "../UsersEvents.constants";
import { UsersEventsData, UsersEventsSettings } from "./UsersEventsTableSettings.index";


export const baseUsersEventsData: UsersEventsData = {
    calendars: {}
};

export const baseUsersEventsSettings: UsersEventsSettings = {
    headBlocks: [],
    generatedCalerdarSettings: {
        headerTodayColor: DayHeaderColors.today,
        headerWeekendColor: DayHeaderColors.weekend,
        calendarId: null
    }
};