import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';
import {
    IShopAgreeToParticipateInMultiOrderProps,
    mapDispatchToProps,
    OrderManageTypes,
    mapStateToProps,
} from './ShopAgreeToParticipateInMultiOrder.index';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { DialogBody } from 'muicomponents/src/DialogParts';
import { Translate } from 'localization';
import { Typography } from 'muicomponents/src';
import { connect } from 'react-redux';
import * as utilsProj from 'utils.project/utils.project';
import { TextItem } from 'muicomponents/src/TextItem/TextItem';
import { IShopMakeShopOrderPayload } from 'redux/shop/actions.interfaces';
import { Dispatch } from 'redux';
import * as utils from 'utils/src/utils';
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { toast } from 'react-toastify';
import { currentUserMinusMyThanksCount } from 'utils/src/CommonRedux/users/actions';
import { NumberTextField } from 'muicomponents/src/TextField/TextField';
import { FlexColumnBox, GreyText } from './ShopAgreeToParticipateInMultiOrder.style';
import { Button } from '@mui/material';

const ShopAgreeToParticipateInMultiOrderPresenter = ({
    orderData,
    investedValue,
    minInvestedValue,
    maxInvestedValue,
    fullCost,
    handleAccept,
    handleClose,
    userThanksCount,
    alreadyInvested,
    currencyNames,
    useRedux,
    makeShopOrder,
    typeForm = OrderManageTypes.makeAgreeToParticipate,
    onAccept: onAcceptHandler,
    onClose: onCloseHandler,
    onRefuse,
    investorStatus,
    isWithRequest,
}: IShopAgreeToParticipateInMultiOrderProps) => {
    const { productId, id: orderId, productCount, thanksPrice, forUser, characteristics } = orderData;

    const [stateInvestedValue, setStateInvestedValue] = useState<number | undefined>(investedValue);
    const [disableAccept, setDisableAccept] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    const currentMinInvestedValue = minInvestedValue || 1;
    const currentMaxInvestedValue = maxInvestedValue || fullCost;

    const header =
        typeForm === OrderManageTypes.makeAgreeToParticipate
            ? Translate.t({ i18nKey: 'pryaniky.shop.dialogTitle.typeParticipate' })
            : Translate.t({ i18nKey: 'pryaniky.shop.dialogTitle.typeChangeParticipate' });
    const acceptText =
        typeForm === OrderManageTypes.makeAgreeToParticipate
            ? Translate.t({ i18nKey: 'pryaniky.shop.order.textButton.participate' })
            : Translate.t({ i18nKey: 'pryaniky.shop.order.textDialogButton.changeParticipate' });
    const closeText = Translate.t({ i18nKey: 'pryaniky.shop.order.textButton.close' });

    const userCanRefuse = useMemo(() => {
        return typeForm === OrderManageTypes.makeAgreeToParticipate && investorStatus !== 'refused';
    }, [typeForm, investorStatus]);

    useEffect(() => {
        const isDisable = !!(
            (stateInvestedValue &&
                (stateInvestedValue < currentMinInvestedValue ||
                    stateInvestedValue > currentMaxInvestedValue ||
                    stateInvestedValue > userThanksCount)) ||
            !stateInvestedValue
        );

        setDisableAccept(isDisable);
    }, [stateInvestedValue]);

    let noOrderAcceptText: React.ReactNode = '';
    if (stateInvestedValue && stateInvestedValue > userThanksCount) {
        noOrderAcceptText = (
            <>
                {Translate.t({ i18nKey: 'missing' })} {stateInvestedValue - userThanksCount}{' '}
                {utilsProj.getFormattedCurrencyName(currencyNames, stateInvestedValue - userThanksCount, 'genitive')}
            </>
        );
    }
    if (stateInvestedValue && stateInvestedValue > currentMaxInvestedValue) {
        noOrderAcceptText = (
            <Translate
                i18nKey="pryaniky.shop.order.field.investedValue.validator.max"
                values={{
                    maxValue: `${currentMaxInvestedValue} ${utilsProj.getFormattedCurrencyName(
                        currencyNames,
                        currentMaxInvestedValue,
                        'nominative'
                    )}`,
                }}
            />
        );
    }
    if (stateInvestedValue && stateInvestedValue < currentMinInvestedValue) {
        noOrderAcceptText = (
            <Translate
                i18nKey="pryaniky.shop.order.field.investedValue.validator.min"
                values={{
                    minValue: `${currentMinInvestedValue} ${utilsProj.getFormattedCurrencyName(
                        currencyNames,
                        currentMinInvestedValue,
                        'nominative'
                    )}`,
                }}
            />
        );
    }

    const onChangeInvestedValue: NonNullable<ComponentProps<typeof NumberTextField>['onChange']> = (e) => {
        setStateInvestedValue(e.target.value);
    };

    const onAccept = () => {
        const opts = {
            presentFor: forUser ? [{ id: forUser.id }] : [],
            characteristics,
            groupOrderId: orderId,
            investedValue: stateInvestedValue,
        };
        const result: IShopMakeShopOrderPayload = {
            id: +productId,
            count: productCount,
            thanksPrice,
            ...opts,
        };
        onAcceptHandler && onAcceptHandler(result);
        if (useRedux) makeShopOrder(result);
        if (isWithRequest) {
            setLoading(true);
            utils.API.shop
                .orderV4(+productId, productCount || 1, opts)
                .r.then((response: IBasicResponse) => {
                    setLoading(false);
                    if (utils.checkResponseStatus(response)) {
                        handleAccept(result);
                    } else {
                        toast.error(response.error_text);
                    }
                })
                .catch((e: any) => {
                    setLoading(false);
                    console.error(e);
                });
        } else {
            handleAccept(result);
        }
    };
    const onClose = () => {
        onCloseHandler && onCloseHandler();
        handleClose();
    };

    const onRefuseHandler = useCallback(() => {
        onRefuse?.();
        handleClose();
    }, [onRefuse, handleClose]);

    return (
        <DialogBody
            isLoading={loading}
            header={header}
            disableAccept={disableAccept}
            acceptText={noOrderAcceptText ? noOrderAcceptText : acceptText}
            closeText={closeText}
            onAccept={onAccept}
            onClose={onClose}
            hiddenClose={typeForm === OrderManageTypes.makeAgreeToParticipate}
            contentProps={{
                sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                },
            }}
            actionsProps={{
                sx: {
                    [`.MuiButton-root`]: {
                        order: 1
                    }
                }
            }}
            leftActions={
                userCanRefuse
                ? <Button onClick={onRefuseHandler} size={'small'}>
                    <Translate i18nKey={'pryaniky.shop.order.textButton.refused'} />
                </Button>
                : undefined
            }
        >
            <FlexColumnBox>
                <NumberTextField
                    fullWidth
                    hideApperance
                    label={Translate.t({ i18nKey: 'pryaniky.shop.order.field.investedValue' })}
                    value={stateInvestedValue}
                    inputProps={{
                        min: currentMinInvestedValue,
                        max: userThanksCount < currentMaxInvestedValue ? userThanksCount : currentMaxInvestedValue,
                    }}
                    onChange={onChangeInvestedValue}
                />
                {alreadyInvested && (
                    <TextItem
                        fontSize={'12px'}
                        keyData={Translate.t({ i18nKey: 'pryaniky.list.orders.collectedCurrency' })}
                        splitter={''}
                    >
                        <Typography variant="caption">
                            <GreyText>{alreadyInvested}</GreyText>
                            {`/${fullCost}`}
                        </Typography>
                    </TextItem>
                )}
            </FlexColumnBox>
        </DialogBody>
    );
};

export const ShopAgreeToParticipateInMultiOrder = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShopAgreeToParticipateInMultiOrderPresenter);

export const openShopAgreeToParticipateInMultiOrder = createModal(ShopAgreeToParticipateInMultiOrder, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
        },
    },
    TransitionComponent,
    scroll: 'body',
});

/**
 * @deprecated ? - нигде не используется
 * @param dispatch 
 * @param arg 
 * @returns 
 */
export const openShopAgreeToParticipateInMultiOrderWithRequest = (
    dispatch: Dispatch<any>,
    ...arg: Parameters<typeof openShopAgreeToParticipateInMultiOrder>
) => {
    const [props] = arg;
    return openShopAgreeToParticipateInMultiOrder({ ...props, isWithRequest: true }).then((value: any) => {
        const { investedValue } = value as IShopMakeShopOrderPayload;
        const { typeForm, ...shopData } = props;
        const prevInvestedValue = shopData?.investedValue;
        if (investedValue) {
            prevInvestedValue ? investedValue - prevInvestedValue : investedValue;
            dispatch(
                currentUserMinusMyThanksCount(prevInvestedValue ? investedValue - prevInvestedValue : investedValue)
            );
            toast.success(Translate.t({ i18nKey: 'your order is successfully placed' }));
        }
    });
};
