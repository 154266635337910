import * as React from 'react';
import { cnUsersWidgetCollegues, IUsersWidgetTypeListProps } from '../UsersWidget_type_list.index';
import { UserMention } from 'uielements/src/UserMention/UserMention';
import { Avatar } from 'uielements/src';
import MDRender from 'uielements/src/CommonmarkRender';
import { PositionTypography, DescriptionTypography, BoxFlexGap8, BoxFlexGap16 } from './Styled';
import '../UsersWidget_type_list.scss';
import { Check, Close, Edit } from 'muicomponents/src/Icons';
import { Box, IconButton, Typography, TextField, Divider } from 'muicomponents/src';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { Translate } from 'localization';
import { useDispatch, useSelector } from 'react-redux';
import { updateWidget, setStructureFromWidget } from 'redux/actions/Widgets';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';

export interface IUserListWhithDescription extends IUsersWidgetTypeListProps {
    list: IListItem[];
}

export interface IListItem {
    title: string;
    description: string;
    deleted: boolean;
    list: IUserListWithDescriptionItem['item'][];
    id: string;
}

export interface IUserListWithDescriptionItem {
    item: { [key: string]: any };
    description: string;
    onSave: (
        item: IUserListWithDescriptionItem['item'],
        description: IUserListWithDescriptionItem['description']
    ) => void;
}

const getDescriptionId = (listId: string, itemid: string) => listId + '-' + itemid;

export const Item: React.FC<IUserListWithDescriptionItem> = ({ item, description, onSave }) => {
    const [edit, setEdit] = React.useState(false);
    const [descText, setDescText] = React.useState<string>(description || item.description || '');

    const authUser: any = useSelector(getAuthUser);

    return (
        <Box className={cnUsersWidgetCollegues('Item')}>
            <Avatar
                className={cnUsersWidgetCollegues('Item-Info-Avatar')}
                size={48}
                imgId={item.imgId}
                imgUrl={item.imgUrl}
                name={item.displayName}
            />

            <BoxFlexGap8
                className={cnUsersWidgetCollegues('Item-Info')}
                sx={{
                    flexDirection: 'column',
                }}
            >
                <Box>
                    <BoxFlexGap8
                        sx={{
                            alignItems: 'center',
                            height: '24px',
                            '.UsersWidgetCollegues-Item-Info-Name': {
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                            },
                        }}
                    >
                        <UserMention
                            className={cnUsersWidgetCollegues('Item-Info-Name')}
                            id={item.id}
                            name={item.displayName}
                        />

                        {authUser.baseData.isAdmin && !edit && (
                            <IconButton
                                className={cnUsersWidgetCollegues('Item-Info-EditIcon')}
                                sx={{
                                    position: 'relative',
                                    top: '-3px',
                                }}
                                size={'small'}
                                onClick={() => setEdit(true)}
                            >
                                <Edit fontSize={'small'} />
                            </IconButton>
                        )}
                    </BoxFlexGap8>
                    <PositionTypography variant={'body2'}>{item.position}</PositionTypography>

                    {!edit && descText && (
                        <Tooltip title={descText} overflowOnly={true}>
                            <DescriptionTypography variant={'body2'}>{descText}</DescriptionTypography>
                        </Tooltip>
                    )}
                </Box>

                {edit && (
                    <BoxFlexGap16>
                        <TextField
                            sx={{ width: '100%' }}
                            value={descText}
                            multiline
                            onChange={(e) => {
                                setDescText(e.currentTarget.value);
                            }}
                            label={<Translate i18nKey={'pryaniky.widgets.settings.type.descText'} />}
                        />
                        <IconButton
                            onClick={() => {
                                onSave(item, descText);
                                setEdit(false);
                            }}
                            size={'small'}
                        >
                            <Check fontSize={'small'} />
                        </IconButton>

                        <IconButton
                            onClick={() => {
                                setEdit(false);
                                setDescText(description || item.description || '');
                            }}
                            size={'small'}
                        >
                            <Close fontSize={'small'} />
                        </IconButton>
                    </BoxFlexGap16>
                )}
            </BoxFlexGap8>
        </Box>
    );
};

export const UserListComponent: React.FC<{
    list: IUserListWhithDescription['list'][number];
    listId: string;
    descriptions: { [key: string]: string };
    onSave: (listId: string, ...args: Parameters<React.ComponentProps<typeof Item>['onSave']>) => void;
}> = ({ list, listId, descriptions, onSave }) => {
    const onSaveHandler = React.useCallback<React.ComponentProps<typeof Item>['onSave']>(
        (...args) => {
            onSave(listId, ...args);
        },
        [onSave, listId]
    );

    return (
        <Box className={cnUsersWidgetCollegues('Block')}>
            <Box>
                {list.title && (
                    <Typography variant="subtitle1" className={cnUsersWidgetCollegues('Name')} children={list.title} />
                )}

                {list.description && (
                    <Box className={cnUsersWidgetCollegues('Block-Description')}>
                        <MDRender type="inline" source={list.description || ''} />
                    </Box>
                )}
            </Box>
            <Box
                className={cnUsersWidgetCollegues('Content')}
                sx={{
                    display: 'flex',
                    gap: '16px',
                    gridGap: '16px',
                    flexDirection: 'column',
                }}
            >
                {list.list &&
                    list.list.map((it: any) => {
                        console.log(
                            'descriptions[getDescriptionId(listId, it.id)]',
                            descriptions[getDescriptionId(listId, it.id)]
                        );
                        return (
                            <Item
                                item={it}
                                onSave={onSaveHandler}
                                description={descriptions[getDescriptionId(listId, it.id)]}
                                key={it.id}
                            />
                        );
                    })}
            </Box>
        </Box>
    );
};

export const UserListWhithDescription: React.FC<IUserListWhithDescription> = ({
    tag: TagName = 'div',
    children,
    widget,
    className,
    list,
    ...props
}) => {
    const dispatch = useDispatch();

    const getAlldescriptions = () => {
        const descriptions: { [key: string]: string } = {};
        widget.data?.list.forEach((el: any, index: number) => {
            el.list.forEach((elItem: any) => {
                if (
                    widget.data &&
                    elItem.description &&
                    !widget.data.descriptions?.[getDescriptionId(el.id, elItem.id)]
                ) {
                    descriptions[getDescriptionId(el.id, elItem.id)] = elItem.description;
                }
            });
        });

        if (Object.keys(descriptions).length !== 0) {
            dispatch(
                updateWidget({
                    ...widget,
                    data: {
                        ...widget.data,
                        descriptions: {
                            ...widget.data?.descriptions,
                            ...descriptions,
                        },
                    },
                })
            );
        }
    };

    const save: React.ComponentProps<typeof UserListComponent>['onSave'] = (listID, item, descText) => {
        dispatch(
            updateWidget({
                ...widget,
                data: {
                    ...widget.data,
                    descriptions: {
                        ...widget.data?.descriptions,
                        [getDescriptionId(listID, item.id)]: descText,
                    },
                },
            })
        );
        dispatch(setStructureFromWidget(widget.id));
    };

    return (
        <TagName {...props} className={cnUsersWidgetCollegues({ type: 'list' }, [className])}>
            {list.map((el: any, index) => {
                return (
                    <UserListComponent
                        list={el}
                        key={index}
                        listId={el.id}
                        onSave={save}
                        descriptions={widget.data?.descriptions || {}}
                    />
                );
            })}
        </TagName>
    );
};
