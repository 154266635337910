import { Box, styled } from 'muicomponents/src';
import { DialogBody } from 'muicomponents/src/DialogParts';

export const GreyText = styled('span')({
    color: 'rgba(0, 0, 0, 0.87)',
});

export const FlexColumnBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
}) as typeof Box;
