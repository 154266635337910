import React, { ComponentProps } from 'react';
import * as utils from 'utils/src/utils';
// import i18n from '../../../localizations/i18n';
import { cnWidget } from '../Widget.index';
import { IWidgetTypeTabsControlProps } from './Widget_type_tabsControl.index';
import './Widget_type_users.scss';
import { widgets } from "../../../i.widgets";
import { default as WidgetPresenter } from '../Widget';
import './Widget_type_tabsControl.scss';
import { TabsControl as TabsControlMui } from '../../TabsControlMui';

const type = 'tabsControl';

export default class WidgetTypeTabsControlPresenter extends WidgetPresenter<IWidgetTypeTabsControlProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    return (
      <widgets.components.common
        {...this.props}
        className={cnWidget({type, subtype})}
      >
        <TabsControlMui
          context={this.props.wcontext}
          tabs={(this.props.widget.data || []) as unknown as ComponentProps<typeof TabsControlMui>['tabs']}
          updateContext={(context) => {
            this.props.updateContext(this.props.widget.id, context)
          }}
          edit={this.props.edit}
        />
      </widgets.components.common>
    )
  }

}