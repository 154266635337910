/**
 * @packageDocumentation
 * @module Widget_Settings_timeline
 */
import { JFdictionary } from '../dictionary';
import i18n from 'localizations/i18n';
import { store } from 'redux/store';
import { Actions } from '@jsonforms/core';
import defaultData from './data';
import uischema from './uischema';
import { Translate } from 'localization';

const { types } = JFdictionary;

export let additionalSchemaData: any = {
    type: types.string,
    placeholder: Translate.t({ i18nKey: 'pryaniky.widgets.settings.placeholder.newsType' }),
    maybeEmpty: true,
    opts: [],
    data: [],
};

const timelineTypes = {
    my: 'my', // лента пользователя
    reward: 'reward', // награды пользователя
    feedback: 'feedback', // отзывы пользователя
    notices: 'notices', // лента объявлений
    ideas: 'ideas', // лента идеи
    creativetasks: 'creativetasks', // лента конкурсов
    events: 'events', // лента событий
    polls: 'Опросы', // лента опросов
};

const relationsArray = ['mainTimeline', 'groupsTimeline', 'usersTimeline', 'tagsTimeline'];

const schema = {
    type: types.object,
    properties: {
        name: {
            type: types.string,
            placeholder: i18n.t('pryaniky.widgets.settings.placeholder.name'),
        },
        selectedTags: {
            type: types.object,
            placeholder: i18n.t('ryaniky.widgets.settings.placeholder.chooseTag'),
        },
        selectedGroups: {
            type: types.object,
            placeholder: i18n.t('pryaniky.widgets.settings.placeholder.selectGroup'),
            props: {
                additionalRequestOpts: {
                    excludeSystemGroups: 'true',
                },
            },
        },
        selectedNews: additionalSchemaData,

        count: {
            type: types.number,
            min: 1,
            placeholder: i18n.t('pryaniky.widgets.settings.placeholder.newsCount'),
        },
        hideIfNoData: {
            type: types.boolean,
        },
    },
};

/**
 * аналогично hierarchy... но без саги
 * тут макароны из типов публикаций...
 */
export const prepareData = (data: any) => {
    const state = store.getState();
    const enabledTypes: string[] = state.appSettings.companyPostTypes
        .filter((e: any) => e.enabled)
        .map((e: any) => e.id);
    const usedTypes: string[] = additionalSchemaData.opts.map((e: any) => e.value);
    // получаем массив типов публикаций
    if (
        (enabledTypes.includes('thanks') || enabledTypes.includes('badges') || enabledTypes.includes('achievements')) &&
        !usedTypes.includes('reward')
    ) {
        additionalSchemaData.opts.push(prepareOption('Награды', 'reward'));
    }
    if (enabledTypes.includes('feedback') && !usedTypes.includes('feedback')) {
        additionalSchemaData.opts.push(prepareOption('Отзывы', 'feedback'));
    }
    if (enabledTypes.includes('notices') && !usedTypes.includes('notices')) {
        additionalSchemaData.opts.push(prepareOption('Объявления', 'notices'));
    }
    if (enabledTypes.includes('creativetasks') && !usedTypes.includes('creativetasks')) {
        additionalSchemaData.opts.push(prepareOption('Конкурсы', 'creativetasks'));
    }
    if (enabledTypes.includes('events') && !usedTypes.includes('events')) {
        const it = state.appSettings.companyPostTypes.filter((e: any) => e.id === 'events')[0];
        additionalSchemaData.opts.push(prepareOption(it.displayName, it.id));
    }
    if (enabledTypes.includes('ideas') && !usedTypes.includes('ideas')) {
        const it = state.appSettings.companyPostTypes.filter((e: any) => e.id === 'ideas')[0];
        additionalSchemaData.opts.push(prepareOption(it.displayName, it.id));
    }
    if (enabledTypes.includes('polls') && !usedTypes.includes('polls')) {
        const it = state.appSettings.companyPostTypes.filter((e: any) => e.id === 'polls')[0];
        additionalSchemaData.opts.push(prepareOption(it.displayName, it.id));
    }
    schema.properties.selectedNews = additionalSchemaData;
    if (data) {
        const currentData = Object.keys(defaultData).reduce((acc, cur) => {
            let currentVal = { [cur]: data[cur] };
            if (cur === 'selectedNews') {
                currentVal.newsTypes = data[cur];
            }
            if (cur === 'selectedTags') {
                currentVal.tagId = data[cur]?.id;
            }
            return { ...acc, ...currentVal };
        }, {} as any);
        store.dispatch(Actions.init(currentData, schema, uischema));
    } else {
        store.dispatch(Actions.init(defaultData, schema, uischema));
    }
};

export const prepareOption = (title: string, value: string) => ({
    title,
    value,
});

export default schema;
