import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { Box, Typography, styled } from 'muicomponents/src';
import Stack from '@mui/material/Stack';
import { IStructureProps, cnHierarchyWidgetNew } from '../Widget_type_hierarchyNew.index';
import { AddForm } from './AddForm';

const ConteinerBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    grid: '16px',
    gridGap: '16px',
}) as typeof Box;

export const Structure = ({ children, ...props }: IStructureProps) => {
    return (
        <ConteinerBox>
            <StructureHeader {...props} />
            {children}
        </ConteinerBox>
    );
};

export const StructureHeader = ({ items, setItems, title, ...props }: Omit<IStructureProps, 'children'>) => {
    const { settings } = props;
    const hierarchyName = settings?.hierarchy.name;
    const authUser: any = useSelector(getAuthUser);

    const [newUser, setNewUser] = useState<any>();
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            {authUser.baseData.isAdmin && hierarchyName ? (
                <AddForm
                    {...props}
                    newUser={newUser}
                    setNewUser={setNewUser}
                    items={items}
                    setItems={setItems}
                    title={title}
                />
            ) : (
                <Typography fontWeight="700" className={cnHierarchyWidgetNew('Title')}>
                    {title}
                </Typography>
            )}
        </Stack>
    );
};
