import { cn } from '@bem-react/classname';
import ButtonBase, { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import openNews from 'blocks/Dialogs/News/DialogNewsView/DialogNewsView';
import { Box, Button as MuiButton, Typography } from 'muicomponents/src';
import { useNodeMediaQuery } from 'muicomponents/src/ContainerResizeWatcher/ContainerResizeWatcher';
import { AccessTime, ArrowForward } from 'muicomponents/src/Icons';
import { withNewsContext } from 'News/contexts/news';
import React, { FC, memo } from 'react';
import { connect } from 'react-redux';
import { Button } from 'uielements/src/Button/Button';
import MDRender from 'uielements/src/CommonmarkRender';
import { CustomSettings } from 'utils';
import i18n from '../../../../localizations/i18n';
import { IEventsPropsType, mapDispatchToProps, mapStateToProps, TStateProps } from '../Events.index';
import { makeDate, makeDuration, removeMarkDownLink } from '../Utils';
import './SliderViewNew.scss';

const useMoexDesign = CustomSettings.useMoexDesign();

export const cnClassName = cn('SliderEvent');
export const cnClassNameNew = cn('SliderEventNew');
export const cnClassNameD = cn('SliderEventDetailItem');
export const cnClassNameH = cn('SliderEventHeader');

const useHeaderStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'block',
            color: '#fff',
            marginLeft: '-1px',
            marginRight: '-1px',
            paddingBottom: '8px',
            textAlign: 'center',
        },
    })
);

type TDivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
const DetailItem: FC<{ className?: string; value: string; title: string } & TDivProps> = ({
    className,
    title,
    value,
    ...props
}) => {
    return (
        <div {...props} className={cnClassNameD({}, [className])}>
            <div className={cnClassNameD('Value')}>
                <span className={cnClassNameD('Title')}>{title}:</span>
                {value}
            </div>
        </div>
    );
};

const Header: FC<{ className?: string; startDateTime: string } & ButtonBaseProps> = ({
    className,
    startDateTime,
    ...props
}) => {
    const classes = useHeaderStyles();
    const start = new Date(startDateTime);
    return (
        <ButtonBase {...{ ...props, component: 'div' }} classes={classes} className={cnClassNameH({}, [className])}>
            {/* 'primaryColor1-bg' */}
            <div className={cnClassNameH('Box')}>
                <div className={cnClassNameH('Date')}>{makeDate.dateNoWeekday(start)}</div>
                <div className={cnClassNameH('Day')}>{makeDate.weekday(start)}</div>
            </div>
        </ButtonBase>
    );
};

export const SliderViewNewPresenter: FC<IEventsPropsType & TStateProps> = ({
    text,
    id,
    uevent,
    header,
    attachments,
}) => {
    const { startDateTime, eventLocation, duration } = uevent;
    const [ref, point140] = useNodeMediaQuery({ minWidth: 112 });
    async function open() {
        try {
            const result = openNews({ newsId: id });
        } catch (e) {}
    }

    const photo = (attachments || []).find((file: any) => file.action === 'ImagePreview');
    const title = removeMarkDownLink(header);
    const start = new Date(startDateTime);

    if (useMoexDesign) {
        return (
            <Box ref={ref} className={cnClassNameNew({}, ['slider-type-23'])}>
                <Box
                    className={cnClassNameNew('Content')}
                    sx={{
                        padding: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                    }}
                >
                    <Box
                        className={cnClassNameNew('Detail')}
                        sx={{
                            borderRadius: '8px',
                            border: '1px solid rgba(255, 255, 255, 0.72)',
                            background: 'rgba(255, 255, 255, 0.16)',
                            backdropFilter: 'blur(12px)',
                            display: 'flex',
                            padding: '12px',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: '8px',
                        }}
                    >
                        <Box onClick={open} className={cnClassNameNew('Title')} sx={{ cursor: 'pointer' }}>
                            {/* <Tooltip title={title.length > 40 ? title : ""}> */}
                            <Typography variant="caption">{title}</Typography>
                            {/* </Tooltip> */}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: point140 ? 'nowrap' : 'wrap',
                                alignItems: 'center',
                                gap: '12px',
                                alignSelf: 'stretch',
                            }}
                        >
                            <AccessTime color="primary" />
                            <Box sx={{ maxWidth: point140 ? '80%' : '100%' }}>
                                <Box className={cnClassNameNew('DateNew')}>
                                    <Typography variant="body1" noWrap>
                                        {makeDate.dateNoWeekday(start)}, {makeDate.weekday(start)}
                                    </Typography>
                                </Box>
                                <Box className={cnClassNameNew('DetailItemNew')}>
                                    <Typography variant="body2">{makeDate.time(start)}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box className={cnClassNameNew('Footer')}>
                        <MuiButton variant={'contained'} className={cnClassName('OpenBtn')} onClick={open}>
                            {point140 ? (
                                <Box className={cnClassName('OpenBtnText')}>
                                    {i18n.t('pryaniky.timeline.news.readfull')}
                                </Box>
                            ) : (
                                <ArrowForward sx={{ color: '#000' }} />
                            )}
                        </MuiButton>
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <div className={cnClassName({}, ['slider-type-23'])}>
            <Header onClick={open} className={cnClassName('Header')} startDateTime={startDateTime} />

            <div className={cnClassName('Content')}>
                <div className={cnClassName('Detail')}>
                    <div className={cnClassName('Times')}>
                        <DetailItem
                            className={cnClassName('DetailItem')}
                            title={i18n.t('time')}
                            value={makeDate.time(start)}
                        />
                        <DetailItem
                            className={cnClassName('DetailItem')}
                            title={i18n.t('duration')}
                            value={makeDuration(duration)}
                        />
                    </div>
                    <div className={cnClassName('Location')}>
                        <DetailItem
                            className={cnClassName('DetailItem')}
                            title={i18n.t('location')}
                            value={eventLocation}
                        />
                    </div>
                </div>

                <div onClick={open} className={cnClassName('Title')} title={title}>
                    {title}
                </div>

                <div className={cnClassName('Text')}>
                    <MDRender
                        disableEmojiOne
                        className={cnClassName('Render')}
                        type={'textOnly'}
                        disableShowMoreBtn
                        textCut={true}
                        maxCharCount={90}
                        source={text}
                    />
                </div>

                {photo && <img className={cnClassName('Preview')} src={photo.previewUrl} alt="img" />}

                <div className={cnClassName('Footer')}>
                    <Button
                        theme={'unstyled'}
                        className={cnClassName('OpenBtn', ['primaryColor3-text'])}
                        onClick={open}
                    >
                        {i18n.t('pryaniky.timeline.news.readfull')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export const SliderViewNew = withNewsContext(
    connect(mapStateToProps, mapDispatchToProps)(memo(SliderViewNewPresenter))
);
