import React, { ComponentProps, createContext, FC, useContext } from "react";

type CalendarContext = {
  cIds?: string[];
}

const CalendarContext = createContext<CalendarContext>({});

export const useCalendarContext = () => {
  return useContext(CalendarContext);
};

export const CalendarContextProvider: FC<ComponentProps<typeof CalendarContext['Provider']>> = (props) => {
  return <CalendarContext.Provider {...props} />
}