import { IClassNameProps } from '@bem-react/core';
import {IDispatchProps} from 'redux/connector';
import { cn } from '@bem-react/classname';
import { IStateType as IState } from 'redux/store';
import { getCurrentPage } from 'redux/actions/Widgets';

export interface IBackgroundProps extends IClassNameProps, ReturnType<typeof mapStateToProps>, IDispatchProps {
  tag?: 'div';
}

export interface IBackgroundState {
  text?: string;
  back: any;
  backColor: string;
  showSnow?: boolean;
}

export const mapStateToProps = (state: IState) => ({
  auth: state.store.auth,
  backgroundUrl: state.store.appDesign.backgroundUrl !== '' ? state.store.appDesign.backgroundUrl : undefined,
  pageParams: getCurrentPage(state)
});

export const cnBackground = cn('Background');