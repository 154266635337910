import React, { ComponentProps, FC, useCallback, useContext, useMemo, useRef } from 'react';
import { cnNewsTypePolls } from './Polls.index';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import './Polls.scss';
import {
    Divider,
    Box,
    styled
} from 'muicomponents/src';
import { DraftInput as DraftInputC, useDraftEdit } from 'muicomponents/src/DraftInput/DraftInput';
import { Button } from 'muicomponents/src/Button/Button';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { Checkbox } from 'muicomponents/src/Checkbox';
import { DatePicker as DatePickerC } from 'muicomponents/src/DatePicker';
import { Help, AddCircle, Close } from 'muicomponents/src/Icons';
import { Tooltip } from 'muicomponents/src/Tooltip';
import { FormControl, FormControlLabel, FormHelperText } from 'muicomponents/src/FormControl';
import { Translate } from 'localizations/Translate';

import { useDebounce, useDidUpdateEffect } from 'utils/src/hooks';
import { PrepareErrorText } from 'News/utils/utils.validate';

import { TextField as TextFieldC } from 'muicomponents/src/TextField';
import { usePollAnswers, usePollsEdit } from './Polls.hooks';
import { NewsCreateContext } from 'News/creator/Creator/Creator.constants';
import { toPairs, fromPairs } from 'lodash';
import moment from 'moment';
import { useDraftEditorStyle } from 'News/creator/hooks';
import { TooltipNews } from 'muicomponents/src/TooltipNews/TooltipNews';
import { memoizedComponent } from 'News/creator/utils.sender';
import { PollsAnswers } from './PollsAnswers';
import { NewsChangeReasons } from 'News/creator/redux/actions.types.newsCreator';
import { basePolls } from './Polls.constants';

const prepareI18nKey = (key: string) => `pryaniky.news.create.polls.${key}`;

const StyledCheckBoxLabel = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: 8
}) as typeof Box;

const StyledFormControlLabelC = styled(FormControlLabel)({
    margin: 0
}) as typeof FormControlLabel;

const StyledHelpButton = styled(Help)({
    opacity: .54
});

const FormControlAnswers = styled(FormControl)({
}) as typeof FormControl;

const AnswersBox = styled(Box)({
    gap: 16,
    display: 'flex',
    flexDirection: 'column'
}) as typeof Box;


// const TextField = memoizedComponent(TextFieldC);
const DraftInput = memoizedComponent(DraftInputC);
const DatePicker = memoizedComponent(DatePickerC);
const StyledFormControlLabel = memoizedComponent(StyledFormControlLabelC, true);



const NewsTypePollsPresenter: FC<{}> = ({
}) => {

    const { creatorId } = useContext(NewsCreateContext);

    const {
        data,
        renderParams,
        errors,
        clearEditor,
        changeCreator,
        changeNews
    } = usePollsEdit(creatorId);

    let {
        text: currentNewsText,
        poll = basePolls.poll,
        expires,
        draftRawState
    } = data;

    if(!poll) {
        poll = basePolls.poll;
    }

    const textRef = useRef(currentNewsText);
    textRef.current = currentNewsText;

    const {
        answers: answersProps,
        answerVariants,
        freeAnswer,
        disableRevote,
        showResultsBeforeVote,
        showParticipants
    } = poll;

    const {
        state,
        draftState,
        setDraftState
    } = useDraftEdit(draftRawState, clearEditor);

    useDidUpdateEffect(() => {
        // because text editor push additional state change when state is empty externally
        if(!textRef.current && !state.text) return;
        changeNews({
            text: state.text,
            draftRawState: state.raw
        });
    }, [state]);

    const setAnswersToModel = useCallback<ComponentProps<typeof PollsAnswers>['updateAnswersToModel']>((answers, answerChangeReason) => {
        changeNews({
            poll: {
                answers
            }
        }, answerChangeReason);
    }, []);

    const changeAnswerText = useDebounce(setAnswersToModel, 250, []);

    // when change answers props length check setted error and filter by stayed answers
    useDidUpdateEffect(() => {
        const stayedAnswers = answersProps.map(el => el.id);
        const errorsToRemove = Object.typedKeys(errors).filter(el => el.toString().startsWith('pollAnswer')).filter(el => !stayedAnswers.includes(el.toString().replace(/pollAnswer\./g, '')));
        if(!errorsToRemove.length) return;
        changeCreator({
            errors: fromPairs(toPairs(errors).filter(([key, v]) => !errorsToRemove.includes(key)))
        });
    }, [ answersProps.length ]);

    const draftCommonProps = useDraftEditorStyle();

    const DraftInputProps = useMemo<Partial<ComponentProps<typeof DraftInput>>>(() => ({
        label: <Translate i18nKey={prepareI18nKey('text')} />
    }), []);

    const expiresProps = useMemo<Pick<ComponentProps<typeof DatePicker>, 'TextFieldProps' | 'onChange'>>(() => ({
        TextFieldProps: {
            'test-id': 'timeline-create-polls-expires',
            label: <Translate i18nKey={prepareI18nKey('expires')} />,
            error: Boolean(errors.expires),
            helperText: Boolean(errors.expires) && PrepareErrorText(errors.expires)
        },
        onChange: (iso, date) => {
            const isoWithCurrentMinuteStart = iso ? moment(iso).toISOString() : null;
            changeNews({
                expires: isoWithCurrentMinuteStart,
                poll: {
                    expires: isoWithCurrentMinuteStart || undefined
                }
            });
        }
    }), [errors.expires]);

    const answerVariantsCheckbox = useMemo(() => {
        return (
            <Checkbox
                checked={answerVariants > 1}
                onChange={(e) => {
                    changeNews({
                        poll: {
                            answerVariants: e.target.checked ? 100 : 1
                        }
                    });
                }}
            />
        )
    }, [answerVariants]);

    const answerVariantsLabel = useMemo(() => {
        return (
            <StyledCheckBoxLabel>
                <Translate i18nKey={prepareI18nKey('answerVariants')} />
                <Tooltip title={<Translate i18nKey={prepareI18nKey('answerVariants.help')} />} >
                    <StyledHelpButton fontSize={'small'}/>
                </Tooltip>
            </StyledCheckBoxLabel>
        );
    }, []);

    const freeAnswerCheckbox = useMemo(() => {
        return (
            <Checkbox
                checked={freeAnswer}
                onChange={(e) => {
                    changeNews({
                        poll: {
                            freeAnswer: e.target.checked
                        }
                    });
                }}
            />
        )
    }, [freeAnswer]);

    const freeAnswerLabel = useMemo(() => {
        return (
            <StyledCheckBoxLabel>
                <Translate i18nKey={prepareI18nKey('freeAnswer')} />
                <Tooltip title={<Translate i18nKey={prepareI18nKey('freeAnswer.help')} />} >
                    <StyledHelpButton fontSize={'small'}/>
                </Tooltip>
            </StyledCheckBoxLabel>
        );
    }, []);

    const disableRevoteCheckbox = useMemo(() => {
        return (
            <Checkbox
                checked={disableRevote}
                onChange={(e) => {
                    changeNews({
                        poll: {
                            disableRevote: e.target.checked
                        }
                    });
                }}
            />
        )
    }, [disableRevote]);

    const disableRevoteLabel = useMemo(() => {
        return (
            <StyledCheckBoxLabel>
                <Translate i18nKey={prepareI18nKey('disableRevote')} />
                <Tooltip title={<Translate i18nKey={prepareI18nKey('disableRevote.help')} />} >
                    <StyledHelpButton fontSize={'small'}/>
                </Tooltip>
            </StyledCheckBoxLabel>
        );
    }, []);

    const showResultsBeforeVoteCheckbox = useMemo(() => {
        return (
            <Checkbox
                checked={showResultsBeforeVote}
                onChange={(e) => {
                    changeNews({
                        poll: {
                            showResultsBeforeVote: e.target.checked
                        }
                    });
                }}
            />
        )
    }, [showResultsBeforeVote]);

    const showResultsBeforeVoteLabel = useMemo(() => {
        return (
            <StyledCheckBoxLabel>
                <Translate i18nKey={prepareI18nKey('showResultsBeforeVote')} />
                <Tooltip title={<Translate i18nKey={prepareI18nKey('showResultsBeforeVote.help')} />} >
                    <StyledHelpButton fontSize={'small'}/>
                </Tooltip>
            </StyledCheckBoxLabel>
        );
    }, []);

    const showParticipantsCheckbox = useMemo(() => {
        return (
            <Checkbox
                checked={showParticipants}
                onChange={(e) => {
                    changeNews({
                        poll: {
                            showParticipants: e.target.checked
                        }
                    });
                }}
            />
        )
    }, [showParticipants]);

    const showParticipantsLabel = useMemo(() => {
        return (
            <StyledCheckBoxLabel>
                <Translate i18nKey={prepareI18nKey('showParticipants')} />
                <Tooltip title={<Translate i18nKey={prepareI18nKey('showParticipants.help')} />} >
                    <StyledHelpButton fontSize={'small'}/>
                </Tooltip>
            </StyledCheckBoxLabel>
        );
    }, []);
    

    return (
        <>
        {renderParams && renderParams.showTooltip &&
                <TooltipNews newsParams={renderParams} title={renderParams.tooltipHeader} />
            }
            <DraftInput
                test-id={'timeline-create-polls-text'}
                {...DraftInputProps}
                {...draftCommonProps}
                disableToolbar={!renderParams?.allowRichEdit}
                value={draftState}
                onChange={setDraftState}
                error={Boolean(errors.text)}
                helperText={Boolean(errors.text) && PrepareErrorText(errors.text as any)}
                clearValue={clearEditor}
            />
            <FormControlAnswers
                fullWidth
            >
                <AnswersBox>
                    <PollsAnswers
                        answers={answersProps}
                        errors={errors}
                        updateAnswersToModel={changeAnswerText}
                    />
                </AnswersBox>
                {
                    Boolean((errors.poll as any)?.answers) && 
                    <FormHelperText
                        error={(errors.poll as any)?.answers}
                    >
                        {PrepareErrorText((errors.poll as any)?.answers)}
                    </FormHelperText>
                }
            </FormControlAnswers>
            <DatePicker
                {...expiresProps}
                value={expires || null}
                disablePast
                type={'datetime'}
            />
            <Divider />
            <FormControl
                fullWidth
            >
                <StyledFormControlLabel
                    control={answerVariantsCheckbox}
                    label={answerVariantsLabel}
                />
                <StyledFormControlLabel
                    control={freeAnswerCheckbox}
                    label={freeAnswerLabel}
                />
                <StyledFormControlLabel
                    control={disableRevoteCheckbox}
                    label={disableRevoteLabel}
                />
                <StyledFormControlLabel
                    control={showResultsBeforeVoteCheckbox}
                    label={showResultsBeforeVoteLabel}
                />
                <StyledFormControlLabel
                    control={showParticipantsCheckbox}
                    label={showParticipantsLabel}
                />
            </FormControl>
        </>
    )
};

export const NewsTypePolls = NewsTypePollsPresenter;

export const initNewsPollsCreator = () => ComponentInjector.getInstance().addNode('news_creator_mui5', <NewsTypePolls />, 'polls')