import React, { useEffect, useState } from 'react';
import { Box, Button, styled } from 'muicomponents/src';
import { IContentItemListProps, cnHierarchyWidgetNew } from '../Widget_type_hierarchyNew.index';
import { Translate } from 'localization';
import { ContentItem } from './ContentItem';

const ButtonNoTransform = styled(Button)({
    textTransform: 'none',
    width: 'fit-content',
});

export const ContentItemsList = ({
    items,
    onDelete,
    countOfVisibleItemsInWidget,
    isFinished,
    onClickShowMore,
    ...props
}: IContentItemListProps) => {
    const { settings } = props;
    const hierarchyName = settings?.hierarchy.name;

    const [itemsInContent, setItemsInContent] = useState<JSX.Element[]>([]);

    useEffect(() => {
        if (Array.isArray(items)) {
            const newItemsInContent: JSX.Element[] = [];
            for (let index = 0; index < Math.min(items.length, countOfVisibleItemsInWidget); index++) {
                newItemsInContent.push(
                    <div>
                        <ContentItem
                            item={items[index]}
                            hierarchyName={hierarchyName}
                            onDelete={onDelete}
                            key={items[index].id}
                        />
                    </div>
                );
            }
            setItemsInContent(newItemsInContent);
        }
    }, [items]);
    return (
        <Box className={cnHierarchyWidgetNew('Content')} sx={{ display: 'flex', gap: '18px', flexDirection: 'column' }}>
            {itemsInContent}
            {(itemsInContent.length < items.length || !isFinished) && (
                <ButtonNoTransform variant="text" onClick={onClickShowMore}>
                    <Translate i18nKey={'pryaniky.widgets.users.participants.moex.more'} />
                </ButtonNoTransform>
            )}
        </Box>
    );
};
