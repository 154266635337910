import { connect } from 'react-redux';
import * as React from 'react';
import { mapDispatchToProps, IDispatchProps } from '../../redux/connector';
import { Page } from '../Page/Page';
import { cn } from '@bem-react/classname';
import { pageNames } from 'redux/pageNames';

export const cnBadgesPage = cn('Badge');

const BadgesPagePresenter: React.FC<{}> = () => {

  return <Page className={cnBadgesPage()} page={{ name: pageNames.badges }} />;
};

export const BadgesPage = connect(
  () => {},
  mapDispatchToProps({  })
)(BadgesPagePresenter);
