import { Box, styled } from "muicomponents/src";

export const MainBox = styled(Box)({
    padding: '8px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    gridGap: 8
}) as typeof Box;

export const BlockBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    gridGap: 8
}) as typeof Box;

export const BlockHeaderBox = styled(Box)({
    display: 'flex',
    justifyContent: 'end'
}) as typeof Box;