import urls from "routes/urls";
import { PreviewParams } from "utils/src/PreviewParams";

export const pageNames: {[key in keyof typeof urls]: string} = {
    deviceInfo: '',
    _widgetdebug: 'widgetdebug',
    _appsDebug: 'appsDebug',
    _develop: '',
    achievements: 'achievements',
    album: '',
    badge: 'badge',
    badges: 'badges',
    birthdays: '',
    birthdaysNoMonth: '',
    board: 'board',
    calendar: 'calendar',
    competitions: 'competitions',
    creativetasks: 'creativetasks',
    dash: 'dash',
    dashboard: 'dashboard',
    error500: '',
    events: 'events',
    external: 'external',
    group: 'group',
    groups: 'groups',
    ideas: 'ideas',
    integrate: '',
    /**
     * @deprecated
     * old mamonth page?
     */
    invite: '',
    learningTracks: '',
    lms: '',
    lmsCourse: '',
    lmsCourseCreate: '',
    lmsResult: '',
    lmsStatistics: '',
    lmsStatisticsUser: '',
    lmsTeacherCourse: '',
    lmsTeacherOffice: '',
    lmsTest: '',
    login: '',
    /**
     * @todo
     * exist check
     */
    logout: '',
    messages: '',
    mountainRating: '',
    news: 'news',
    notfound: '',
    notices: 'notices',
    notificationsSettings: '',
    notifiSettings: 'notifiSettings',
    orders: 'orders',
    orgchart: '',
    pages: 'pages',
    photoalbum: '',
    polls: 'polls',
    profileEdit: '',
    quests: 'quests',
    questsmoderate: 'questsmoderate',
    quiz: '',
    rating: 'rating',
    search: '',
    secretsanta: 'secretSantaPage',
    /**
     * @todo
     * old mamonth page?
     */
    settingsDesign: 'settingsDesign',
    shop: 'shopMui',
    shopProduct: '',
    statistics: 'statistics',
    survey: '',
    tag: 'tagBlock',
    trackManage: '',
    tracksManage: '',
    trackView: '',
    user: 'user',
    /**
     * @todo
     * trash?
     */
    userNew: 'userNew',
    users: 'users',
    vacancies: 'vacancies',
    vacancy: 'vacancy',
    wdialogs: '',
    welcome: 'welcome',
    widgets: 'widgets',
    widgets_horizontal_v2: 'widgets_horizontal_v22',
    widgets_v2: 'widgets_v2222',
    wikilist: 'wikilist',
    workflows: 'workflows',
    tasks: 'tasks'

};