import { cn } from '@bem-react/classname';
import { withStyles } from '@mui/styles';
import { LanguageContext } from 'localization';
import { Translate } from 'localizations/Translate';
import { Box, Typography } from 'muicomponents/src';
import { Avatar } from 'muicomponents/src/Avatar';
import { Link } from 'muicomponents/src/Link/Link';
import { LightMentionTooltip } from 'muicomponents/src/Tooltip';
import React, { FC, useMemo } from 'react';
import { generatePath } from 'react-router';
import { mainUrls } from 'utils/src';
import { defaultBadgeIconUrl } from 'utils/src/constants.prn';
import { IPresentData } from 'utils/src/requests/models/api.shop';
import BaseRequests from 'utils/src/requests/requests.base';
import { formatDateSTD } from 'utils/src/utils.dates';
import { ItemBox, StyledBadge, TooltipTitleBox } from './styled';

export const LightMentionTooltipStyled = withStyles((theme) => ({
    tooltip: {
        background: '#fff !important',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        maxWidth: '560px!important',
    },
}))(LightMentionTooltip);

export const cnPresentItemMui = cn('PresentItemMui');
export interface IItemProps {
    item: IPresentData;
    onRemove?: (arg: string) => void;
    size: number;
    widgetType: string;
}

const getI18NKey = (postfix: string) => {
    return `pryaniky.widgets.presents.${postfix}`;
};

export const PresentItemMui: FC<IItemProps> = ({ item, widgetType, onRemove, size }: IItemProps) => {
    const isReceivedGifts = widgetType === 'receivedGifts';

    const presentUserI18key = useMemo(
        () =>
            isReceivedGifts
                ? { action: getI18NKey('sender'), text: getI18NKey('sender.text') }
                : { action: getI18NKey('recipient'), text: getI18NKey('recipient.text') },
        [isReceivedGifts]
    );

    return (
        <LightMentionTooltipStyled
            title={
                <Box
                    className={cnPresentItemMui('PresentItemMuiPopoverBox')}
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        margin: '24px',
                        width: '560px',
                    }}
                >
                    {item?.users?.map((el) => {
                        return (
                            <Box sx={{ marginRight: '19px', maxWidth: '512px' }}>
                                <TooltipTitleBox
                                    className={cnPresentItemMui('PresentItemMuiPopoverBox-TooltipTitleBox')}
                                    sx={{ justifyContent: 'left' }}
                                >
                                    <Avatar src={el.userOrder.imgUrl} sx={{ mr: 1 }} />
                                    <Box
                                        className={cnPresentItemMui(
                                            'PresentItemMuiPopoverBox-TooltipTitleBox-HeaderBox'
                                        )}
                                    >
                                        <Box sx={{ ['> *']: { display: 'inline' } }}>
                                            <Typography
                                                color="ActiveCaption"
                                                variant="body2"
                                                className={cnPresentItemMui(
                                                    'PresentItemMuiPopoverBox-TooltipTitleBox-HeaderBox-DisplayName'
                                                )}
                                            >
                                                <Link href={generatePath(mainUrls.user.id, { id: el.userOrder.id })}>
                                                    {el.userOrder.displayName}
                                                </Link>
                                            </Typography>
                                            &nbsp;
                                            <Typography
                                                color="GrayText"
                                                variant="body2"
                                                className={cnPresentItemMui(
                                                    'PresentItemMuiPopoverBox-TooltipTitleBox-HeaderBox-Localization'
                                                )}
                                            >
                                                <Translate
                                                    values={{
                                                        text: Translate.t({
                                                            i18nKey: presentUserI18key.action,
                                                            context:
                                                                el.userOrder.sex === 'f'
                                                                    ? LanguageContext.female
                                                                    : LanguageContext.male,
                                                        }),
                                                    }}
                                                    i18nKey={presentUserI18key.text}
                                                />
                                            </Typography>
                                            &nbsp;
                                            <Typography
                                                color="InfoText"
                                                variant="body2"
                                                fontWeight={'600'}
                                                className={cnPresentItemMui(
                                                    'PresentItemMuiPopoverBox-TooltipTitleBox-HeaderBox-OrderProductName'
                                                )}
                                            >
                                                {item.orderProductName}
                                            </Typography>
                                        </Box>
                                        <Typography
                                            color="GrayText"
                                            variant="body2"
                                            className={cnPresentItemMui(
                                                'PresentItemMuiPopoverBox-TooltipTitleBox-HeaderBox-Date'
                                            )}
                                        >
                                            {formatDateSTD(el.date, false)}
                                        </Typography>
                                    </Box>
                                </TooltipTitleBox>
                                <Typography
                                    variant="body2"
                                    color="InfoText"
                                    className={cnPresentItemMui(
                                        'PresentItemMuiPopoverBox-TooltipTitleBox-HeaderBox-userComment'
                                    )}
                                >
                                    {el.userComment}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
            }
        >
            <ItemBox key={item.orderProductId} className={cnPresentItemMui('PresentItemMuiBox')}>
                {
                    <Box>
                        <Box
                            sx={{
                                p: 0,
                                '&:hover': {
                                    borderRadius: '50%',
                                    background: 'inherit!important',
                                },
                            }}
                        >
                            <StyledBadge
                                className={cnPresentItemMui('PresentItemMuiStyledBadge')}
                                badgeContent={item.count}
                                color="primary"
                                invisible={item.count === 1}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                            >
                                <Avatar
                                    className={cnPresentItemMui('PresentItemMuiStyledBadge-Avatar')}
                                    src={item.imgUrl || BaseRequests.getUrl(defaultBadgeIconUrl)}
                                    alt={item.orderProductName}
                                    sx={{ width: `${size}px`, height: `${size}px` }}
                                />
                            </StyledBadge>
                        </Box>
                    </Box>
                }
            </ItemBox>
        </LightMentionTooltipStyled>
    );
};
