import { Button, Paper, styled } from "muicomponents/src";
import { IconButton } from "muicomponents/src/IconButton/IconButton";
import { Avatar } from "muicomponents/src/Avatar";


export const EditButton = styled(Button)({
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '6px 0',
    alignItems: 'center',
    fontWeight: '700',
    '&:last-of-type': {
      marginBottom: '0px',
    },
  }) as typeof Button
  
  export const QRButtton = styled(EditButton)({
    '&:first-of-type': {
      marginTop: '0px',
    },
  }) as typeof Button
  
  export const IconWrapper = styled(IconButton)({
    padding: '0',
    marginRight: '10px',
  }) /* as typeof IconButton  - выдаёт ошибку */
  
  export const PaperBox = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
  })) as typeof Paper
  
  export const PaperMarginBox = styled(PaperBox)(({theme}) => ({
    marginTop: theme.spacing(3),
  })) as typeof PaperBox
  
  export const StyledAvatar = styled(Avatar)({
    width: '100%',
    height: 'auto',
    minHeight: 200,
    borderRadius: '4px',
    ['img']: {
      minHeight: 'inherit'
    }
  }) as typeof Avatar