import { LanguageContext } from "localization";
import { Translate } from "localizations/Translate";
import { clone } from "lodash";
import { Box, Checkbox, FormControl, FormControlLabel, IconButton } from "muicomponents/src";
import { ColorPreviewPicker } from "muicomponents/src/ColorPreviewPicker";
import { FilePreviewUploader } from "muicomponents/src/FilePreviewUploader";
import { FilePreviewUploaderFileState } from "muicomponents/src/FilePreviewUploader/FilePreviewUploader.index";
import { ContentCopy } from "muicomponents/src/Icons";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentPage } from "redux/actions/Widgets";
import { WidgetLayoutPageSettings, WidgetLayoutSettings } from "utils/src";
import { ComponentInjector } from "utils/src/ComponentInjector";
import { WidgetsTypes } from "utils/src/widgets/types";
import { SettingsBox } from "./LayoutSettings.styled";

const defaultSettings: WidgetLayoutPageSettings & WidgetLayoutSettings = {
    fullWidth: false,
}

export const LayoutSettingsPr: FC<{
    settings: WidgetLayoutSettings,
    onChange: (value: {
        data: WidgetLayoutSettings
    }) => void;
    setValid: (value: boolean) => void;
}> = ({
    settings,
    onChange,
    setValid
}) => {

    const page = useSelector(getCurrentPage)!;

    const [settingsState, setSettingsState] = useState<typeof defaultSettings>(() => {
        // не может быть массивом, поэтому считаем, что если массив, то настроек вообще нет и используем базовые
        if(Array.isArray(settings)) return clone(defaultSettings);
        return clone({
            ...settings,
            backgroundColor: page.backgroundColor,
            backgroundImage: page.backgroundImage
        });
    });

    useEffect(() => {
        onChange({
            data: settingsState,
        });
    }, [settingsState]);

    return (
        <SettingsBox>
            <FormControl
                fullWidth
            >
                <FormControlLabel
                    sx={{
                        marginLeft: "-4px"
                    }}
                    control={<Checkbox
                        checked={settingsState.fullWidth}
                        onChange={(event) => setSettingsState((ps) => ({...ps, fullWidth: event.target.checked}))}
                    />}
                    label={<Translate i18nKey={'pryaniky.widgets.settings.layout.fullWidth'} />}
                />
            </FormControl>
            <FormControl
                fullWidth
            >
                <FilePreviewUploader
                    file={settingsState.backgroundImage || null}
                    onFileChange={(file) => {
                        setSettingsState((ps) => ({ ...ps, backgroundImage: file }))
                    }}
                    onFileStateChange={(state) => {
                        switch(state) {
                            case FilePreviewUploaderFileState.loading:
                            case FilePreviewUploaderFileState.isError:
                                setValid(false);
                                break;
                            default:
                                setValid(true);
                                break;
                        }
                    }}
                    label={<Translate i18nKey={'pryaniky.widgets.settings.layout.backgroundImage'} />}
                    helperText={<Translate i18nKey={'pryaniky.widgets.settings.layout.backgroundImage.size'} />}
                />
            </FormControl>
            <FormControl
                fullWidth
            >
                <ColorPreviewPicker
                    value={settingsState.backgroundColor || null}
                    onChange={(color) => {
                        setSettingsState((ps) => ({ ...ps, backgroundColor: color as string | null }))
                    }}
                    label={<Translate i18nKey={'pryaniky.widgets.settings.layout.backgroundColor'} />}
                />
            </FormControl>
        </SettingsBox>
    )
};

export const initLayoutSettings = () => {
    ComponentInjector.getInstance().addComponent('WSettings', LayoutSettingsPr, WidgetsTypes.layout);
};
