import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
import i18n from '../localizations/i18n';

export const forbidden2005 = (type?: string): IWidget<IColumn[]> => {
  return generateWidget({
    type: widgets.types.layout,
    isEditable: false,
    data: [
      generateColumn({
        items: [
            generateWidget({
              type: widgets.types.wiki,
              isEditable: false,
              settings: {
                className: 'forbidden2005'
              },
              data: {"blocks":[{"key":"fdsde","text":"Недостаточно прав","type":"header-two","depth":0,"inlineStyleRanges":[{"offset":0,"length":18,"style":"BOLD"},{"offset":0,"length":18,"style":"center"}],"entityRanges":[],"data":{}}],"entityMap":{}}
            }),
          ],
        styles: {
          width: '100%',
        },
      }),
    ],
  });
}