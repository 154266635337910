import { Actions } from '@jsonforms/core';
import React from 'react';
import * as UIElements from 'uielements/src';
import { get_variable_name, get_variable_schema_data } from './utils';

// SelectControl
export const select = (props: any) => {
    if (!props.visible) return null;
    const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {};

    return (
        <UIElements.Select
            placeholder={schema.placeholder}
            onChange={(selected: any) => {
                if (selected) {
                    props.dispatch(
                        Actions.update(get_variable_name(props.uischema.scope), (oldData) => selected.value)
                    );
                } else {
                    if (schema.maybeEmpty)
                        props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => ''));
                }
            }}
            options={schema.opts}
            selected={
                schema.opts &&
                schema.opts.filter((el: any) => {
                    if (props.data.hierarchy) {
                        return el.value.id === props.data[get_variable_name(props.uischema.scope)].id;
                    } else {
                        return el.value === props.data[get_variable_name(props.uischema.scope)];
                    }
                })[0]
            }
        />
    );
};
// end GroupsInputControl
