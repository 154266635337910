import React from 'react';
import i18n from '../../../localizations/i18n';
import * as UIElements from 'uielements/src';
import * as PryanikyForms from '../../PryanikyForms';
import { InputMention } from '../../InputMention/InputMention';
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps, ControlProps } from '@jsonforms/core';
import { DispatchProp } from 'react-redux';
import { connect } from 'react-redux';
import { JFdictionary } from '../../WSettings/structures/dictionary';
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';

// NumberInputControl
export const number = (props: any) => {
    const onChangeData = (e: any) => (oldData: any) => {
        let newValue = e.currentTarget.value;
        if (newValue < schema.min) newValue = schema.min;
        if (newValue > schema.max) newValue = schema.max;
        return newValue;
    };
    if (!props.visible) return null;
    const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {};
    return (
        <>
            {schema.placeholder && <div className={'InputCount-Placeholder'} children={schema.placeholder} />}
            <UIElements.Input
                type="number"
                value={props.data[get_variable_name(props.uischema.scope)]}
                onChange={(e: any) => {
                    props.dispatch(Actions.update(get_variable_name(props.uischema.scope), onChangeData(e)));
                }}
            />
        </>
    );
};
// end NumberInputControl
