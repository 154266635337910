import { NetworkSelector } from 'blocks/NetworkSelector/NetworkSelector';
import { VirtUserSelector } from 'blocks/VirtUserSelector/VirtUserSelector';
import { Translate } from 'localization';
import { KeyboardArrowDown, KeyboardArrowUp } from 'muicomponents/src/Icons';
import { Menu } from 'muicomponents/src/Menu';
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { IStateType } from 'redux/store';
import urls from 'routes/urls';
import { mainUrls } from 'utils/src';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { loadVirtUsers } from 'utils/src/CommonRedux/users/actions';
import { AvatarButton, AvatarDropdownIconMui, AvatarImage, AvatarImageBox } from '../Header.styled';
import { IUserMenuProps } from './UserMenu.index';
import { MenuItem } from './UserMenu.styled';
import { UserNestedMenu } from './UserNestedMenu/UserNestedMenu';

// const userStatus = ['В сети', 'Не беспокоить', 'Отсутствую'];

const useVirtUsers = () => {
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        dispatch(loadVirtUsers());
    }, []);

    const virtUsers = useSelector((state: IStateType) => state.users.virtUsers);

    return virtUsers;
};

export const UserMenu: React.FC<IUserMenuProps> = ({ user, nameNetwork }) => {
    const networks = useSelector((state) => getAuthUser(state).baseData.networks);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    // const [activeStatus, setActiveStatus] = useState<number>(0);
    const history = useHistory();
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const virtUsers = useVirtUsers();

    return (
        <>
            <AvatarButton onClick={handleClick}>
                <AvatarImageBox>
                    <AvatarImage src={user.baseData.userPhotoUrl} />
                    {/* Будущий функционал с выбором статуса профиля */}
                    {/* <CircleIndicator sx={{bacgroundColor: '#4CAF50'}} /> */}
                </AvatarImageBox>
                <AvatarDropdownIconMui
                    className="primaryColor3-text"
                    as={KeyboardArrowDown}
                    sx={{
                        transform: open ? 'rotate(180deg)' : undefined
                    }}
                />
            </AvatarButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                mousePassBackdrop
                PaperProps={{
                    sx: {
                        width: 220,
                        top: '66px !important',
                    },
                }}
            >
                <MenuItem
                    onClick={() => {
                        handleClose();
                        history.push(generatePath(mainUrls.user.id, { id: user.extData.userId }));
                    }}
                >
                    <Translate i18nKey={'profile.my'} />
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        handleClose();
                        history.push(generatePath(urls.profileEdit, { id: user.extData.userId }));
                    }}
                >
                    <Translate i18nKey={'profile.settings'} />
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        handleClose();
                        history.push(
                            generatePath(urls.notifiSettings[2], {
                                uid: user.extData.userId,
                                tab: 'common',
                            })
                        );
                    }}
                >
                    <Translate i18nKey={'notifications settings'} />
                </MenuItem>

                {!!virtUsers.length && (
                    <UserNestedMenu
                        title={<Translate i18nKey={'pryaniky.header.menu.item.changeUser'} />}
                        text={user.baseData.displayName}
                    >
                        <VirtUserSelector />
                    </UserNestedMenu>
                )}

                {networks.length > 1 && (
                    <UserNestedMenu
                        title={<Translate i18nKey={'pryaniky.header.menu.item.changeNetwok'} />}
                        text={nameNetwork}
                    >
                        <NetworkSelector />
                    </UserNestedMenu>
                )}

                {/* Будущий функционал с выбором статуса профиля */}
                {/* {
          userStatus.map((status, index) => 

            <UserStatusItem 
              title={status} 
              index={index} 
              key={index} 
              setActive={setActiveStatus} 
              active={activeStatus} 
            />)
        } */}
                <MenuItem onClick={() => history.push(mainUrls.logout)}>
                    <Translate i18nKey={'pryaniky.header.menu.item.logout'} />
                </MenuItem>
            </Menu>
        </>
    );
};
