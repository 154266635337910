import React, { FC } from 'react';
import { TableVirtualizedItemProps } from "muicomponents/src/TableVirtualized";
import { CellBox, HeadCellBox } from './Cell.styled';
import { styled } from 'muicomponents/src';
import { lineClampStyles } from 'blocks/UsersEvents/UsersEvents.constants';
import { BottomBorderedBox } from '../UsersEventsTable.styled';
import { cn } from '@bem-react/classname';

const SpaceBox = styled(BottomBorderedBox)({
    minHeight: 15
}) as typeof BottomBorderedBox;

const cnCell = cn('TableCell');

export const CellComponent: FC<TableVirtualizedItemProps & { 
    isHeadUser: boolean;
    className?: string;
}> = (({
    isHeadUser,
    ...props
}) => {
    if(isHeadUser) {
        return <div className='HeadUserWrapper' ref={props.registerChild as any}>
            <HeadCellBox
                {...props}
                className={cnCell({}, [props.className])}
            />
            <SpaceBox className='SpaceBox' />
        </div>
    }
    return <CellBox
        {...props}
        className={cnCell({}, [props.className])}
        ref={props.registerChild}
    />
});

export const TableCellNamesHeaderBox = styled(CellComponent)({
    maxWidth: 200
}) as typeof CellComponent;

export const TableCellDaysBox = styled(CellComponent)({
    maxWidth: 80,
    textAlign: 'center'
}) as typeof CellComponent;

export const TableCellDivisionBox = styled(CellComponent)({
    maxWidth: 260
}) as typeof CellComponent;