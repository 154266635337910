import * as React from 'react';
import * as utilsDep from 'utils.project/utils.project';
import * as utils from 'utils/src/utils';
import ReactSortable from 'react-sortablejs';

import { IWidgetTypeLayoutProps, IWidgetTypeLayoutState } from './Widget_type_layout.index';
import './Widget_type_layout.scss';
import { Button } from 'uielements/src/Button/Button';
import { Icon } from 'uielements/src/Icon/Icon';
import { IColumn, prepareWidget, generateColumn } from 'i.widgets';

import { widgets } from 'i.widgets';

import { default as WidgetPresenter } from '../Widget';
import { cnWidget } from '../Widget.index';
import { COLUMN_BREAKPOINT } from 'utils/src/utils';
import { WidgetsAdd } from 'blocks/Dialogs/Widgets/Add/WidgetsAdd';
import { DialogWidgetAdd } from 'blocks/Dialogs/Widgets/AddNew/WidgetsAddNew';
import i18n from 'localizations/i18n';
import { ColumnChanger } from '../ColumnChanger/ColumnChanger';
import { toast } from 'react-toastify';
import ReactSwipe from 'react-swipe';
import { SwipeMenu } from '../SwipeMenu';
import { initLayoutSettings } from 'Widgets_v2/Settings/Layout/LayoutSettings';

const columnMinWidth = 30;

const type = 'layout';

initLayoutSettings();

export default class WidgetTypeLayoutPresenter extends WidgetPresenter<IWidgetTypeLayoutProps, IWidgetTypeLayoutState> {
  // public qew = this.addWidget
  public state = {
    smallScreen: window.innerWidth < COLUMN_BREAKPOINT,
    showAddWidget: false,
    addWidgetToColumn: '',
    swipeActive: 0
  }
  private resizeTimeout: NodeJS.Timeout;
  private swipeRef: any;
  private columnRefs: {
    [id: string]: HTMLDivElement | null;
  } = {}

  // constructor(props: IWidgetTypeLayoutProps) {
  //   super(props);
  // }

  // private swipeVisualize = (item: HTMLElement, count: number) => {
  //   new Array(count).fill(0).forEachTimed(350, (_, idx) => {
  //     if (!(idx % 2)) {
  //       item?.classList.add('SwipeTranslate')
  //     } else {
  //       item?.classList.remove('SwipeTranslate');
  //     }
  //   });
  // }

  public componentDidMount() {
    super.componentDidMount();
    window.addEventListener('resize', this.handleResize);
    // const isMobile = utils.isMobile();
    // if (isMobile && this.swipeRef && this.props.widget.data?.length > 1) {
    //   const itemId = this.props.widget.data ? this.props.widget.data[0].id : 0;
    //   const htmlCollumnsItems = this.props.widget.data?.map((el: IColumn) => this.columnRefs[el.id]).filter((e: HTMLDivElement | null) => e);
    //   const jerkingItems: HTMLDivElement[] = htmlCollumnsItems.slice(0, 2);
    //   jerkingItems.forEach(element => {
    //     this.swipeVisualize(element, 4);
    //   });
    // }
  }

  public componentWillUnmount() {
    super.componentWillUnmount()
    window.removeEventListener('resize', this.handleResize);
  }

  public render() {
    // if (window.PRN_SERVICE.dev_features) return this.renderCollumnChange();
    return this.renderCommon();
  }

  private handleResize = () => {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(this.rerenderColumns, 100);
  }

  private rerenderColumns = () => {
    if (window.innerWidth > COLUMN_BREAKPOINT && this.state.smallScreen) this.setState({ smallScreen: false });
    else if (window.innerWidth < COLUMN_BREAKPOINT && !this.state.smallScreen) this.setState({ smallScreen: true });
  }

  private showAddWidget = (el: React.SyntheticEvent<HTMLButtonElement>) => this.setState({ showAddWidget: true, addWidgetToColumn: el.currentTarget.name })
  private closeAddWidget = () => this.setState({ showAddWidget: !this.state.showAddWidget, addWidgetToColumn: '' });

  private prepareWidgets = (items: string[]) =>
    items.map(item => {
      if (!item || !this.props.widgets[item]) return null;
      return prepareWidget(this.props.widgets[item]);
    });

  private onDraged = (columnId: string, items: string[]) => {
    let { widget } = this.props;
    widget = utils.cloneObject(widget);
    if (widget.data) widget.data.forEach((colunm: IColumn) => {
      if (colunm.id === columnId) colunm.items = items
      // .reduce((acc, cur) => {
      //   acc.push(this.props.widgets[cur]);
      //   return acc;
      // }, [] as IWidget<any, any>[]);
    });
    this.props.dragWidget(widget);
  };

  /**
   * addWidget
   */
  private addWidgetv2 = (newWidget: { [s: string]: any }) => {
    newWidget.colId = newWidget.columnId;
    newWidget.relations = [this.props.widget.id, newWidget.id];
    this.props.addWidget(newWidget);
  }

  private sliderCallback = (index: number, elem: HTMLDivElement) => {
    this.setState({ swipeActive: index });
  }

  private renderWithSlider = () => {
    const customClassNames = '';
    const { children, className = '', editType } = this.props;

    let { data } = this.props.widget;
    const dataId = this.props['data-id'];
    const isMobile = utils.isMobile();

    return (
      <widgets.components.common
        data-id={dataId}
        className={cnWidget({ type, edit: this.props.edit, isMobile }, [customClassNames, className])}>
        {data?.length > 1 && <SwipeMenu className={cnWidget('Swipe-Menu')} active={this.state.swipeActive} count={data?.length || 0} />}
        {
          data &&
          data?.length > 1 ?
          <ReactSwipe
            className={cnWidget('Swipe')}
            swipeOptions={{
              speed: 300,
              continuous: false,
              stopPropagation: true,
              callback: this.sliderCallback
            }}
            childCount={data.length}
            ref={(swipe: any) => {
              this.swipeRef = swipe;
            }}
          >
            {
              data.map((column: IColumn, i: number, arr: IColumn[]) => {
                const items = this.prepareWidgets(column.items as string[]);
                return (
                  <div
                    ref={(el) => this.columnRefs[column.id] = el}
                    key={column.id}
                    id={column.id}
                    className={cnWidget('Column', {
                      preActive: i === this.state.swipeActive - 1,
                      swipeActive: i === this.state.swipeActive,
                      postActive: i === this.state.swipeActive + 1,
                      swipe: true,
                      mobileHidden: column.mobileHidden
                    })}>
                    <div className={cnWidget('Column-SwipeWrapper')}>
                      {items}
                    </div>
                  </div>
                );
              })
            }
          </ReactSwipe>
          : data?.map((column: IColumn, i: number, arr: IColumn[]) => {
            const items = this.prepareWidgets(column.items as string[]);
            return (
              <div
                ref={(el) => this.columnRefs[column.id] = el}
                key={column.id}
                id={column.id}
                className={cnWidget('Column', {
                  preActive: i === this.state.swipeActive - 1,
                  swipeActive: i === this.state.swipeActive,
                  postActive: i === this.state.swipeActive + 1,
                  swipe: true,
                  mobileHidden: column.mobileHidden
                })}>
                {items}
              </div>
            );
          })
        }
      </widgets.components.common>
    )
  }

  private renderCommon = () => {
    const customClassNames = '';
    const { children, className = '', editType } = this.props;
    const isMobile = utils.isMobile();
    // const { data } = this.props.widgetData || {
    //   data: []
    // };
    if (!this.props.widget) return null;
    if (isMobile) return this.renderWithSlider();

    const { data } = this.props.widget;
    const dataId = this.props['data-id'];

    return (
      <widgets.components.common
        data-id={dataId}
        className={cnWidget({ type, edit: this.props.edit, isMobile }, [customClassNames, className])}>
        {/* {!data && <Button onClick={this.addLayout} children={i18n.t('create')} />} */}
        {/* {
          this.props.edit &&
          <WidgetsAdd
            data={{
              columnId: this.state.addWidgetToColumn,
            }}
            isShown={this.state.showAddWidget}
            onConfirm={this.addWidgetv2}
            onClose={this.closeAddWidget}
          />
        } */}
        {data &&
          data.map((column: IColumn, i: number, arr: IColumn[]) => {
            const len = arr.length;
            const items = this.prepareWidgets(column.items as string[]);
            const styles: React.CSSProperties = { ...column.styles };
            if (!styles.minWidth && styles.width) styles.minWidth = styles.width;
            !column.mobileHidden && this.state.smallScreen && (styles.width = '100%');
            
            const padding = 2;
            const paddingStyles = `0 ${padding / 2}%`;
            Object.assign(styles, {
              padding: paddingStyles
            });
            
            // remove from setted width 2% to padding by horizontal sides for more currect column width
            if (styles && styles.minWidth) {
              // base padding in number;
              const width = parseFloat(styles.minWidth + '') - padding + '%';
              Object.assign(styles, {
                minWidth: width
              });
            }
            // if old property width setted then remove it
            if (styles.width) delete styles.width;

            return (
              <div key={column.id} id={column.id} className={cnWidget('Column', { mobileHidden: column.mobileHidden })} style={styles}>
                {this.props.edit ? (
                  <>
                    <Button
                      name={column.id}
                      onClick={() => DialogWidgetAdd({ columnId: column.id,  widgetId: this.props.widget.id }).then(this.addWidgetv2)}
                      title={i18n.t('pryaniky.widgets.add.widget')}
                      className={cnWidget('Column-AddWidget')}
                      children={<Icon icon="plus" />}
                    />
                    <ReactSortable
                      options={{
                        group: 'shared',
                        onStart: (ev: any) => {
                          this.props.setDraggingElem((ev.item && ev.item.getAttribute('data-id')) || undefined)
                          this.props.changeWidgetsViewType('small')
                        },
                        onEnd: (ev: any) => {
                          this.props.setDraggingElem();
                          this.props.changeWidgetsViewType('full');
                        },
                        onAdd: () => {
                          this.props.setDraggingElem();
                          this.props.changeWidgetsViewType('full');
                        }
                      }}
                      onChange={this.onDraged.bind(this, column.id)}
                      className={cnWidget('Sortable')}
                      children={items}
                    />
                  </>
                ) : (
                  items
                )}
              </div>
            );
          })}
        {children}
      </widgets.components.common>
    );
  }
}
