import moment from "moment";

export const getDatesFromTo = (start: moment.Moment, end: moment.Moment) => {
    const dates: moment.Moment[] = [];
    let date = start.clone();
    while(date.get('D') < end.get('D')) {
        dates.push(date);
        date = date.clone().set('D', date.get('D') + 1);
    }
    dates.push(end.clone());
    return dates;
};