import { lineClampStyles } from "blocks/UsersEvents/UsersEvents.constants";
import { styled, Typography } from "muicomponents/src";
import { BorderedBox } from "../UsersEventsTable.styled";

export const HeaderBox = styled(BorderedBox)({
    padding: '4px 16px 12px',
}) as typeof BorderedBox;

export const TableContentHeaderBox = styled(Typography)({
    fontWeight: 500,
    ...lineClampStyles
}) as typeof Typography;
