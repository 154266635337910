import { IStateType as IState } from 'redux/store';
import { ICalendarCalFilter } from './interfaces';

export const getCalendarState = (state: IState) => state.calendar;

export const getCalendarStateField = <Key extends keyof ReturnType<typeof getCalendarState>> (key: Key) => 
    (...args: Parameters<typeof getCalendarState>) => {
        return getCalendarState(...args)[key]
    };

export const getCalendarCalFiltersSelector = (state: IState): ICalendarCalFilter[] => state.calendar.calendars;

export const getCalendarEventsSelector = (state: IState) => state.calendar.calendarEvents;

export const selectworkflowFilters = (state: IState) => state.calendar.workflow;