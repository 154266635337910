import { Box, Typography } from 'muicomponents/src';
import { styled } from 'muicomponents/src/mui/system';
import LoadButton from 'uielements/src/MaterialElements/ProgressButton';

const NewsTimelineHeader = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    marginTop: '24px',
    padding: '0 24px',
}) as typeof Box;

const NewsTimelineHeaderTitle = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '116%',
    paddingLeft: '6.5px',
}) as typeof Typography;

const NewsTimelineHeaderNavigation = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    '.MuiButton-root:hover': {
        background: 'inherit',
    },
    '.MuiButton-root': {
        minWidth: '20px',
        width: '20px',
        padding: '0px !important',

        '@media (max-width: 1024px)': {
            minWidth: '16px',
            width: '16px',
        },
    },
}) as typeof Box;

const NewsTimelineHeaderIndicator = styled(Box)({
    minWidth: '25px',
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '166%',
    '& span': {
        color: '#999',
    },
}) as typeof Box;

const NewsTimelineHeaderButton = styled(LoadButton)({}) as typeof LoadButton;

export {
    NewsTimelineHeader,
    NewsTimelineHeaderButton,
    NewsTimelineHeaderIndicator,
    NewsTimelineHeaderNavigation,
    NewsTimelineHeaderTitle,
};
