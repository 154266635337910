import { cn } from '@bem-react/classname';
import { IBodyProps } from 'utils/src/DialogCreator';

export const cnHierarchyWidgetNew = cn('HierarchyWidgetNew');

export interface IHierarchyInfo {
    companyUsersCount?: number;
    totalUserCount?: number;
    isFinished?: boolean;
}
export interface IUsersHierarchyWidgetProps {
    context: { [s: string]: any };
    settings?: any;
    tag?: React.ComponentType | 'div';
}

export interface ContentItemProps {
    item: any;
    hierarchyName: any;
    onDelete: (v: any) => void;
}

type commonStructureAndAddForm = {
    title: string;
    items: any[];
    setItems: (v: any) => void;
} & Omit<IUsersHierarchyWidgetProps, 'countOfVisibleItemsInWidget' | 'tag'>;

export type IAddFormProps = {
    newUser: any;
    setNewUser: (arg: any) => void;
} & commonStructureAndAddForm;

export type IStructureProps = {
    children?: React.ReactNode;
} & commonStructureAndAddForm;

export interface IDialogBodyShowAllProps extends IBodyProps {
    items: any[];
    header: React.ReactNode;
    loadMore: (skipCount: number, setLoading: (v: boolean) => void, setData: (v: any) => void) => void;
    hierarchyName: any;
    onDelete: (v: any) => void;
    defaultIsFinished?: boolean;
}

export type IContentItemListProps = {
    onDelete: (v: any) => void;
    countOfVisibleItemsInWidget: number;
    isFinished?: boolean;
    onClickShowMore?: () => void;
    items: any[];
} & IUsersHierarchyWidgetProps;
