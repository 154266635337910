import { ChatList } from 'blocks/Chat/ChatList/ChatList';
import { MENU_HIDE_BREAKPOINT } from 'blocks/LeftMenuMui/LeftMenu.index';
import { Notifications } from 'blocks/Notifications/Notifications';
import { Translate } from 'localizations/Translate';
import { Box } from 'muicomponents/src';
import { ClickAwayListener } from 'muicomponents/src/ClickAwayListener';
import { useNodeMediaQuery } from 'muicomponents/src/ContainerResizeWatcher/ContainerResizeWatcher';
import {
    ChatBubbleOutline,
    Home as HomeIcon,
    Menu as MenuIcon,
    NotificationsNone,
    Search as SearchIcon,
    SearchOff,
} from 'muicomponents/src/Icons';
import { Link } from 'muicomponents/src/Link/Link';
import { SuggesterQuick } from 'muicomponents/src/SuggesterQuick';
import { ToggleButton } from 'muicomponents/src/ToggleButton';
import { Tooltip } from 'muicomponents/src/Tooltip';
import React, { ComponentProps, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useEnvironmentContex, withEnvironmentContext } from 'utils/src/EnvironmentContex';
import { cnHeader, IHeaderProps, mapDispatchToProps, mapStateToProps } from './Header.index';
import { HeaderButtonsBox, HeaderItemBox, HeaderItemFlexBox, HeaderWrapper, LogoImg } from './Header.styled';
import { Menu } from './Menu/Menu';
import { RenderEditSwitcher } from './RenderEditSwitcher/RenderEditSwitcher';
import { UserMenu } from './UserMenu/UserMenu';

export const HeaderPresenter: React.FC<IHeaderProps> = (props) => {
    const {
        isAdmin,
        chatEnable,
        isEditable,
        homePage,
        titleLogo,
        showLogoInHeader = false,
        menuHide,
        leftMenuSetReducerField,
        className,
        menuHidden,
        audioPlayList,
        children,
        lifeCycleStructureFromServer,
        activePage,
        isMobileOS,
        authUser,
        notificationCount,
        nameNetwork,
        edit,
        messagesCount,
        getNotificationCount,
        resetNotificationCount,
        filters,
        history,
    } = props;
    const [previewLogo, setPreviewLogo] = useState<string>('');
    const [showSuggester, setShowSuggester] = useState<boolean>(true);
    // console.log("showLogoInHeader", showLogoInHeader, "previewLogo", previewLogo, "titleLogo", titleLogo)
    const [ref, point700] = useNodeMediaQuery({ minWidth: 665 });
    let [refForMenu, menuPoint] = useNodeMediaQuery({ minWidth: MENU_HIDE_BREAKPOINT });
    const environment = useEnvironmentContex();

    if (environment.isTablet || environment.isMobileOS) {
        menuPoint = false;
    }

    useEffect(() => {
        // console.log("window.location.href.indexOf('stylePreview=true') !== -1", window.location.href.indexOf('stylePreview=true') !== -1)
        if (window.location.href.indexOf('stylePreview=true') !== -1) {
            // console.log("window.location.href.indexOf('stylePreview=true') !== -1", window.location.href.indexOf('stylePreview=true') !== -1)
            const dt = localStorage.getItem('stylePreview');
            if (dt) {
                const { mobileImageUrl } = JSON.parse(dt) as any;
                setPreviewLogo(mobileImageUrl);
            }
        }
    }, []);

    useEffect(() => {
        if (point700) {
            setShowSuggester(true);
        } else {
            setShowSuggester(false);
        }
    }, [point700]);

    // console.log("showLogoInHeader", showLogoInHeader, "previewLogo", previewLogo, "titleLogo", titleLogo)
    const GoHomeComponent = useMemo(() => {
        // show home icon if viewport size is small or not showLogoInHeader or not define previewLogo and titleLogo
        if (!point700 || !showLogoInHeader || (!previewLogo && !titleLogo)) {
            return (
                <ToggleButton size="small" value={false} onClick={() => history.push(homePage || '/dash')}>
                    <HomeIcon className={cnHeader('IconHome CustomStyle primaryColor3-text')} />
                </ToggleButton>
            );
        }

        // экран большой есть чекбокc showLogoInHeader true, есть картинка, - картинка
        if (showLogoInHeader && (previewLogo || titleLogo)) {
            return (
                <Link
                    href={homePage || '/dash'}
                    children={
                        <LogoImg
                            className={cnHeader('TitleLogo')}
                            src={previewLogo || titleLogo?.replace(/\/\//g, '/').replace(':/', '://')}
                        />
                    }
                />
            );
        }

        return null;
    }, [previewLogo, point700, showLogoInHeader, titleLogo]);

    const closeSuggester: ComponentProps<typeof ClickAwayListener>['onClickAway'] = (e) => {
        const target = e.target as HTMLElement;
        if (
            target.id === 'QUICKSEARCHTOGGLER' ||
            target.closest('#QUICKSEARCHTOGGLER') ||
            target.closest('.MuiAutocomplete-popper')
        ) {
            return;
        }
        setShowSuggester(false);
    };

    const handleToggleSuggester = () => {
        setShowSuggester((prev) => !prev);
    };

    const showSuggesterSx = useMemo<ComponentProps<typeof HeaderItemFlexBox>['sx']>(() => {
        if (!point700) {
            if (showSuggester) {
                return {
                    maxWidth: 'auto',
                    width: '100%',
                    position: 'absolute',
                    top: '66px',
                    left: 0,
                    height: '54px',
                    backgroundColor: 'inherit',
                    padding: '0 8px',
                    boxSizing: 'border-box',
                };
            } else {
                return {
                    display: 'none',
                };
            }
        }
        return {};
    }, [showSuggester, point700]);

    return (
            <HeaderWrapper
                className={cnHeader('')}
                ref={(el) => {
                    (ref as any).current = el;
                    (refForMenu as any).current = el;
                }}
                sx={{
                    columnGap: point700 ? '20px' : '15px',
                    padding: point700 ? '0 16px' : '0 8px',
                }}
            >
                <HeaderItemFlexBox>
                    {!menuPoint && (
                        <ToggleButton
                            id={'DASHUPMENUSCROLLER'}
                            size={'small'}
                            value="menuToggler"
                            selected={!menuHide}
                            onClick={() => {
                                leftMenuSetReducerField({ field: 'hide', value: !menuHide });
                                leftMenuSetReducerField({ field: 'opened', value: menuHide });
                                if (menuHide) {
                                    leftMenuSetReducerField({ field: 'bars', value: ['main'] });
                                }
                            }}
                        >
                            <MenuIcon className={cnHeader('MenuIcon CustomStyle primaryColor3-text')} />
                        </ToggleButton>
                    )}
                    {
                        <Tooltip title={Translate.t({ i18nKey: 'home' })}>
                            <span>{GoHomeComponent}</span>
                        </Tooltip>
                    }   
                    {!point700 && (
                        <ToggleButton id={'QUICKSEARCHTOGGLER'} size={'small'} value={'searchToggler'} onClick={handleToggleSuggester}>
                            {showSuggester ? (
                                <SearchOff className={cnHeader('IconHome CustomStyle primaryColor3-text')} />
                            ) : (
                                <SearchIcon className={cnHeader('IconHome CustomStyle primaryColor3-text')} />
                            )}
                        </ToggleButton>
                    )}
                </HeaderItemFlexBox>

            {(point700 || showSuggester) && (
                <ClickAwayListener
                    onClickAway={closeSuggester}
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    disableReactTree
                >
                    <HeaderItemFlexBox
                        sx={{
                            flexGrow: 0,
                            flex: 1,
                            maxWidth: '614px',
                            ...showSuggesterSx,
                        }}
                    >
                        {!edit && (
                            <SuggesterQuick
                                SearchInputProps={{
                                    autoFocus: !point700,
                                }}
                                filters={filters}
                            />
                        )}
                    </HeaderItemFlexBox>
                </ClickAwayListener>
            )}

            {
                <HeaderItemBox>
                    <HeaderButtonsBox
                        sx={{
                            columnGap: point700 ? '20px' : '15px',
                        }}
                    >
                        {((lifeCycleStructureFromServer && isEditable) || isAdmin) &&
                            activePage !== 'statistics' &&
                            !isMobileOS && <RenderEditSwitcher isMobile={point700} />}

                        {!edit && (
                            <>
                                {chatEnable && (
                                    <Menu
                                        notificationCount={messagesCount}
                                        tooltipTitle={<Translate i18nKey="pryaniky.default.menu.messages" />}
                                        menuIcon={ChatBubbleOutline}
                                        children={
                                            <div>
                                                <ChatList />
                                                <Box className="ChatList_Buttons">
                                                    <Link className="Button-LikeRead" href="/messages">
                                                        <Translate i18nKey="pryaniky.header.open.chat" />
                                                    </Link>
                                                </Box>
                                            </div>
                                        }
                                    />
                                )}
                                <Menu
                                    notificationCount={notificationCount}
                                    tooltipTitle={<Translate i18nKey="notifications" />}
                                    menuIcon={NotificationsNone}
                                    getNotificationCount={getNotificationCount}
                                    resetNotificationCount={resetNotificationCount}
                                    children={<Notifications />}
                                />

                                <UserMenu user={authUser} nameNetwork={nameNetwork} />
                            </>
                        )}
                    </HeaderButtonsBox>

                    {children}
                </HeaderItemBox>
            }
        </HeaderWrapper>
    );
};

export const Header = connect(mapStateToProps, mapDispatchToProps)(withEnvironmentContext(withRouter(HeaderPresenter)));
