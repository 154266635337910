import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { ListItem } from 'muicomponents/src/ListItem/ListItem';
import { ComponentProps } from 'react';
import { NLeftMenu } from '../LeftMenu.index';
// import { IStateType } from 'redux/store';
// import { Action, Dispatch, bindActionCreators } from 'redux';
// import { getMenuStrusture, getMenuService } from './redux/selectors';
// import { menuAddItem, menuGetData, menuOpen, menuHidden, menuSetExpandedAll } from './redux/actions';

export const cnLeftMenuItem = cn('LeftMenuItemMui');

export namespace NLeftMenuItem {

  export interface Props extends IClassNameProps, ComponentProps<typeof ListItem>, Pick<NLeftMenu.Props, 'stayOpened'> {
    id: string;
    path: string[];
    inFavouritesBar?: boolean;
  }
}

export enum LeftMenuItemType {
  null,
  tasks
}