/**
 * @packageDocumentation
 * @module Widget_Settings_timeline
 */
export default {
    selectedTags: [],
    selectedGroups: [],
    selectedNews: '',
    once: true,
    viewType: 'head',
    timelineType: 'headlist',
    name: '',
    count: 5,
    hideIfNoData: false,
};
