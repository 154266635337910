import { connect } from 'react-redux';
import * as React from 'react';
import { mapDispatchToProps, IDispatchProps } from '../../redux/connector';
import i18n from 'localizations/i18n';

import { mapStateToProps, cnTabs, ITabsProps, ITabsState, mapActionsToProps, ITabsOwnProps, ITabsStateProps } from "./Tabs.index";
import { Button, Icon } from 'uielements/src';
import { prepareWidget } from 'i.widgets';
import { withRouter } from 'react-router';
import { IStateType as IState } from 'redux/store';
import './Tabs.scss';
import { Translate } from 'localizations/Translate';

export class TabsPresenter extends React.Component<ITabsProps, ITabsState> {
  public el: HTMLElement | null;
  // private userOrGroupPath: string | null = null;

  public constructor(props: ITabsProps) {
    super(props);
  }

  private get userOrGroupPath(): string | null {
    const match = this.props.location.pathname.toLowerCase().match(/((user|group|tag)\/[\w-\.]+)\/?/);
    return match ? match[1] : null;
  }

  private get tabs() {
    const { tabs, context } = this.props;
    const isGroupPage = this.props.location.pathname.toLowerCase().includes('group');
    return tabs.filter(tab => {
      if(!isGroupPage) return true;
      if(!context.isContentDisabled) return true;
      return tab.name !== 'feed';
    })
  }

  public render() {
    const { tag: TagName = 'div', context, tabs, children, className = '', disableNavigate, ...props } = this.props;
    let selectedTab = context.tab
    if(this.userOrGroupPath && !selectedTab) {
      selectedTab = this.props.location.pathname.split('/')[3]
    }
    const navTabs = this.getNavTabs();
    const activeTab = this.tabs.find(tab => selectedTab === tab.id || selectedTab === tab.name) || this.tabs[0];
    return (
      <TagName {...props} className={cnTabs({}, [className])}>
        <div className={cnTabs('Nav')}>
          {navTabs.map((tab, i) => (
            disableNavigate ? 
              <div 
                className={cnTabs('Button', { active: activeTab.id === tab.id })}
              >
                {
                  tab.iconFile && <img className={cnTabs('Button-Icon')} src={tab.iconFile.originalUrl} alt='tabImg' /> ||
                  tab.icon && <Icon icon={tab.icon} />
                }
                <Translate i18nKey={tab.title} defaults={tab.titleFallback} />
              </div>
            :
              <Button
                key={tab.id}
                className={cnTabs('Button', { active: activeTab.id === tab.id })}
                href={tab.to}
                type={!tab.to ? undefined : "rlink"}
                name={tab.name || tab.id}
                onClick={this.changeTab}
                noBorderRadius
                noBorder>
                  {
                    tab.iconFile && <img className={cnTabs('Button-Icon')} src={tab.iconFile.originalUrl} alt='tabImg' /> ||
                    tab.icon && <Icon icon={tab.icon} />
                  }
                  <Translate i18nKey={tab.title} defaults={tab.titleFallback} />
              </Button>
          ))}
        </div>
        <div className={cnTabs('Tab')}>{activeTab && this.prepareWidgets(activeTab.items as string[])}</div>
        {children}
      </TagName>
    );
  }

  private changeTab = (ev: any) => {
    this.props.updateContext(this.props.widget.id, {
      tab: ev.target.getAttribute('name'),
    })
  }

  private getNavTabs = () => {
    const isUserPage = this.props.location.pathname.toLowerCase().includes('user');
    const isGroupPage = this.props.location.pathname.toLowerCase().includes('group');
    const { edit } = this.props;
    
    return this.tabs.filter((tab) => 
      edit ? true : !tab.isHidden
    ).map(tab => {
      // let title = tab.title ? tab.id : tab.name || tab.id;
      let title = tab.name || tab.id;
      if (tab.name === 'about') {
        if (isUserPage) title = 'pryaniky.tabs.aboutMe';
        if (isGroupPage) title = 'pryaniky.tabs.aboutGroup';
      }
        // let title = (
        //   tab.name === 'about' ? this.props.location.pathname.toLowerCase().indexOf('/user') === 0 ? 'pryaniky.custom.tabs.aboutMe' : 'pryaniky.custom.tabs.aboutGroup' : tab.title
        // ) ||
        //   tab.name
        //   || tab.id
        //   || 'new';
        title = title === tab.name ? `pryaniky.tabs.${tab.name}` : title === tab.id ? `pryaniky.custom.tabs.${tab.id}` : title
        // заглучшка на время локализации в дев фиче
        // title = tab.title !== tab.name ? (tab.title || title) : title
        return {
          id: tab.id,
          title: title,
          titleFallback: tab.title || undefined,
          tab: tab.name,
          name: tab.name,
          type: tab.to || this.userOrGroupPath ? 'rlink' : undefined,
          to: tab.to || (this.userOrGroupPath ? `/${this.userOrGroupPath}/${tab.name || tab.id}` : undefined),
          icon: tab.icon,
          iconFile: tab.iconFile,
        };
      });
  }

  private prepareWidgets = (items: string[]) => items.map(item => prepareWidget(this.props.widgets[item]));
}

/**@deprecated */
export const Tabs = withRouter(
  connect<ITabsStateProps, IDispatchProps, ITabsOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps({ ...mapActionsToProps })
  )(TabsPresenter)
);
