import { cn } from '@bem-react/classname';
import { WithTranslation } from 'react-i18next';

export interface IPresentsProps extends IPresentsOwnProps, WithTranslation {}
interface IPresentsOwnProps {
    tag?: React.ComponentType | 'div';
    context: any;
    id: string;
    edit: boolean;
    className: string;
    settings?: any;
}

export const cnPresentsNew = cn('PresentsNew');
