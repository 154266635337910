import { Badge, badgeClasses } from 'muicomponents/src/Badge';
import { styled } from 'muicomponents/src/mui/system';

export const StyledLeftMenuItemBadge = styled(Badge)({
    [`.${badgeClasses.badge}`]: {
        pointerEvents: 'none',
        position: 'relative',
    },
    [`.${badgeClasses.invisible}`]: {
        transform: 'scale(0)'
    }
}) as typeof Badge;