import { Badge } from "muicomponents/src/Badge";
import React, { FC, memo, useMemo } from "react";
import urls from "routes/urls";
import { useMyTasksCountManager } from "utils/src";
import { useCurrentUser } from "utils/src/CommonRedux/users/hooks";
import { LeftMenuItemType } from "../LeftMenuItem.index";
import { ItemBadgeProps } from "./ItemBadge.index";
import { StyledLeftMenuItemBadge } from "./ItemBadge.styled";

const badgeCenterOrigin = {
    vertical: 'center',
    horizontal: 'center',
} as any;

const TasksCountBadge: FC<ItemBadgeProps> = ({
    item,
    ...props
}) => {

    const authUser = useCurrentUser();

    const {
        count
    } = useMyTasksCountManager(authUser?.baseData?.id || '');

    return (
        <StyledLeftMenuItemBadge
            {...props}
            badgeContent={count}
            color='primary' 
            anchorOrigin={badgeCenterOrigin}
        />
    )
};

const LeftMenuItemBadgePr: FC<ItemBadgeProps> = ({
    item,
    ...props
}) => {

    const view = useMemo(() => {
        let type = LeftMenuItemType.null;
        switch (item.url) {
            case urls.tasks:
                type = LeftMenuItemType.tasks;
                break;
        }
        return type
    }, [item.url]);

    switch (view) {
        case LeftMenuItemType.tasks:
            return <TasksCountBadge item={item} {...props} sx={{ marginRight: 'auto' }} />
        default:
            return null
    }
};

export const LeftMenuItemBadge = memo(LeftMenuItemBadgePr);