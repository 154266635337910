import { styled } from 'muicomponents/src/mui/system';
import { Box } from 'muicomponents/src/Box';
import { PagesMenuMuiBar } from './PagesMenuMuiBar/PagesMenuMuiBar';

const top = 72;

export const TransitionGroupBox = styled(Box)({
  display: 'flex',
  position: 'fixed',
  top,
  right: 0,
  height: `calc(100% - ${top}px)`,
  maxWidth: '100%',
  // backgroundColor: '#194776',
  zIndex: 90,
  transition: `max-width 0.25s ease-in-out`,
  backgroundColor: '#FFFFFF',
});

export const StyledBar = styled(PagesMenuMuiBar)(({
  id,
  zIndex
}) => ({
  backgroundColor: '#FFFFFF!important',
  zIndex: zIndex || 10
}));

export const MenuItem = styled(Box)({
    display: "flex",
    alignItems: "center",
    position: "relative",
});
export const MenuItemBuble = styled(Box)({
    position: "absolute",
    fontWeight: "500",
    fontSize: "11px",
    lineHeight: "20px",
    letterSpacing: "0.14px",
    backgroundColor: "#000",
    color: "#fff",
    padding: "0px 6px",
    borderRadius: "12px",
    top: "-10px",
    left: "16px",
});
