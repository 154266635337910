import React, { FC, useEffect, useMemo, useState, useCallback } from 'react'
import { Button } from 'muicomponents/src/Button/Button'
import { Translate } from 'localization'
import { IRequest } from 'utils/src/requests/service';
import { useMyTasksCountManager } from 'utils/src';
import { Box, Typography, CircularProgress } from 'muicomponents/src'
import CardMedia from '@mui/material/CardMedia';
import MyTasks from 'assets/svg/MyTasks.svg';
import { ContentBox, ContentBoxBaseline } from './MyTasks.styled'
import urls from 'routes/urls';
import {IMyTasksProps, cnMyTasks} from './MyTasks.index'



let request: IRequest;

export const MyTasksPreseter: FC<IMyTasksProps> = ({ context }) => {

    const currentUserId = context.uId || context.currentUserId

    const { count: tasksCount, loading } = useMyTasksCountManager(currentUserId);

    return (
        <Box>
            <ContentBox>
                <CardMedia
                    sx={{
                        width: "48px",
                        height: "48px"
                    }}
                    component="svg"
                    className={cnMyTasks("MyTasksHeader-Avatar")}
                    image={MyTasks}
                />
                <Typography
                    variant="subtitle1"
                    className={cnMyTasks('MyTasksHeader-Title')}
                    children={<Translate i18nKey={'pryaniky.myTasks.title'} />}
                />
            </ContentBox>
            <ContentBox sx={{ justifyContent: "space-between" }}>
                <ContentBoxBaseline >
                    <Typography
                        variant="subtitle1"
                        className={cnMyTasks('MyTasksContent-CountText')}
                        children={<Translate i18nKey={'pryaniky.myTasks.countText'} />}
                    />
                    {
                        loading
                        ? <CircularProgress size={32} />
                        : <Typography
                            variant="h4"
                            className={cnMyTasks('MyTasksContent-Count')}
                            children={tasksCount?.toString()}
                        />
                    }
                </ContentBoxBaseline>
                <Button
                    href={urls.tasks}
                    variant="contained">
                    <Translate i18nKey={'pryaniky.myTasks.moreInfo'} />
                </Button>
            </ContentBox>
        </Box>
    )
}
