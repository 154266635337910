import React, { FC, useEffect, memo, useCallback } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withNewsContext } from '../../contexts/news'
import { HeaderLayout } from 'blocks/NewsTypes/common/HeaderLayout/HeaderLayout';
import { Button, Avatar, Icon } from 'uielements/src';
import MDRender from 'uielements/src/CommonmarkRender'
import { Translate } from 'localizations/Translate';
import { UserMention } from 'uielements/src/UserMention/UserMention';
import { createNewsTitle } from 'blocks/NewsTypes/NewsUtils';
import * as utils from 'utils/src/utils';
import ContextMenu from '../ContextMenu'
import { createNewsDate, isNotPublished } from '../../utils/utils'
import { HelpTooltip } from 'uielements/src/index';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import createThanksUsersDialog from 'blocks/Dialogs/News/ThanksUsers/ThanksUsers'
import { thanksEditValidate } from 'blocks/NewsTypes/Thanks/Thanks.validate';
import { ErrorOutline } from 'muicomponents/src/Icons';
import { formatDateSTD } from 'utils/src/utils.dates';


type IPresenterProps = News.NewsHeader.IPresenterProps
type IOwnProps = News.NewsHeader.IOwnProps
type IDispatchProps = News.NewsHeader.IDispatchProps
type IStateProps = News.NewsHeader.IStateProps
type IState = News.NewsHeader.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    actions,
    user,
    isNeedApprove,
    isShowOnTop,
    group,
    changeDate,
    date,
    id,
    newstype,
    header,
    thanks,
    publishAt,
    badge,
    isRepost,
    componentRenderName,
    toggleEdit,
    deleteNews,
    hideNews,
    pathNews,
    TextRender,
    actionsRender,
    isDraft = false,
    showContextAlways,
    achievement
}) => {
    const notPublished = isNotPublished(isDraft, publishAt)

    const dateStr = createNewsDate({
        date,
        isDraft,
        changeDate,
        publishAt
    })

    return <HeaderLayout
        className={cnClassName({}, [className])}
        type={'post'}
        left={
            user.isDeleted ?
                <Avatar
                    responsive
                    name={user.displayName}
                    size={56} /> :
                <Button theme='unstyled' className={cnClassName('UserImg')} type='rlink' href={'/user/' + user.id} >
                    <Avatar
                        responsive
                        imgUrl={user.imgUrl}
                        imgId={user.imgId}
                        name={user.displayName}
                        size={56} />
                </Button>
        }
        right={
            isRepost ?
                <></> :
                <>
                    {actions.includes('addToFavorites.off') &&

                        // <HelpTooltip fixed={true} help={<Translate i18nKey='pryaniky.post.is.addToFavorites' />}>
                        <Button className={'HeaderLayout-ActionButton'} disabled={true}>
                            <Tooltip
                                className={'InfoIcon'}
                                title={<Translate i18nKey='pryaniky.post.is.addToFavorites' />}>
                                <span><Icon icon='star' className={cnClassName('isFavorite')} /></span>
                            </Tooltip>
                        </Button>
                        // </HelpTooltip>
                    }

                    {(achievement && achievement.achievementOnCorrect && isNeedApprove) &&
                        <Button className={'HeaderLayout-ActionButton'} disabled={true}>
                            <Tooltip
                                className={cnClassName('achievementOnCorrect')}
                                title={
                                <span>
                                    <Translate i18nKey='pryaniky.post.is.achievement.achievementOnCorrect' />
                                    {achievement.comment}
                                </span>
                                }>
                                <span>   <Icon icon='edit' className={cnClassName('edit')} /></span>
                            </Tooltip>
                        </Button>}

                    {(achievement && achievement.isDeleted && isNeedApprove) &&

                        <Tooltip
                            className={'InfoIcon'}
                            title={<Translate i18nKey='pryaniky.post.is.achievement.isDeleted' />}>
                            <ErrorOutline color="disabled" />
                        </Tooltip>}

                    {isNeedApprove && !achievement?.isDeleted &&
                        // <HelpTooltip fixed={true} help={<Translate i18nKey='pryaniky.post.is.need.to.approve' />}>
                        <Button className={'HeaderLayout-ActionButton'} disabled={true}>
                            <Tooltip
                                className={'InfoIcon'}
                                title={<Translate i18nKey='pryaniky.post.is.need.to.approve' />}>
                                <span> <Icon icon='eye-crossed' className={cnClassName('isNeedApprove')} /></span>

                            </Tooltip>
                        </Button>
                        // </HelpTooltip>
                    }
                    {isShowOnTop &&
                        // <HelpTooltip fixed={true} help={<Translate i18nKey='pryaniky.post.isShowOnTop' />}>

                        <Button className={'HeaderLayout-ActionButton'} disabled={true}>
                            <Tooltip
                                className={'InfoIcon'}
                                title={<Translate i18nKey='pryaniky.post.isShowOnTop' />}>
                                <span><Icon icon='bullhorn' className={cnClassName('isShowOnTop')} /></span>
                            </Tooltip>
                        </Button>
                        // </HelpTooltip>
                    }
                    {notPublished &&
                        <Button
                            className={'HeaderLayout-ActionButton'}
                            disabled={true}>
                            <Tooltip
                                className={'InfoIcon'}
                                title={formatDateSTD(publishAt, true)}>
                                <span><Icon icon='clock' className={cnClassName('isPublishAt')} /></span>
                            </Tooltip>
                        </Button>
                        // </HelpTooltip>
                    }

                    <ContextMenu showAlways={showContextAlways} />

                    {actionsRender}
                    {/* <ServiceActions news={data as any} announcement={isShowOnTop} onClick={postAction} actions={actions} id={id} /> */}
                </>
        }
        bottom={dateStr}
    >
        {user.isDeleted ? <span>{user.displayName}</span> : <UserMention id={user.id} name={user.displayName} className={('UserName')} />}

        {' '}

        {TextRender || <MDRender
            className={'Markdown'}
            type={'inline'}
            source={createNewsTitle({ id, newstype, user, header, componentRenderName })}
            onClick={(e) => {
                if ((e.target as HTMLElement).getAttribute('data_action') === 'likersMoreButton') {
                    createThanksUsersDialog({ participants: newstype === 4 ? thanks.participants : badge.participants, newstype:newstype }).then(() => { }).catch(() => { })
                }
            }} />}

        {group && group.pkid !== -1 ? (
            <span>
                <Icon icon="caret-right" />
                <Button type="rlink" className={'GrupLink'} href={'/group/' + group?.pkid} theme="unstyled">
                    {' ' + group.name}
                </Button>
            </span>
        ) : (
            undefined
        )}
    </HeaderLayout>
}

export default withNewsContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter)));

