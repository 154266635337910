import React, { ComponentProps, FC } from 'react'
import { connect } from 'react-redux';
import { IStateType as IState } from 'redux/store';
import { Typography } from 'muicomponents/src';
import { Translate } from 'localization';
import { useInitNewsCreator, baseModels } from 'News/creator/Creator/Creator.hooks';
import { SendNewsDialog } from 'News/creator/Creator/SendDialog/SendDialog';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getNewsCreateModule } from 'News/creator/redux/module.newsCreator';
import { EditButton, IconWrapper } from "../styled";
import { SvgIcon } from "@material-ui/core";
import { ThanksValidateRules } from 'News/creator/types/Thanks/Thanks.validate';
import { FNewsUser } from 'utils/src/BaseTypes/user.types'
import { AskingHand } from 'muicomponents/src/Icons';
export interface IUserThankFormProps {
    user: any
    targetUsers: any
    currencyName: string
}

/**
 * user.baseData or full
 * @param value 
 * @returns 
 */
export const convertFullUserToShort = (value: any): FNewsUser => {
    let v = value
    if (value.baseData) v = value.baseData
    return {
        birthday: v.birthday,
        displayName: v.displayName,
        division: v.division,
        eMail: v.eMail,
        id: v.id,
        isDeleted: v.isDeleted,
        isFirstLogon: v.isFirstLogon,
        phone: v.phone,
        position: v.position,
        sex: v.sex,
        alias: v.alias,
        imgId: v.imgId,
        imgUrl: v.imgUrl,
    }
}

/**
 * @deprecated
 */
export async function sendThanksDialog(creatorId: string, users: FNewsUser[] = []) {
    try {
        ThanksValidateRules.groups!.notNull!.value = false;
        await SendNewsDialog({
            creatorId,
            newsType: 'thanks',
            dialogTitle: <Translate i18nKey={`pryaniky.post.actions.thank.dialog.title`} values={{ name: '' }} />,
            creatorOptions: {
                models: {
                    thanks: {
                        ...baseModels.thanks,
                        users,
                    }
                }
            }
        })
    } catch (error) {
        console.error('error', error)
    }
}

/**
 * @deprecated
 */
const ThankFormPresenter: FC<IUserThankFormProps> = (
    { user, targetUsers, currencyName }) => {

    const target = { ...convertFullUserToShort(targetUsers), type: 'user' }
    const {
        creatorId
    } = useInitNewsCreator();


    async function openDialog(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.stopPropagation();
        await sendThanksDialog(creatorId, [target])
    }

    return (<EditButton variant='outlined' fullWidth
        onClick={openDialog} >
        <IconWrapper color='primary'>
            <SvgIcon>
                <AskingHand />
            </SvgIcon>
        </IconWrapper>
        <Typography variant='subtitle1'>
            <Translate i18nKey={'pryaniky.post.actions.thank'} />
        </Typography>
    </EditButton>
    )
}

/**
 * @deprecated
 */
export const NewsCreatorModule: FC<ComponentProps<typeof ThankFormPresenter>> = ({
    ...props
}) => {
    return (
        <DynamicModuleLoader modules={[getNewsCreateModule()]}>
            <ThankFormPresenter {...props} />
        </DynamicModuleLoader>
    );
};


/**
 * @deprecated
 */
const mapStateToProps = (state: IState) => ({
    currencyName: state.store.appSettings && state.store.appSettings.currencyNameAccusativeSingular,
});

/**
 * @deprecated
 */
export const ThankForm = connect(mapStateToProps)(NewsCreatorModule);