import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Box } from 'muicomponents/src';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import React, { FC, memo } from 'react';

import { CustomSettings } from 'utils';
import {
    NewsTimelineHeader,
    NewsTimelineHeaderButton,
    NewsTimelineHeaderIndicator,
    NewsTimelineHeaderTitle,
} from './component.styled';
import { cnClassName, News } from './interfaces';
import './style.scss';

const useMoexDesign = CustomSettings.useMoexDesign();

type IPresenterProps = News.TimelineHeader.IPresenterProps;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
            minWidth: '32px',
            textAlign: 'center',
            borderRadius: '0',
            cursor: 'pointer',
            padding: '0',
        },
    })
);

export const Presenter: FC<IPresenterProps> = ({
    className,
    value,
    children,
    isLoading,
    next,
    previous,
    disableNext,
    disablePrev,
    disableNavigation = false,
    totalCount,
    splitter = '/',
}) => {
    const classes = useStyles();
    return (
        <NewsTimelineHeader className={cnClassName({}, [className])}>
            {useMoexDesign ? (
                <Tooltip overflowOnly title={(children && children?.toString()) || ''}>
                    <NewsTimelineHeaderTitle
                        variant="subtitle1"
                        sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        className={cnClassName('Title')}
                    >
                        {children}
                    </NewsTimelineHeaderTitle>
                </Tooltip>
            ) : (
                <NewsTimelineHeaderTitle className={cnClassName('Title')}>{children}</NewsTimelineHeaderTitle>
            )}
            {!disableNavigation && (
                <Box className={cnClassName('Navigation')}>
                    <NewsTimelineHeaderButton
                        isLoading={isLoading}
                        disabled={disablePrev}
                        className={cnClassName('Button', [classes.root])}
                        onClick={previous}
                    >
                        <KeyboardArrowLeftIcon color="primary" />
                    </NewsTimelineHeaderButton>
                    <NewsTimelineHeaderIndicator className={cnClassName('Indicator')}>
                        {value}
                        <span>
                            {splitter}
                            {totalCount}
                        </span>
                    </NewsTimelineHeaderIndicator>
                    <NewsTimelineHeaderButton
                        isLoading={isLoading}
                        disabled={disableNext}
                        className={cnClassName('Button', [classes.root])}
                        onClick={next}
                    >
                        <KeyboardArrowRightIcon color="primary" />
                    </NewsTimelineHeaderButton>
                </Box>
            )}
        </NewsTimelineHeader>
    );
};

export default memo(Presenter);
