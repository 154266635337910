/**
 * @packageDocumentation
 * @module Widget_Settings_hierarchy
 */
export default {
    hierarchy: '',
    headusers: false,
    subusers: false,
    hideIfNoData: false,
    hierarcheListView: false,
    itemCountView: 2,
};
