import { Box } from "muicomponents/src/Box";
import React, { ComponentProps, FC, forwardRef, ReactNode, useMemo } from "react";
import { styled } from 'muicomponents/src/mui/system';
import { Typography } from "muicomponents/src/Typography";
import { ColoredSquare } from "../ColoredSquare";
import moment from "moment";
import { DayCellColors } from "../UsersEvents.constants";
import { BorderedBox } from "./UsersEventsTable.styled";
import { getDatesFromTo } from "../UsersEvents.utils";
import { calendarsArray, weekendIndexes } from "../UsersEvents.constants";
import { useUsersEventsContext } from "../UsersEvents.context";
import { getCalendarEventsRequestV4 } from "utils/src/requests/requests.calendar";
import { getEventFromArrayWhereDateIsBetweenStartAndEndDates } from "./UsersEventsTable.utils";
import { CellComponent } from "./Cells/Cell";

const MonthCellBox = styled(BorderedBox)({
    display: 'inline-flex',
    boxSizing: 'border-box'
}) as typeof BorderedBox;

const MonthCellDatesBox = styled(Box)({
    display: 'inline-flex',
    padding: '8px 16px',
    flexDirection: 'column',
    gap: 4,
    gridGap: 4,
}) as typeof Box;

const MonthCellSumBox = styled(MonthCellDatesBox)({
    borderLeft: '1px solid #0000001F',
    width: 50,
    boxSizing: 'border-box',
    alignItems: 'center'
}) as typeof MonthCellDatesBox;

const MonthCellSumItemBox = styled(Box)({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}) as typeof Box;

const MonthCellSumTypography = styled(Typography)({

}) as typeof Typography;

const DaysBox = styled(Box)({
    display: 'flex',
    gap: 4,
    gridGap: 4,
    flex: 1,
    alignItems: 'center'
}) as typeof Box;

const DayBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 4,
    gridGap: 4
}) as typeof Box;

type MonthCellProps = ComponentProps<typeof MonthCellBox> & {
    dateFrom: Date;
    dateTo?: Date;
    userId: string;
    events: {[key: string]: Awaited<ReturnType<typeof getCalendarEventsRequestV4>>['data']['events']};
    isHeadUser: boolean;
    // dates: moment.Moment[];
};

const MonthCellDay: FC<Pick<MonthCellProps, 'events' | 'userId'> & {
    cIds: string[],
    day: moment.Moment
}> = ({
    cIds,
    userId,
    day,
    events
}) => {

    const blockContext = useUsersEventsContext();

    const isWeekend = useMemo(() => weekendIndexes.includes(day.day()), [day]);

    const color = useMemo(() => {
        const event = cIds.map((cId) => {
            return getEventFromArrayWhereDateIsBetweenStartAndEndDates(day, events[cId]);
        })?.reverse().find((event) => !!event);
        if(event && event.user.id === userId) {
            return event.eventColor;
        }
        if(blockContext.settings.generatedCalerdarSettings.calendarId) {
            const weekendEvent = getEventFromArrayWhereDateIsBetweenStartAndEndDates(day, events[blockContext.settings.generatedCalerdarSettings.calendarId]);
            return weekendEvent ? DayCellColors.weekend : undefined;
        }
        return isWeekend ? DayCellColors.weekend : undefined;
    }, [cIds, events, day, userId, isWeekend, blockContext.settings.generatedCalerdarSettings.calendarId]);

    return (
        <ColoredSquare color={color} disableIteractive />
    );
};

const MonthCellComponent = styled(CellComponent)({
    display: 'inline-flex',
    boxSizing: 'border-box',
    padding: 0
}) as typeof CellComponent;

export const MonthCell = ({
    dateFrom,
    dateTo,
    events = {},
    userId,
    isHeadUser,
    // dates,
    ...props
}: MonthCellProps & ComponentProps<typeof CellComponent>) => {

    const blockContext = useUsersEventsContext();

    const dates = useMemo(() => {
        return getDatesFromTo(moment(dateFrom), dateTo ? moment(dateTo) : moment(dateFrom).endOf('month'));
    }, [dateFrom, dateTo]);

    const eventsMonthCountByBlock = useMemo(() => {
        const month = moment(dateFrom).format('YYYY-MM');
        return blockContext.settings.headBlocks.map(el => {
            const calendarsEventsSum2 = el.calendars.reduce((s, cId) => {
                const calendarsEventsSum = (events[cId] ?? []).reduce((s, event) => {
                    let start = moment(event.start);
                    let end = moment(event.end);
                    if(
                        event.user.id !== userId
                        || (
                            start.format('YYYY-MM') !== month
                            && end.format('YYYY-MM') !== month
                        )
                    ) {
                        return s;
                    }
                    if(start.format('YYYY-MM') !== month) {
                        start = moment(event.end).startOf('month');
                    }
                    if(end.format('YYYY-MM') !== month) {
                        end = moment(event.start).endOf('month');
                    }
                    return s + (end.diff(start, 'days') + 1);
                }, 0);
                return s + calendarsEventsSum;
            }, 0);
            return calendarsEventsSum2;
            // const calendarEventsSum = (events[cId] ?? []).reduce((s, event) => {
            //     let start = moment(event.start);
            //     let end = moment(event.end);
            //     if(
            //         event.user.id !== userId
            //         || (
            //             start.format('YYYY-MM') !== month
            //             && end.format('YYYY-MM') !== month
            //         )
            //     ) {
            //         return s;
            //     }
            //     if(start.format('YYYY-MM') !== month) {
            //         start = moment(event.end).startOf('month');
            //     }
            //     if(end.format('YYYY-MM') !== month) {
            //         end = moment(event.start).endOf('month');
            //     }
            //     return s + (end.diff(start, 'days') + 1);
            // }, 0);
        });
        // blockContext.settings.headBlocks
        // return blockContext.cIds.reduce((a, cId) => {
        //     const calendarEventsSum = (events[cId] ?? []).reduce((s, event) => {
        //         let start = moment(event.start);
        //         let end = moment(event.end);
        //         if(
        //             event.user.id !== userId
        //             || (
        //                 start.format('YYYY-MM') !== month
        //                 && end.format('YYYY-MM') !== month
        //             )
        //         ) {
        //             return s;
        //         }
        //         if(start.format('YYYY-MM') !== month) {
        //             start = moment(event.end).startOf('month');
        //         }
        //         if(end.format('YYYY-MM') !== month) {
        //             end = moment(event.start).endOf('month');
        //         }
        //         return s + (end.diff(start, 'days') + 1);
        //     }, 0);
        //     return {
        //         ...a,
        //         [cId]: calendarEventsSum
        //     }
        // }, {} as {[key: (typeof blockContext.cIds)[number]]: number});
    }, [blockContext.settings.headBlocks, events, dateFrom, dateTo]);

    return (
        <MonthCellComponent
            {...props}
            isHeadUser={isHeadUser}
        >
            <MonthCellDatesBox>
                {
                    blockContext.settings.headBlocks.map(block => {
                        const cId = block.calendars[block.calendars.length - 1];
                        return (
                            <DaysBox key={cId}>
                                {dates.map((day, idx) => {
                                    return <DayBox key={idx}>
                                        <MonthCellDay userId={userId} cIds={block.calendars} day={day} events={events} />
                                    </DayBox>
                                })}
                            </DaysBox>
                        );
                    })
                }
                {/* {
                    blockContext.cIds.map(cId => {
                        return (
                            <DaysBox key={cId}>
                                {dates.map((day, idx) => {
                                    return <DayBox key={idx}>
                                        <MonthCellDay userId={userId} cId={cId} day={day} events={events} />
                                    </DayBox>
                                })}
                            </DaysBox>
                        );
                    })
                } */}
            </MonthCellDatesBox>
            <MonthCellSumBox>
                {
                    blockContext.settings.headBlocks.map((block, idx) => {
                        const cId = block.calendars[block.calendars.length - 1];
                        return (
                            <MonthCellSumItemBox key={`sum-${cId}`}>
                                <MonthCellSumTypography variant="body2">
                                    {eventsMonthCountByBlock[idx]}
                                </MonthCellSumTypography>
                            </MonthCellSumItemBox>
                        );
                    })
                }
                {/* {
                    blockContext.cIds.map(cId => {
                        return (
                            <MonthCellSumItemBox key={`sum-${cId}`}>
                                <MonthCellSumTypography variant="body2">
                                    {eventsMonthCountByCalendar[cId]}
                                </MonthCellSumTypography>
                            </MonthCellSumItemBox>
                        );
                    })
                } */}
            </MonthCellSumBox>
        </MonthCellComponent>
    );
};