/**
 * @packageDocumentation
 * @module Widget_Settings_presents
 */
import { i18n } from 'localization';
import { JFdictionary } from '../dictionary';

const { types } = JFdictionary;

const subtypes = ['receivedGifts', 'sendedGifts'];

export const prepareOption = (value: string) => ({
    title: i18n.t(`pryaniky.widgets.presents.settings.${value}`),
    value,
});

export default {
    type: types.object,
    properties: {
        name: {
            type: types.string,
            placeholder: i18n.t('pryaniky.widgets.presents.settings.name'),
        },
        type: {
            type: types.string,
            placeholder: i18n.t('pryaniky.widgets.presents.settings.type'),
            opts: subtypes.map((el) => prepareOption(el)),
        },
        hideIfNoData: {
            type: types.boolean,
        },
    },
};
