import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { WidgetCalendarMiniSettings } from 'utils/src';


export namespace SettingsCalendarMiniWidget {

    export interface Props extends IClassNameProps {
        widgetContext?: any;
        settings?: WidgetCalendarMiniSettings | null;
        isEdit?: boolean;
    }
}

export const cnCalendarMini = cn('CalendarMini');
