import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utilsDep from 'utils.project/utils.project';
import * as utils from 'utils/src/utils';
// import * as utilsProj from 'utils.project/utils.project';
// import * as widgetUtils from 'widgets/utils';
// import { v1 as uuid } from 'uuid';
// import { mapDispatchToProps, IDispatchProps } from '../../redux/connector';
import i18n from 'localizations/i18n';

import { cnPage, IPageProps, IPageState, mapStateToProps, IPageStateProps, IOtherData, IActionsPage, mapActionsToPropsPage, IPageOwnProps } from './Page.index';
import './Page.scss';
import { Button } from 'uielements/src/Button/Button';
import { Icon } from 'uielements/src/Icon/Icon';
import { cn } from '@bem-react/classname';
// import { Widget } from '../Widget/Widget';

import { widgets } from "i.widgets";
import { toast } from 'react-toastify';
import { IDispatchProps, mapDispatchToProps } from '../../redux/connector';
import { Stub, Loading } from 'uielements/src';
import { IStateType as IState } from 'redux/store';
import { WidgetTypeSkeleton } from 'blocks/Widget/_type/Widget_type_skeleton';
import { Prompt } from 'react-router';
import { Beforeunload } from 'react-beforeunload';
import { Translate } from 'localizations/Translate';
import { Widget } from 'Widgets_v2/Widget/Widget';
import { DialogHistoryComment } from 'blocks/Dialogs/HistoryComment/HistoryComment'
import { createPortal } from 'react-dom';
import { AppFooter } from 'blocks/AppFooter';

// import { PageBreadcrumbs } from '../Pages/PageBreadcrumbs/PageBreadcrumbs'

const cnMenu = cn('Page-Menu');


const StructureItem = (id: string, otherData?: IOtherData) => ({
  id,
  ...otherData
});

export class PagePresenter extends React.Component<IPageProps, IPageState> {
  public el: HTMLElement | null;
  protected mounted: boolean;

  constructor(props: IPageProps) {
    super(props);
  }

  private setFullWidth = () => {

  }


  public componentDidMount() {
    this.mounted = true;
    this.getPage(this.props, () => {
      if (this.props.edit && this.props.backup) this.props.backup('make');
      if (this.props.cbAfterGetData) this.props.cbAfterGetData();
    })
    // document.title = this.props.title + ( this.props.page.title ? ' - ' + this.props.page.title : '' );
    const { layout } = this.props;
    if (layout) {
      if (
        // если первая структура это сетка то растягиваем на всю ширину
        (layout.type === widgets.types.grid) ||
        // если первая структура это колонки, но при этом в настройках есть галка fullWidth то растягиваем на всю ширину
        (
          (
            layout.type === widgets.types.layout ||
            layout.type === widgets.types.layout + '/horizontal'
          ) && layout.settings?.fullWidth)
      ) {
        const layoutDOM = document.getElementById('Layout');
        if (layoutDOM) {
          Array.from(layoutDOM.children).find((el) => {
            if (el.classList.contains('Layout-Inner')) {
              el.classList.add('FullWidth')
            }
          });
        }
      }
    }
  }

  public componentDidUpdate(pp: IPageProps) {
    const { page, edit, saving, layout } = this.props;
    if (pp.edit !== edit && !edit && page.name === pp.page.name) {
      if (saving) {
        this.savePage();
      } else {
        if (this.props.backup) this.props.backup('restore');
      }
    }
    if (pp.edit !== edit && edit && page.name === pp.page.name) {
      if (this.props.backup) this.props.backup('make');
    }
    if (page.name !== pp.page.name) {
      this.getPage(this.props, () => {
        if (this.props.cbAfterGetData) this.props.cbAfterGetData();
      });
    }
    // if (edit) {
    //   window.onbeforeunload = (e: BeforeUnloadEvent) => {
    //     e.preventDefault();
    //     e.returnValue = 'Возможно, внесенфывфывфывные изменения не сохранятся. ,kf ,kf';
    //   };
    //   // () => true;
    // } else {
    //   window.onbeforeunload = null;
    // }
    if (layout !== pp.layout) {
      const { layout } = this.props;
      if (layout) {
        const layoutDOM = document.getElementById('Layout');
        if (layoutDOM) {
          if (
            // если первая структура это сетка то растягиваем на всю ширину
            (layout.type === widgets.types.grid) ||
            // если первая структура это колонки, но при этом в настройках есть галка fullWidth то растягиваем на всю ширину
            (
              (
                layout.type === widgets.types.layout ||
                layout.type === widgets.types.layout + '/horizontal'
              )
              && layout.settings?.fullWidth)
          ) {
            // история умалчивает почему это сделано так, но оно работает
            Array.from(layoutDOM.children).find((el) => {
              if (el.classList.contains('Layout-Inner')) {
                el.classList.add('FullWidth');
              }
            });
          } else {
            // убираем из 
            Array.from(layoutDOM.children).find((el) => {
              if (el.classList.contains('Layout-Inner') && el.classList.contains('FullWidth')) {
                el.classList.remove('FullWidth');
              }
            });
          }
        }
      }
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
    const { layout } = this.props;
    if (layout && layout.type !== widgets.types.grid) {
      const layoutDOM = document.getElementById('Layout');
      if (layoutDOM) {
        Array.from(layoutDOM.children).find((el) => {
          if (el.classList.contains('Layout-Inner') && el.classList.contains('FullWidth')) {
            el.classList.remove('FullWidth')
          }
        });
      }
    }
  }

  public getPage(props: IPageProps, cb?: any) {

    if (this.props.getStructure) this.props.getStructure(props.page.name, props.page.params, cb);
  }

  public render() {
    const { tag: TagName = 'div', children, className, page, layout, loading, edit } = this.props;
    const type = utils.capitalize(page.name).replace(/\./gi, '-');
    let Component = widgets.components.layout;
    if (layout && layout.type === widgets.types.grid) {
      Component = widgets.components.grid
    }
    if (layout?.version === '2') {
      Component = Widget as any;
    }
    return (
      <TagName ref={el => this.el = el} className={cnPage({ type }, [className])}>
        {edit && <Beforeunload onBeforeunload={() => Translate.t({ i18nKey: 'pryaniky.beforeunload.text' })} />}
        <Prompt
          when={edit}
          message={Translate.t({ i18nKey: 'pryaniky.beforeunload.text' })}
        />
        {this.renderMenu()}

        {/* <PageBreadcrumbs dt={this.props.structure} isDraftState={false} matchId={page.name} /> */}

        {
          //!loading && <- зачем? каждый раз размонтировать все компоненты и инициализировать их все заного, пускать все запросы по второму кругу? 
          // к тому же существенно замедлять взаимодействие с системой показыванием каких то спинеров, когда уже и так есть данные, всё уже загружено, 
          // что нужно для работы и отображения интерфейса. 
          // В результате повторной инициализации просиходят такие баги - COMMON-1311.

          layout ?
            <Component key={layout.id} id={layout.id} data-id={layout.id} pageData={page.data || {}} type={layout.type.split('/')[1]} />
            :
            <WidgetTypeSkeleton />
        }

        
        {
          typeof children === 'function' ?
            children() :
            children
        }
        {this.renderFooter()}
      </TagName>
    );
  }

  private savePage() {
    const { structure } = this.props;
    if (structure.type === "page"|| structure.type === "file") {
      DialogHistoryComment({
        data: { comment: "" }
      }).then((value) => this.props.setStructure({ ...structure, comment: value.comment })).catch(() => { })
    } else {
      this.props.setStructure(structure);
    }


  }

  private renderMenu() {
    const { edit, layout, loading } = this.props;
    return (
      !loading && edit && !layout &&
      <div className={cnMenu({ init: Boolean(layout) })}>
        {<Button onClick={this.layoutSelector} className={'Button_create'} main={Boolean(!layout)} children={!layout ? i18n.t('pryaniky.widgets.create') : <Icon icon='cogs' />} />}
        {/* {layout && <Button onClick={this.deleteLayout} children={<Icon icon='times' />} />} */}
      </div>
    )
  }

  private renderFooter() {
    const { structure } = this.props; 
    if(!structure || structure.isHideFooter) return null;
    return <AppFooter />;
  }

  // private functionBind() {}

  private layoutSelector = () => {
    const okBtnValidate = (data: { [s: string]: any }) => {
      return data.selected;
    };

    const okBtnAction = (data: { [s: string]: any }) => {
      // this.setState({layout: data.selected});
      // this.props.changeVm('structure.' + this.props.page.name, data.selected);
    }

    utilsDep.modal({
      header: i18n.t('pryaniky.modal.layoutGrid.title'),
      type: 'layoutGrid',
      data: {
        // selected: layout
        // [this.props.page.name]
      },
      noFooter: true,
      okBtnAction,
      okBtnValidate
    })
  }

  // private deleteLayout = () => {
  //   const onConfirm = () => {
  //     // this.setState({layout: undefined});
  //     // this.props.changeVm('page.' + this.props.page.name, undefined);
  //   }

  //   utilsProj.confirm({
  //     text: i18n.t('pryaniky.modal.pageDelete.title'),
  //     onConfirm
  //   })
  // }

}

interface IPasd {
  page: {
    name: string;
    data?: { [s: string]: any };
  },
  context: { [s: string]: any }
}

export const Page = connect<IPageStateProps, IDispatchProps, IPageOwnProps, IState>(
  mapStateToProps,
  mapDispatchToProps({ ...mapActionsToPropsPage })
)(PagePresenter)
// compose(withBemMod(cnPage(), {}))(PagePresenter));
