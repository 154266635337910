import React, { useEffect, useMemo, useState } from 'react';
import {
    IGetReceivedPresentsResponse,
    IGetSendedPresentsResponse,
    IPresentData,
} from 'utils/src/requests/models/api.shop';
import { PresentItemMui } from './PresentItem/PresentItemMui';
import { cnPresentsNew, IPresentsProps } from './Presents.index';
// import { PopupMenu } from 'uielements/src/PopupMenu/PopupMenu';
import CardMedia from '@mui/material/CardMedia';
import EmptyWidget from 'assets/svg/EmptyWidget.svg';
import { Translate } from 'localizations/Translate';
import { Box } from 'muicomponents/src';
import { Typography } from 'muicomponents/src/Typography/Typography';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { getUserReceivedPresents, getUserSendedPresents } from 'utils/src/requests/requests.shop';
import './Presents.scss';

function getDefaultWidgetType(type: string | undefined): string {
    return type === '' || type === undefined ? 'receivedGifts' : type;
}

export const Presents = withTranslation('translation')((props: IPresentsProps) => {
    const { tag: Tag = 'div', edit, className, settings } = props;

    const widgetType = getDefaultWidgetType(settings?.type);

    const params: {
        id: string;
        tab: string;
    } = useParams();

    // console.log("params", params)
    const authUser = useSelector(getAuthUser);

    const [presents, setPresents] = useState<IPresentData[]>();
    const [loading, setLoading] = useState<boolean>(false);

    const userId = params.id || authUser.baseData.id;

    useEffect(() => {
        async function fetchPresents() {
            setLoading(true);

            const fetchFunction = widgetType === 'sendedGifts' ? getUserSendedPresents : getUserReceivedPresents;
            const response: IGetSendedPresentsResponse | IGetReceivedPresentsResponse = await fetchFunction(
                userId,
                true
            );

            const presents = response?.data?.map((item) => ({
                ...item,
                userOrder: 'orderFromUser' in item ? item.orderFromUser : item.orderForUser,
                users: item.users.map((user) => ({
                    ...user,
                    userOrder: 'orderFromUser' in user ? user.orderFromUser : user.orderForUser,
                })),
            }));

            setPresents(presents);
            setLoading(false);
        }

        fetchPresents();
    }, [userId, widgetType]);

    const widgetName = useMemo(() => {
        return settings?.name?.trim() ? settings.name : <Translate i18nKey="pryaniky.widgets.presents.title" />;
    }, [settings?.name]);

    if (settings?.hideIfNoData && presents?.length === 0) {
        return null;
    }

    return (
        <Tag {...props} className={cnPresentsNew('', [className])}>
            <Typography fontWeight="700">{widgetName}</Typography>
            <Box
                className={cnPresentsNew(`Items `)}
                sx={{
                    // background: "#f5f5f5"
                    background: presents && presents?.length < 0 ? '#f5f5f5' : '#fff',
                }}
            >
                {!edit && !loading && presents && presents?.length > 0 ? (
                    presents?.map((present) => {
                        return <PresentItemMui item={present} size={64} widgetType={widgetType} />;
                    })
                ) : (
                    <>
                        <CardMedia
                            component="svg"
                            className={cnPresentsNew('EmptyList-Avatar')}
                            sx={{
                                width: '100%',
                                height: '270px',
                                marginBottom: '16px',
                            }}
                            image={EmptyWidget}
                        />
                        <Typography>
                            <Translate i18nKey={'pryaniky.widgets.settings.type.birthdays.noData.text'} />
                        </Typography>
                    </>
                )}
            </Box>
        </Tag>
    );
});
