import React, { ComponentType, ComponentProps, FC, useContext, useMemo, useRef, useCallback, useState } from 'react';
import { cnNewsTypeEvents } from './Events.index';
import { connect } from 'react-redux';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import './Events.scss';
import {
    Box,
    CalendarsSuggester as CalendarsSuggesterC,
    UsersSuggester as UsersSuggesterC
} from 'muicomponents/src';
import { DraftInput as DraftInputC, useDraftEdit } from 'muicomponents/src/DraftInput/DraftInput';
import { styled } from 'muicomponents/src/mui/system';
import { MenuItem } from 'muicomponents/src/Menu';
import { Select } from 'muicomponents/src/Select';
import { DatePicker as DatePickerC } from 'muicomponents/src/DatePicker';
import { Translate } from 'localizations/Translate';

import { useDebounce, useDidUpdateEffect } from 'utils/src/hooks';
import { PrepareErrorText } from 'News/utils/utils.validate';

import { TextField as TextFieldC } from 'muicomponents/src/TextField';
import { baseEvents, durationOptions } from './Events.constants';

import { AddLocationAlt, Close } from 'muicomponents/src/Icons';
import { NewsCreateContext } from 'News/creator/Creator/Creator.constants';
import { useEventsEdit } from './Events.hooks';
import { useDraftEditorStyle } from 'News/creator/hooks';
import moment from 'moment';
import { EventsValidateRules, startCurrentMinute } from './Events.validate';
import { Help } from 'muicomponents/src/Icons';
import { Checkbox } from 'muicomponents/src/Checkbox';
import { FormControl, FormControlLabel, FormHelperText } from 'muicomponents/src/FormControl';
import { Tooltip } from 'muicomponents/src/Tooltip';
import { IconButton } from 'muicomponents/src/IconButton';
import { NumberTextField as NumberTextFieldC } from 'muicomponents/src/TextField/TextField';
import { memoizedComponent } from 'News/creator/utils.sender';

const prepareI18nKey = (key: string) => `pryaniky.news.create.events.${key}`;

const StyledBox = styled(Box)({
    display: 'flex',
    gap: 16,
    flexWrap: 'wrap',
    '& > .MuiFormControl-root': {
        flex: 1,
        minWidth: 250
    }
}) as typeof Box;

const StyledCheckBoxLabel = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: 8
}) as typeof Box;

const TextField = memoizedComponent(TextFieldC);
const StyledSelectC = styled(TextField)({}) as any as typeof TextField;

const StyledFormControlLabel = styled(FormControlLabel)({
    margin: 0
}) as typeof FormControlLabel;

const StyledHelpButton = styled(Help)({
    opacity: .54
}) as any as typeof Help;

const CalendarsSuggester = memoizedComponent(CalendarsSuggesterC);
const UsersSuggester = memoizedComponent(UsersSuggesterC);
const DraftInput = memoizedComponent(DraftInputC);
const DatePicker = memoizedComponent(DatePickerC);
const NumberTextField = memoizedComponent(NumberTextFieldC);
const StyledSelect = memoizedComponent(StyledSelectC);

const NewsTypeEventsPresenter: FC<{}> = ({
}) => {

    const { creatorId, groupId } = useContext(NewsCreateContext);

    const {
        data,
        isAdmin,
        renderParams,
        errors,
        clearEditor,
        changeNews
    } = useEventsEdit(creatorId);

    let {
        text: currentNewsText,
        draftRawState,
        uevent,
        expires,
        users,
        groups,
        header,
        ishidden
    } = data;

    if(!uevent) {
        uevent = baseEvents.uevent;
    }

    const textRef = useRef(currentNewsText);
    textRef.current = currentNewsText;

    const {
        state,
        draftState,
        setDraftState
    } = useDraftEdit(draftRawState, clearEditor);

    useDidUpdateEffect(() => {
        // because text editor push additional state change when state is empty externally
        if(!textRef.current && !state.text) return;
        changeNews({
            text: state.text,
            draftRawState: state.raw
        });
    }, [state]);

    const suggesterValues = useMemo(() => {
        return [ ...users, ...groups ];
    }, [ users, groups ]);

    const draftCommonProps = useDraftEditorStyle();

    const datePickerType = useMemo<ComponentProps<typeof DatePicker>['type']>(() => {
        if(uevent.duration === 1440) return 'date';
        return 'datetime';
    }, [uevent.duration]);

    const calendarStaticProps = useMemo<ComponentProps<typeof CalendarsSuggester>>(() => {
        return {
            onChange: (e, value) => {
                if(!Array.isArray(value)) {
                    changeNews({
                        uevent: {
                            calendar: value
                        }
                    });
                }
            },
            TextFieldProps: {
                InputProps: {
                    endAdornment: <Tooltip title={<Translate i18nKey={prepareI18nKey('calendar.help')} />} >
                        <StyledHelpButton fontSize={'small'}/>
                    </Tooltip>
                }
            },
            requestAdditionalParams: {
                includeBirthdays: false,
                gId: groupId
            }
        };
    }, [groupId]);

    const maxParticipantsCountProps = useMemo<ComponentProps<typeof NumberTextField>>(() => ({
        label: <Translate i18nKey={prepareI18nKey('uevent.maxParticipantsCount')} />,
        onChange: (event) => {
            changeNews({
                uevent: {
                    maxParticipantsCount: event.target.value
                }
            });
        },
        InputProps: {
            endAdornment: <IconButton
                onClick={() => {
                    changeNews({
                        uevent: {
                            maxParticipantsCount: null
                        }
                    });
                }}
            >
                <Close />
            </IconButton>
        }
    }), []);

    const headerProps = useMemo<ComponentProps<typeof TextField>>(() => ({
        label: <Translate key={'asd'} i18nKey={prepareI18nKey('header')} />,
        onChange: (e) => {
            changeNews({ header: e.target.value })
        }
    }), []);

    const userSuggesterProps = useMemo<ComponentProps<typeof UsersSuggester>>(() => ({
        TextFieldProps: {
            label: <Translate i18nKey={prepareI18nKey('users')} />,
            error: Boolean(errors.users),
            helperText: Boolean(errors.users) && PrepareErrorText(errors.users)
        },
        onChange: (_, users: any) => {
            if(Array.isArray(users)) {
                const usersList = users.filter(el => el.type === 'user');
                const groupsList = users.filter(el => el.type === 'group');
                changeNews({
                    users: (usersList || []) as any,
                    groups: (groupsList || []) as any
                });
            }
        }
    }), [errors.users]);

    const expiresProps = useMemo<Pick<ComponentProps<typeof DatePicker>, 'TextFieldProps' | 'onChange'>>(() => ({
        TextFieldProps: {
            label: <Translate i18nKey={prepareI18nKey('expires')} />,
            error: Boolean(errors.expires),
            helperText: Boolean(errors.expires) && PrepareErrorText(errors.expires)
        },
        onChange: (iso, date) => {
            changeNews({
                expires: iso,
                uevent: {
                    startDateTime: iso || ''
                }
            });
        }
    }), [errors.expires]);

    const durationProps = useMemo<ComponentProps<typeof StyledSelect>>(() => ({
        label: <Translate i18nKey={prepareI18nKey('uevent.duration')} />,
        onChange: (e) => {
            const value = Number(e.target.value);
            if(isNaN(value)) return ;
            const news: Parameters<typeof changeNews>['0'] = {
                uevent: {
                    duration: value as typeof uevent['duration']
                }
            };
            if(value === 1440) {
                const dateIso = moment(expires).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString();
                news.expires = dateIso;
                news.uevent!.startDateTime = dateIso;
                EventsValidateRules.expires!.min!.value = moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
            } else
            // set current startCurrentMinute if previos value was "fullday"
            if(uevent.duration === 1440) {
                news.expires = startCurrentMinute.toISOString();
                news.uevent!.startDateTime = startCurrentMinute.toISOString();
                EventsValidateRules.expires!.min!.value = startCurrentMinute
            }
            changeNews(news);
        },
        children: durationOptions.map(el => {
            return (
                <MenuItem value={el.value}>{el.title}</MenuItem>
            )
        })
    }), []);

    const eventLocationProps = useMemo<ComponentProps<typeof TextField>>(() => ({
        label: <Translate i18nKey={prepareI18nKey('uevent.eventLocation')} />,
        onChange: (e) => changeNews({
            uevent: {
                eventLocation: e.target.value
            }
        }),
        InputProps: {
            startAdornment: <AddLocationAlt />
        }
    }), []);

    const DraftInputProps = useMemo<Partial<ComponentProps<typeof DraftInput>>>(() => ({
        label: <Translate i18nKey={prepareI18nKey('text')} />
    }), []);

    return (
        <>
            <CalendarsSuggester
                {...calendarStaticProps}
                test-id={'timeline-create-event-calendar'}
                // TODOD to any because news type is working
                value={uevent.calendar as any || undefined}
            />
            <TextField
                {...headerProps}
                test-id={'timeline-create-event-header'}
                fullWidth
                value={header}
                error={Boolean(errors.header)}
                helperText={Boolean(errors.header) && PrepareErrorText(errors.header)}
            />
            <UsersSuggester
                {...userSuggesterProps}
                test-id={'timeline-create-event-users'}
                isAdmin={isAdmin}
                withCheckboxes
                multiple
                // TODO check types
                value={suggesterValues as any}
                saveSearchOnBlur
            />
            <StyledBox>
                <DatePicker
                    {...expiresProps}
                    test-id={'timeline-create-event-startDateTime'}
                    value={expires || null}
                    disablePast
                    type={datePickerType as any}
                />
                <StyledSelect
                    {...durationProps}
                    test-id={'timeline-create-event-duration'}
                    select
                    value={uevent.duration.toString()}
                />
            </StyledBox>
            <TextField
                {...eventLocationProps}
                test-id={'timeline-create-event-eventLocation'}
                fullWidth
                value={uevent.eventLocation}
                error={Boolean((errors.uevent as any)?.eventLocation)}
                helperText={
                    // TODO check types in BaseNews.text
                    Boolean((errors.uevent as any)?.eventLocation) && PrepareErrorText((errors.uevent as any)?.eventLocation)
                }
            />
            <NumberTextField
                {...maxParticipantsCountProps}
                test-id={'timeline-create-event-maxParticipantsCount'}
                fullWidth
                hideApperance
                disabled={uevent.disallowParticipate}
                value={uevent.maxParticipantsCount}
            />
            <DraftInput
                test-id={'timeline-create-event-text'}
                {...draftCommonProps}
                {...DraftInputProps}
                disableToolbar={!renderParams?.allowRichEdit}
                value={draftState}
                onChange={setDraftState}
                error={Boolean(errors.text)}
                helperText={Boolean(errors.text) && PrepareErrorText(errors.text as any)}
                clearValue={clearEditor}
            />
            <FormControl
                fullWidth
            >
                {
                    renderParams?.allowCreateVideoConference &&
                    <StyledFormControlLabel
                        control={
                            <Checkbox
                                test-id={'timeline-create-event-meeting.online'}
                                checked={uevent.meeting.online}
                                onChange={(e) => {
                                    changeNews({
                                        uevent: {
                                            meeting: {
                                                online: e.target.checked,
                                                domain: '',
                                                meetId: '',
                                                url: ''
                                            }
                                        }
                                    });
                                }}
                            />
                        }
                        label={
                            <StyledCheckBoxLabel>
                                <Translate i18nKey={prepareI18nKey('online')} />
                                <Tooltip title={<Translate i18nKey={prepareI18nKey('online.help')} />} >
                                    <StyledHelpButton fontSize={'small'}/>
                                </Tooltip>
                            </StyledCheckBoxLabel>
                        }
                    />
                }
                <StyledFormControlLabel
                    control={
                        <Checkbox
                            test-id={'timeline-create-event-disallowParticipate'}
                            checked={uevent.disallowParticipate}
                            onChange={(e) => {
                                changeNews({
                                    uevent: {
                                        disallowParticipate: e.target.checked,
                                        maxParticipantsCount: null
                                    }
                                });
                            }}
                        />
                    }
                    label={
                        <StyledCheckBoxLabel>
                            <Translate i18nKey={prepareI18nKey('disallowParticipate')} />
                            <Tooltip title={<Translate i18nKey={prepareI18nKey('disallowParticipate.help')} />} >
                                <StyledHelpButton fontSize={'small'}/>
                            </Tooltip>
                        </StyledCheckBoxLabel>
                    }
                />
                <StyledFormControlLabel
                    control={
                        <Checkbox
                            test-id={'timeline-create-event-ishidden'}
                            checked={ishidden}
                            onChange={(e) => {
                                changeNews({
                                    ishidden: !ishidden
                                });
                            }}
                        />
                    }
                    label={
                        <StyledCheckBoxLabel>
                            <Translate i18nKey={prepareI18nKey('ishidden')} />
                            <Tooltip title={<Translate i18nKey={prepareI18nKey('ishidden.help')} />} >
                                <StyledHelpButton fontSize={'small'}/>
                            </Tooltip>
                        </StyledCheckBoxLabel>
                    }
                />
                <StyledFormControlLabel
                    control={
                        <Checkbox
                            test-id={'timeline-create-event-enablewaitlist'}
                            checked={uevent.enableWaitList}
                            disabled={(uevent.maxParticipantsCount||0)<=0}
                            onChange={(e) => {
                                changeNews({
                                    uevent: {
                                        enableWaitList: e.target.checked,
                                    }
                                });
                            }}
                        />
                    }
                    label={
                        <StyledCheckBoxLabel>
                            <Translate i18nKey={prepareI18nKey('enableWaitList')} />
                            <Tooltip title={<Translate i18nKey={prepareI18nKey('enableWaitList.help')} />} >
                                <StyledHelpButton fontSize={'small'}/>
                            </Tooltip>
                        </StyledCheckBoxLabel>
                    }
                />
            </FormControl>
        </>
    )
};

export const NewsTypeEvents = NewsTypeEventsPresenter;

export const initNewsEventsCreator = () => ComponentInjector.getInstance().addNode('news_creator_mui5', <NewsTypeEvents />, 'events')