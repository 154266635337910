import moment from "moment";
import { CalendarEvents } from "./UsersEventsTable.index";

const splitter = "|";

export const keyDateFormat = (eventDate: string | moment.Moment) => {
    return moment(eventDate).format('YYYY-MM-DD');
}

// export const getEventKey = (calendarId: string, eventDate: string) => {
//     return `${calendarId}${splitter}${eventDate}`;
// };

// export const parseEventKey = (key: ReturnType<typeof getEventKey>) => {
//     const [calendarId, eventDate] = key.split(splitter);
//     return {calendarId, eventDate};
// };

export const getEventFromArrayWhereDateIsBetweenStartAndEndDates = (day: moment.Moment, events: CalendarEvents): CalendarEvents[number] | undefined => {
    return (events || []).find((event) => {
        const start = moment(event.start);
        const end = moment(event.end);
        if(
            keyDateFormat(start) === keyDateFormat(day)
            || keyDateFormat(end) === keyDateFormat(day)
            || (
                start < day
                && end > day
            )
        ) {
            return true;
        }
        return false;
    });
};