import { put, takeLeading, takeLatest, takeEvery } from 'redux-saga/effects';

import { call, select } from 'utils/src/saga.effects';

import { checkResponseStatus, clone, getErrorText } from 'utils/src/utils';
import { v1 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import i18n from '../../../localizations/i18n';
import { sub, format } from 'date-fns';
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { USERS_GET_USERS_HIERARCHY, setUserHieararchyToRedux, getUserHieararchyFromServer } from './actions';
import { IGetUserHierarchyFromServerAction } from './actions.interfaces';

import { IUserHierarchyRespons } from 'utils/src/requests/models/admin.userSettings';

import { getCompanyHierarchy } from 'utils/src/requests/admin.userSettings';

import { Actions, JsonSchema } from '@jsonforms/core';
import uischema from 'blocks/WSettings/structures/hierarchy/uischema';
import schemaDefault from 'blocks/WSettings/structures/hierarchy/schema';
import hierarchyData from 'blocks/WSettings/structures/hierarchy/data';
import { JFdictionary } from 'blocks/WSettings/structures/dictionary';
import { cloneDeep } from 'lodash';
const { types } = JFdictionary;

function* getUserHierarchyFromServer(action: IGetUserHierarchyFromServerAction) {
    try {
        const { count, skipCount, data } = action.payload;
        const response: IUserHierarchyRespons = yield* call(getCompanyHierarchy, skipCount, count);
        if (checkResponseStatus(response)) {
            const schema: any = cloneDeep(schemaDefault);
            schema.properties.hierarchy.opts = response.data.map((e: any) => ({
                title: e.name,
                value: e,
            }));
            if (data) {
                yield put(
                    Actions.init(
                        Object.entries(hierarchyData).reduce(
                            (acc, [cur, val]) => ({ ...acc, [cur]: (data && data[cur]) || val }),
                            {} as any
                        ),
                        schema,
                        uischema
                    )
                );
            } else {
                yield put(Actions.init(hierarchyData, schema, uischema));
            }
            yield put(setUserHieararchyToRedux(response.data));
        } else {
            toast.error(getErrorText(response));
        }
    } catch (e) {
        console.warn('get user settings error', e);
    }
}

const Hierarchy = function* Hierarchy() {
    yield takeLeading(USERS_GET_USERS_HIERARCHY, getUserHierarchyFromServer);
};

export default Hierarchy;
