import React from 'react';
import { cnWidget, cnWidgetMui, IWidgetProps } from '../../Widget.index';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../../Widget';
import { widgets } from "i.widgets";
import { IWidgetTypeUserseventsProps } from './Widget_type_usersevents.index';
import './Widget_type_usersevents.scss';
import { UsersEvents } from 'blocks/UsersEvents/UsersEvents';
import { Box } from 'muicomponents/src';

const type = 'usersevents';

export default class WidgetTypeUserseventsPresenter extends WidgetPresenter<IWidgetTypeUserseventsProps> {

    public render() {
        if (!this.props.widget) return null
        const subtype = utils.widgetSubtype(this.props.widget.type);
        return <widgets.components.common {...this.props} className={cnWidgetMui({ type, subtype })}>
            <UsersEvents calendars={(this.props.widget.data as any)?.calendars || {}} settings={this.props.widget.settings as any} userId={this.props.wcontext.userId || this.props.wcontext.uId} />
        </widgets.components.common>
    }
}