// import Immutable from 'seamless-immutable';
import * as utils from "utils/src/utils";
import { setNested } from 'utils/src/utils';
import * as wutils from 'widgets/utils';
import { IColumn, IWidget, WidgetTypes, generateColumn } from 'i.widgets';
import { modelServerClientDiff } from 'widgets/wiget_settings';
import { toast } from "react-toastify";
import i18n from "localizations/i18n";
import actions from 'redux/actionsTypes/Widgets';
import { getCurrentPage, getCurrentPageId, toggleLoading } from "redux/actions/Widgets";
import { clone, cloneDeep, set } from 'lodash'
import { CacheHelper } from 'utils/src/CacheHelper'
import { WidgetsTypes } from "utils/src/widgets/types";
import { TPage, WidgetLayoutPageSettings, WidgetLayoutSettings } from "utils/src";
import { IStateType } from "redux/store";
// import { SET_STRUCTURE } from '../actions';

export interface IdefaultWidegtsWidgets {
    [s: string]: IWidget<any>
}

export interface IWidgetsTogglers {
    loading: boolean;
}

interface IdefaultWidegts {
    contexts: {
        [s: string]: {
            [s: string]: any
        }
    };
    structures: { [s: string]: string };
    pages: { [s: string]: any };
    widgets: IdefaultWidegtsWidgets;
    viewType: string | 'full' | 'small';
    backup?: IdefaultWidegts;
    dragging?: string;
    togglers: IWidgetsTogglers;
    active?: string;
}

const defaultWidegts: IdefaultWidegts = {
    // togglers: {
    //     loading: false,
    // },
    contexts: {
        common: {}
    },
    structures: {},
    pages: {},
    widgets: {},
    viewType: 'full',
    togglers: {
        loading: false
    }
};

function upStructure(this: { widgets: IdefaultWidegtsWidgets, types: WidgetTypes }, widget: IWidget<any>) {
    if (
        widget &&
        (
            widget.type === this.types.layout ||
            widget.type === this.types.layout + '/horizontal' ||
            widget.type === this.types.tabs ||
            widget.type === this.types.tabsControl ||
            widget.type === this.types.grid
        )
    ) {
        widget.data.forEach((col: IColumn) => {
            col.items = (col.items as string[]).map((id) => upStructure.call(this, utils.clone(this.widgets[id])))
        });
    }
    if (widget.relations[0] = 'common') widget.relations.splice(0, 1);
    return widget;
}

const settingsKeys = {
    rId: 'rating',
    count: 'count',
    countView: 'countView',
    type: 'type',
    name: 'name',
    gId: 'group',
    photoView: 'photoView',
    miniview: 'miniview',
    once: 'once'
    // gIds: 'group',
}

export function prepareContextFromSettings(settigns: { [s: string]: any }) {
    return Object.keys(settigns).reduce((acc, cur) => {
        switch (cur) {
            case settingsKeys.rId: {
                return { ...acc, rId: settigns[settingsKeys.rId].pkid || settigns[settingsKeys.rId].id }
            }
            case settingsKeys.count: {
                return { ...acc, count: settigns[settingsKeys.count] }
            }
            case settingsKeys.type: {
                return { ...acc, type: settigns[settingsKeys.type] }
            }
            case settingsKeys.name: {
                return { ...acc, name: settigns[settingsKeys.name] }
            }
            case settingsKeys.name: {
                return { ...acc, name: settigns[settingsKeys.name] }
            }
            case settingsKeys.gId: {
                return {
                    ...acc,
                    gId: settigns[settingsKeys.gId].pkid || settigns[settingsKeys.gId].id,
                    group: settigns[settingsKeys.gId]
                }
            }
            case settingsKeys.countView: {
                return { ...acc, countView: settigns[settingsKeys.countView] }
            }
            case settingsKeys.photoView: {
                return { ...acc, photoView: settigns[settingsKeys.photoView] }
            }
            case settingsKeys.miniview: {
                return { ...acc, miniview: settigns[settingsKeys.miniview] }
            }
            default:
                return acc
        }
    }, {} as { [s: string]: any });
}

export function widgetsReducer(state: IdefaultWidegts = defaultWidegts, action: any): IdefaultWidegts {

    switch (action.type) {

        case actions.SET_DATA: {

            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    ...action.payload.contexts
                },
                structures: {
                    ...state.structures,
                    ...action.payload.structures
                },
                widgets: {
                    ...state.widgets,
                    ...action.payload.widgets
                },
                pages: {
                    ...state.pages,
                    ...action.payload.pages
                }
            }
        }

        // case actions.SET_STRUCTURE: {
        //     const { structure, types } = utils.clone(action.payload);
        //     structure.layout = upStructure.call({ widgets: state.widgets, types}, utils.clone(state.widgets[structure.layout]));
        //     structure.registerNewWidgets = true;
        //     utils.API.pages.set(structure)
        //     .r
        //     .then((d: any) => {
        //     if (utils.checkResponseStatus(d)) {
        //         toast.success(i18n.t('pryaniky.toasts.success.pageSaved'));
        //     }
        //     //  else {
        //     //     toast.error(i18n.t(d.error_text));
        //     // }
        //     })
        //     return state
        // }

        case actions.SET_ACTIVE_PAGE: {
            return {
                ...state,
                active: action.payload
            }
        }

        case actions.SAVE_STRUCTURE_FROM_WIDGET: {
            const { id, types } = action.payload;
            let pageLayoutId = '';
            let c_parent = state.contexts[id].__parent;
            while (c_parent && c_parent !== 'common') {
                pageLayoutId = c_parent;
                c_parent = state.contexts[c_parent].__parent;
            }

            const pageId = Object.keys(state.pages).filter((pid) => state.pages[pid].layout === pageLayoutId)[0];

            if (pageId) {
                const structure = utils.clone(state.pages[pageId]);
                structure.layout = upStructure.call({ widgets: state.widgets, types }, utils.clone(state.widgets[structure.layout]));
                structure.registerNewWidgets = true;
                if (structure?.isInheritingRootRights) {
                    structure.moderators = []
                };
                //очистка кэша
                // не работает
                CacheHelper.remove('structures', structure.url.substring(1)).then(() => { }).catch(() => { })

                utils.API.pages.set(structure)
                    .r
                    .then((d: any) => {
                        if (utils.checkResponseStatus(d)) {
                            // if save successed then update chache
                            // need to create base pesponse object with data
                            CacheHelper.set('structures', structure.url.substring(1), {
                                error_code: 0,
                                error_text: "OK",
                                data: utils.clone(structure)
                            })
                                .then((value) => console.log(value))
                                .catch(e => console.warn('CacheHelper, structures:', e))
                            toast.success(i18n.t('pryaniky.toasts.success.pageSaved'));
                        } else {
                            toast.error(i18n.t(d.error_text));
                        }
                    })
            }

            // const { structure, types } = utils.clone(action.payload);
            return state
        }

        case actions.SET_CONTEXT: {
            const contexts = JSON.parse(JSON.stringify(state.contexts));
            const { paths, innerPath, value } = action.payload;
            paths.forEach((path: string) => {
                path = innerPath !== '' ? `${path}.${innerPath}` : `${path}`;
                set(contexts, path, value)
                // setNested(state.contexts, path , value);
            });
            // udate object reference so connected components would update too
            return {
                ...state,
                contexts
            };
        }

        case actions.UPDATE_CONTEXT: {
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    [action.payload.id]: {
                        ...state.contexts[action.payload.id],
                        ...action.payload.context
                    }
                }
            }
        }

        case actions.UPDATE_PARENT_CONTEXT: {
            const parentId = state.contexts[action.payload.id]?.__parent;
            if(!parentId) return state;
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    [parentId]: {
                        ...state.contexts[parentId],
                        ...action.payload.context
                    }
                }
            }
        }

        case actions.UPDATE_CONTEXTS: {
            const contexts = JSON.parse(JSON.stringify(state.contexts));
            Object.keys(action.payload).forEach(id => {
                contexts[id] = {
                    ...contexts[id],
                    ...action.payload[id]
                };
            });
            // action.payload.forEach((contextId: any) => {
            //     contexts[contextId] = {
            //         ...contexts[context.id],
            //         ...context,
            //     };
            // })
            return {
                ...state,
                contexts
            }
        }

        case actions.UPDATE_STRUCTURE: {
            return {
                ...state,
                structures: {
                    ...state.structures,
                    [action.payload.name]: action.payload.structure
                }
            }
        }


        case actions.ADD_WIDGET: {
            if (!action.payload.relations) action.payload.relations = ['common', action.payload.id];
            if (action.payload.relations[0] === action.payload.id) action.payload.relations.splice(0, 0, 'common');
            if (action.payload.relations[1] !== action.payload.id) action.payload.relations.splice(1, 0, action.payload.id);
            const wcontext: any = {
                __parent: action.payload.relations[0] || 'common',
                ...(action.payload.newsType && { newsType: action.payload.newsType }),
                ...prepareContextFromSettings(action.payload.settings || {}),
            }
            Object.keys(modelServerClientDiff).forEach((skey: string) => {
                Object.keys(action.payload).forEach((wkey) => {
                    if (skey === wkey) wcontext[modelServerClientDiff[skey]] = action.payload[wkey]
                })
            })
            if (wcontext.__parent !== 'common') {
                const wLayout = cloneDeep(state.widgets[wcontext.__parent]) as IWidget<IColumn[], any>;
                if (action.payload.colId) {
                    wLayout.data = wLayout.data?.map(col => {
                        if (col.id === action.payload.colId) {
                            return {
                                ...col,
                                items: action.payload.append ? [...col.items, action.payload.id] : [action.payload.id, ...col.items]
                            };
                        }
                        return col;
                    }) || [];
                    // wLayout.data.forEach((col: IColumn) => { if (col.id === action.payload.colId) col.items.unshift(action.payload.id) });
                    delete action.payload.append;
                    delete action.payload.colId;
                }
                if (action.payload.addToGrid) {
                    if (!wLayout.data) wLayout.data = [];
                    if (!wLayout.data[0]) {
                        wLayout.data.unshift(
                            generateColumn({
                                items: [],
                                styles: {
                                    width: '100%',
                                }
                            })
                        );
                    }
                    wLayout.data[0]?.items?.unshift(action.payload.id);
                    wLayout.settings = {
                        ...wLayout.settings,
                        layouts: {
                            ...wLayout.settings?.layouts,
                            [action.payload.addToGrid.breakpoint]: [
                                action.payload.addToGrid.layoutItem,
                                ...wLayout.settings?.layouts[action.payload.addToGrid.breakpoint]
                            ]
                            // xs: [
                            //     { i: ids.users1, x: 0, y: 0, w: 12, h: 10 },
                            // ]
                        }
                    }
                    delete action.payload.addToGrid;
                }
                state.widgets[wLayout.id] = wLayout;
            }

            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    [action.payload.id]: wcontext
                },
                widgets: {
                    ...state.widgets,
                    [action.payload.id]: action.payload
                }
            }
        }

        case actions.UPDATE_WIDGET: {
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    [action.payload.id]: action.payload
                }
            }
        }


        case actions.DRAG_WIDGET: {
            const contexts = utils.cloneObject(state.contexts)
            action.payload.data.forEach((col: IColumn) => (col.items as string[]).forEach(item => {
                if (contexts[item]) contexts[item].__parent = action.payload.id
            }));
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    ...contexts
                },
                widgets: {
                    ...state.widgets,
                    [action.payload.id]: action.payload
                }
            }
        }

        case actions.REMOVE_WIDGET: {
            const wLayout = cloneDeep(state.widgets[state.contexts[action.payload.id].__parent]);
            wLayout.data.forEach((col: IColumn) => col.items = (col.items as string[]).filter(widget => widget !== action.payload.id));
            let commonContextRemoveKey: string = '';
            if (state.widgets[action.payload.id].type === 'pryaniky/license') commonContextRemoveKey = `license/${action.payload.id}`;
            if (wLayout.type === 'pryaniky/grid') {
                wLayout.settings = {
                    ...wLayout.settings,
                    layouts: Object.keys(wLayout.settings?.layouts || {}).reduce((a, key) => ({ ...a, [key]: wLayout.settings?.layouts[key].filter((el: any) => el.i !== action.payload.id) }), {})
                }
            }
            delete state.widgets[action.payload.id];
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    common: !commonContextRemoveKey ? state.contexts.common : Object.keys(state.contexts.common).reduce((newContext, key) => {
                        if (key !== commonContextRemoveKey) newContext[key] = state.contexts.common[key];
                        return newContext;
                    }, {} as { [s: string]: any })
                },
                widgets: {
                    ...state.widgets,
                    [wLayout.id]: wLayout,
                }
            }
        }

        case actions.BACKUP_PAGE: {
            switch (action.payload) {
                case 'make': {
                    const backup: IdefaultWidegts = {
                        structures: utils.clone(state.structures),
                        pages: utils.clone(state.pages),
                        contexts: utils.clone(state.contexts),
                        widgets: utils.clone(state.widgets),
                        viewType: 'full',
                        togglers: {
                            loading: false,
                        }
                    }
                    return {
                        ...state,
                        backup
                    }
                }
                case 'restore': {
                    if (state.backup) {
                        const { contexts, structures, pages, widgets } = state.backup;
                        delete state.backup;
                        return {
                            ...state,
                            contexts,
                            structures,
                            pages,
                            widgets
                        }
                    }
                }
            }
            return state;
        }

        case actions.ADD_TAB_TO_WIDGET: {
            const { wId, columns } = action.payload;
            const stateWidget = state.widgets[wId];
            const oldColumns: string[] = stateWidget ? stateWidget.data.map((el: IColumn) => el.id) : [];

            // удаляем личшее фронтовое
            columns.forEach((el: any) => {
                if (el.frontTitle && typeof (el.title) === 'function') {
                    el.title = el.frontTitle;
                    delete el.frontTitle;
                    delete el.changeTitleFunction;
                }
            });
            // получаем только новую колонку
            const newColumns = columns.filter((el: IColumn) => !oldColumns.includes(el.id));

            // учтанавливаем необходимые зависимости
            newColumns.forEach((el: IColumn) => {
                const lay = el.items[0] as IWidget<IColumn[], any>;
                lay.data?.forEach(col => {
                    const widgets = col.items as IWidget<any, any>[];
                    col.items = widgets?.map(el => el.id) || [];
                    widgets?.forEach((widg) => {
                        widg.relations = [lay.id, widg.id];
                        state.contexts[widg.id] = { __parent: lay.id }
                        state.widgets[widg.id] = widg;
                    });
                });
                el.items[0] = lay.id;
                lay.relations = [wId, lay.id];
                state.contexts[lay.id] = { __parent: wId }
                state.widgets[lay.id] = lay;
            });

            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    [wId]: {
                        ...state.widgets[action.payload.wId],
                        data: columns
                    }
                }
            }
        }

        case actions.CHANGE_WIDGET_DATA: {
            const { wId, data } = action.payload;
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    [wId]: {
                        ...state.contexts[wId],
                        ...prepareContextFromSettings(data || {})
                    }
                },
                widgets: {
                    ...state.widgets,
                    [wId]: {
                        ...state.widgets[action.payload.wId],
                        data: {
                            ...state.widgets[action.payload.wId].data,
                            ...data
                        }
                    }
                }
            }
        }

        case actions.CHANGE_WIDGET_SETTINGS: {
            let { wId, settings } = action.payload;
            const widget = state.widgets[wId];
            const pageId = getCurrentPageId({ widgets: state } as IStateType);
            let page = state.pages[pageId!] as TPage;
            switch(widget.type) {
                case WidgetsTypes.layout:
                    const { backgroundColor, backgroundImage, ...widgetSettings } = settings as WidgetLayoutPageSettings & WidgetLayoutSettings;
                    settings = widgetSettings;
                    page = { ...clone(page), backgroundColor, backgroundImage };
                    break;
            }
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [pageId!]: page
                },
                contexts: {
                    ...state.contexts,
                    [wId]: {
                        ...state.contexts[wId],
                        ...prepareContextFromSettings(settings || {})
                    }
                },
                widgets: {
                    ...state.widgets,
                    [wId]: {
                        ...state.widgets[action.payload.wId],
                        settings: {
                            ...state.widgets[action.payload.wId].settings,
                            ...settings
                        }
                    }
                }
            }
        }

        case actions.CHANGE_WIDGETS_VIEW_TYPE: {
            const { payload: type } = action;
            return {
                ...state,
                viewType: type
            }
        }

        case actions.SET_DRAGGING_ELEM: {
            const { payload: id } = action;
            return {
                ...state,
                dragging: id
            }
        }

        case actions.TOGGLE: {
            const a = action as ReturnType<typeof toggleLoading>
            return {
                ...state,
                togglers: {
                    [a.payload.variable]: a.payload.value !== undefined ? a.payload.value : !state.togglers[a.payload.variable]
                }
            }
        }

        case actions.REMOVE_PROP_FROM_CONTEXT: {
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    [action.payload.contextId]: Object.keys(state.contexts[action.payload.contextId]).reduce((newContext, key) => {
                        if (key !== action.payload.propName) newContext[key] = state.contexts[action.payload.contextId][key];
                        return newContext;
                    }, {} as { [s: string]: any })
                }
            }
        }

        case actions.UPDATE_ACTIVE_PAGE: {
            if(!state.active) return state;
            const activePageId = state.structures[state.active];
            const activePage = state.pages[activePageId];
            return {
                ...state,
                pages: {
                    [activePageId]: {
                        ...activePage,
                        ...action.payload
                    }
                }
            }
        }

        default:
            return state;
    }
}
