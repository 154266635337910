import { Box, Paper, styled, Typography } from 'muicomponents/src';
import { Avatar } from 'muicomponents/src/Avatar';
import { LinearProgress } from 'muicomponents/src/LinearProgress';

export const PositionTypography = styled(Typography)({
    color: '#424242',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 1.4,
}) as typeof Typography;

export const DescriptionTypography = styled(Typography)({
    color: 'rgba(0, 0, 0, 0.60)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 1.4,
    borderLeft: '1px solid #DADAFF',
    paddingLeft: '8px',
    whiteSpace: 'nowrap',
    width: '100%',
}) as typeof Typography;

export const BoxFlexGap8 = styled(Box)({
    display: 'flex',
    gap: '8px',
    gridGap: '8px',
}) as typeof Box;

export const BoxFlexGap16 = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    gridGap: '16px',
    width: '100%',
}) as typeof Box;
