import React from 'react';
import { cnWidget, IWidgetProps } from '../../Widget.index';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../../Widget';
import { widgets } from "i.widgets";
import { IWidgetTypeEventslistProps } from './Widget_type_eventslist.index';
import './Widget_type_eventslist.scss';

const type = 'eventslist';

export default class WidgetTypeEventslistPresenter extends WidgetPresenter<IWidgetTypeEventslistProps> {

    public render() {
        if (!this.props.widget) return null
        const subtype = utils.widgetSubtype(this.props.widget.type);
        this.props.widget.settings
        return <widgets.components.common {...this.props} className={cnWidget({ type, subtype })}>
            asdasd
        </widgets.components.common>
    }
}