import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IStateType as IState } from 'redux/store';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { bindActionCreators } from 'redux';

export interface IOrdersListProps extends IClassNameProps, IMapStateProps, IMapDispatchProps {}

export const mapStateToProps = (state: IState) => {
    return {
        uid: getAuthUser(state).baseData.id,
    };
};

export type IMapStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch);
export type IMapDispatchProps = ReturnType<typeof mapDispatchToProps>;

export const cnOrders = cn('OrdersList');
