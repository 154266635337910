import { Box } from 'muicomponents/src/Box';
import { MenuItem as MenuItemMui } from 'muicomponents/src/Menu';
import { styled } from 'muicomponents/src/mui/system';

export const MenuItem = styled(MenuItemMui)({
    padding: '6px 16px !important',
    width: '100%',
    justifyContent: 'flex-start !important',
    alignItems: 'flex-start !important',
    whiteSpace: 'normal',
}) as typeof MenuItemMui;

export const MenuItemFlex = styled(Box)({
    display: 'flex',
    flex: '1 0 auto',
    justifyContent: 'space-between',
}) as typeof Box;

export const MenuItemTextBox = styled(Box)({
    maxWidth: 170,
}) as typeof Box;

/**
 * @todo refactor
 * не надо придумывать новые пропсы, у компонента есть sx
 */
export const MenuTextCrop = styled(Box)<{ countLine?: number }>(({ theme, countLine = 1 }) => ({
    fontSize: 14,
    display: '-webkit-box',
    WebkitLineClamp: countLine,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    color: '#666666',
}));
