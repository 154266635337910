/**
 * @packageDocumentation
 * @module Widget_Settings_presents
 */
import data from './data';
import schema from './schema';
import uischema from './uischema';

export default {
    data,
    schema,
    uischema,
};
