import { Box, Paper, Typography } from 'muicomponents/src';
import React, { FC, useEffect, useMemo, useState } from 'react';
import * as utils from 'utils/src/utils';
import { IBadge, IUserBadgesProps, cnProfileBadgeTabList } from './UserBadges.index';
import { i18n, Translate } from 'localization';
import { PaperBox } from 'blocks/InfoBlock/UserNew/Avatar/styled';
import SearchBar from './SearchBar/SearchBar';
import { UserRatingsSkeleton } from 'blocks/UserRatings/UserRatingsSkeleton';
import { IconButton } from 'muicomponents/src/IconButton';
import { GridView as GridViewIcon, List as ListIcon } from 'muicomponents/src/Icons';
import { ListItem } from './ListItem/ListItem';
import { GridItem } from './GridItem/GridItem';
import { useListCardResize } from 'muicomponents/src/InfinityList/hook';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { toast } from 'react-toastify';
import * as utilsProj from 'utils.project/utils.project';

const Presenter: FC<IUserBadgesProps> = ({ context, tag, ...props }: IUserBadgesProps) => {
    const Tag = tag || 'div';

    const [loading, setLoading] = useState(true);
    const [badges, setBadges] = useState<{ [s: string]: IBadge[] }>();
    const [catsList, setCatsList] = useState<string[]>();
    const [searchValue, setSearchValue] = useState('');
    const [viewType, setViewType] = useState<'grid' | 'row'>('grid');

    const authUser = useSelector(getAuthUser);
    const isAuthUserAdmin = authUser.baseData.isAdmin;

    const uid = context.uId;

    const { count, ref: listRef } = useListCardResize(3, 220);

    const getData = () => {
        utils.API.badges.byUser({ uid }).r.then((d: any) => {
            if (!d.data || d.error_code !== 0) {
                setLoading(false);
                return;
            }
            setBadges(d.data);
            const categoriesList = Object.keys(d.data);
            setCatsList(categoriesList);
            setLoading(false);
        });
    };

    useEffect(() => {
        getData();
    }, []);

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.currentTarget.value);
    };

    const searchedBadges = useMemo(() => {
        let filteredBadges: { [s: string]: IBadge[] } = {};
        if (!searchValue) {
            return badges;
        } else if (searchValue && catsList && badges) {
            catsList.forEach((category) => {
                filteredBadges[category] = badges[category].filter((badge) =>
                    badge.name.toLowerCase().includes(searchValue)
                );
            });
            if (Object.keys(filteredBadges).length === 1 && '' in filteredBadges && !filteredBadges[''].length) {
                return null;
            }
            return filteredBadges;
        }
    }, [searchValue, badges, catsList]);

    const removeBadge = (catId: string) => (bid: string) => {
        utilsProj.confirm({
            text: i18n.t('pryaniky.profile.badge.tab.list.badge.delete.confirm'),
            onConfirm: () =>
                utils.API.badges.removeBadgeFromUser({ uid, bid }).r.then((d: any) => {
                    if (d && d.error_code !== 0) {
                        console.warn('badge delete fail:', d);
                        toast.error(<Translate i18nKey={'pryaniky.profile.badge.tab.list.badge.delete.error'} />);
                    } else {
                        toast.success(<Translate i18nKey={'pryaniky.profile.badge.tab.list.badge.delete.success'} />);
                        if (badges) {
                            setBadges((s) => ({
                                ...s,
                                [catId]: badges[catId]
                                    .map((v) => {
                                        if (v.badgeUid === bid) {
                                            return {
                                                ...v,
                                                count: v.count - 1,
                                            };
                                        } else return v;
                                    })
                                    .filter((v) => v.count >= 1),
                            }));
                        }
                    }
                }),
        });
    };

    if (loading) return <UserRatingsSkeleton />;

    if (!badges || !catsList?.length) {
        return (
            <PaperBox>
                <Typography>
                    <Translate i18nKey={'pryaniky.placeholder.widget.nodata'} />
                </Typography>
            </PaperBox>
        );
    }

    return (
        <Tag {...props}>
            <PaperBox sx={{ display: 'flex', alignItems: 'center', columnGap: '24px' }}>
                <SearchBar onSearchChange={onSearchChange} value={searchValue} />
                <Box sx={{ display: 'flex', columnGap: '10px' }}>
                    <IconButton onClick={() => setViewType('grid')}>
                        <GridViewIcon sx={{ fontSize: '30px' }} color={viewType === 'grid' ? 'primary' : 'inherit'} />
                    </IconButton>
                    <IconButton onClick={() => setViewType('row')}>
                        <ListIcon sx={{ fontSize: '30px' }} color={viewType === 'row' ? 'primary' : 'inherit'} />
                    </IconButton>
                </Box>
            </PaperBox>

            <PaperBox sx={{ mt: '24px' }}>
                {catsList?.map((el, i) => {
                    return (
                        <>
                            {el !== '' && (searchedBadges || badges)[el].length ? (
                                <Typography
                                    mt={'24px'}
                                    mb={viewType === 'grid' ? '16px' : '0'}
                                    variant="h6"
                                    sx={{
                                        ['&::first-letter']: {
                                            textTransform: 'uppercase',
                                        },
                                    }}
                                >
                                    {el.toLocaleLowerCase()}
                                </Typography>
                            ) : null}
                            <Box
                                key={i}
                                mt={'12px'}
                                ref={listRef}
                                sx={viewType === 'grid' ? { display: 'flex', flexWrap: 'wrap', gap: '12px' } : {}}
                            >
                                {searchValue && !searchedBadges ? (
                                    <Typography>{i18n.t('nothing')}</Typography>
                                ) : (
                                    (searchedBadges || badges)[el].map((badge) => {
                                        return viewType === 'row' ? (
                                            <ListItem badge={badge} onRemove={isAuthUserAdmin && removeBadge(el)} />
                                        ) : (
                                            <GridItem
                                                badge={badge}
                                                countColumns={count}
                                                onRemove={isAuthUserAdmin && removeBadge(el)}
                                            />
                                        );
                                    })
                                )}
                            </Box>
                        </>
                    );
                })}
            </PaperBox>
        </Tag>
    );
};

export const UserBadges = React.memo(Presenter);
