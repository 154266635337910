import React, { FC } from 'react';
import { TableVirtualizedItemProps } from "muicomponents/src/TableVirtualized";
import { HeaderBox } from "./Header.styled";
import { styled } from 'muicomponents/src';
import { lineClampStyles } from 'blocks/UsersEvents/UsersEvents.constants';

export const HeaderComponent: FC<TableVirtualizedItemProps> = ((props) => {
    return <HeaderBox {...props} ref={props.registerChild} />
});

export const TableHeaderNamesHeaderBox = styled(HeaderComponent)({
    maxWidth: 200
}) as typeof HeaderComponent;

export const TableHeaderDaysBox = styled(HeaderComponent)({
    maxWidth: 80,
    // display: 'flex',
    justifyItems: 'center'
}) as typeof HeaderComponent;

export const TableHeaderDivisionBox = styled(HeaderComponent)({
    maxWidth: 260
}) as typeof HeaderComponent;