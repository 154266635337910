import { Button } from 'muicomponents/src/Button/Button';
import { styled } from 'muicomponents/src/mui/system';

export const ButtonEdit = styled(Button)<{ isMobile: boolean }>(({ isMobile }) => ({
    height: isMobile ? 'auto' : '40px',
    width: isMobile ? 'auto' : '40px',
    padding: '6px 16px',
    minWidth: isMobile ? '64px' : 'auto',
    columnGap: isMobile ? '8px' : '0',
    '& .MuiButton-startIcon': {
        marginRight: '0',
        marginLeft: '0',
    },
}));
